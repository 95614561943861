import React, { Component } from 'react'
import Select from 'react-select'
import axios from 'axios'

var contentAPI = ''

if (window.location.hostname === 'localhost' || window.location.hostname === '192.168.0.149') {
  contentAPI = 'https://' + window.location.hostname + ':3001/api'
} else {
  contentAPI = 'https://' + window.location.hostname + '/api'
}

function validateEmail(value) {
    // regex from http://stackoverflow.com/questions/46155/validate-email-address-in-javascript
    var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(value);
}

class Report extends Component {
	constructor(props) {
	    super(props);

	    this.state = {
	      reportEmail: '',
	      pageTitle: this.props.pageTitle,
	      reportState: this.props.state,
	      reportCounty: this.props.county,
	      reportDocument: this.props.documentName,
	      reportSection: '',
	      reportMessage: '',
	      isSelectedSection: '',
	      isFilledreportMessage: '',
	      sendingReportStatus: this.props.sending,
	      sendingReportStatusMessage: '',
	  	}

	  	this.handleSubmit = this.handleSubmit.bind(this)
	  	this.hideShowReportCard = this.hideShowReportCard.bind(this)
	}

	hideShowReportCard(event) {
	  	this.props.hideMe()
	}

	handleSubmit(event) {
		event.preventDefault()
		event.persist()

		var _thisComponentReport = this

		var dataEmail = this.state.reportEmail;
		var dataSections = [];

		console.log(this.state)

		Object.values(this.state.reportSection).forEach( function(index) {
      		dataSections.push(index.value)
      	}) 
      	dataSections = dataSections.join(', ')
      	var dataPageTitle = this.state.pageTitle
      	var dataMessage = this.state.reportMessage
      	var reportState = this.state.reportState
      	var reportCounty = this.state.reportCounty
      	var reportDocument = this.state.reportDocument.file_id

      	var AdminTextMessage = 'State: ' + reportState + ', County: ' + reportCounty + ', Page Title: ' + dataPageTitle + ', Document Name: ' + reportDocument + ' Section: ' + dataSections + ' Message: ' + dataMessage;
      	var AdminHTMLMessage = '<p>State: ' + reportState + ', County: ' + reportCounty + ', Active Problem: ' + dataPageTitle + '</p><p> Document Id: ' + reportDocument + '</p><p>Message: ' + dataMessage + '<p>';
      	var UserTextMessage = 'Thanks for reporting a correction or update to Titlebox! We\'ll review this quickly and update Titlebox with the right information.';
      	var UserHTMLMessage = 'Thanks for reporting a correction or update to Titlebox! We\'ll review this quickly and update Titlebox with the right information.';



	    _thisComponentReport.reportSection(this.state.reportSection)
	    _thisComponentReport.reportMessage(this.state.reportMessage)
	    _thisComponentReport.reportEmail(this.state.reportEmail)

        this.setState({
        	sendingReportStatus: 'sending',
        	sendingReportStatusMessage: 'Sending...'
        })

        if (validateEmail(dataEmail) && dataEmail && dataMessage) {        
	        axios.post(contentAPI + `/report`, {
	            data: {
	                email: dataEmail,
	                AdminTextMessage: AdminTextMessage,
	                AdminHTMLMessage: AdminHTMLMessage,
	                UserTextMessage: UserTextMessage,
	                UserHTMLMessage: UserHTMLMessage
	            },
	            method: 'HEAD',
	                  headers: {
	                    'Content-Type': 'application/json',
	                  }
	            })
	            .then((response) => {
	                //
	                //return response;

			        this.setState({
			        	sendingReportStatus: 'success',
			        	sendingReportStatusMessage: 'Thank you! We\'ll take a look and follow up with you.'
			        })

			        setTimeout( function() {
			       		_thisComponentReport.hideShowReportCard()
			        }, 2000)
	            }).catch((error) => {
	                //
	            });
        } else {
        	if (!dataEmail) {
		        this.setState({
		        	sendingReportStatus: 'invalid',
		        	sendingReportStatusMessage: 'Please enter your email.'
		        })
		    } else {
		        this.setState({
		        	sendingReportStatus: 'invalid',
		        	sendingReportStatusMessage: 'Please enter all details.'
		        }) 	
		    }
        } 
	}

  	reportEmail = (reportEmail) => {

  		if (typeof reportEmail.target !== "undefined") {
			this.setState({ 
				reportEmail: reportEmail.target.value 
			});
		}

		if (reportEmail) {
			this.setState({ 
				sendingReportStatusMessage: ''
			})
		}
  	}

  	reportSection = (reportSection) => {


	    if (reportSection.length) {
			this.setState({ 
				reportSection: reportSection,
				isSelectedSection: ''
			},
	      		() => Object.values(this.state.reportSection).forEach( function(index) {
	      		})
	    	)

	    }
  	}

  	reportMessage = (reportMessage) => {


  		if (reportMessage) {
  			if (typeof reportMessage.target !== "undefined") {
				this.setState({
					reportMessage: reportMessage.target.value,
					isFilledreportMessage: ''
				})
			} else {
				this.setState({
					isFilledreportMessage: ''
				})				
			}
	    } else {
	      this.setState({ 
	        isFilledreportMessage: 'invalid'
	      })
	    }
  	}

	render () {
		var isSelectedSection = this.state.isSelectedSection 
		var isFilledreportMessage = this.state.isFilledreportMessage
		var sendingReportStatus = this.state.sendingReportStatus
		var sendingReportStatusMessage = this.state.sendingReportStatusMessage

		var sectionsArray = []

	            return ([
		            <div className={ "row clear reportCard " + sendingReportStatus } key="report">
		                <div className="card col-md-12">
            				<div className="card-header">
                   				<button type="button" className="close" onClick={ this.hideShowReportCard } data-dismiss="modal">&times;</button>
                   			</div>
		                    
		                    <div className="card-body">		                        
		                        <form onSubmit={this.handleSubmit}>
		                             <div className="form-group">
		                                <label htmlFor="textMessage" className={ "textMessage " + isFilledreportMessage }>What needs to be updated?
								             <textarea
								             	key="reportMessage"
								             	name="reportMessage"
								             	onChange={(...args) => this.reportMessage(...args)}
								             	className="form-control"
								             	//required
								             	></textarea>

            								<small className={ 'reportMessage' }>Please tell us what should be updated.</small>
							            </label>
		                            </div>

			                        <div className="">
			                            <label htmlFor="reportEmail" className="backupEmailaddress">Email address
				                            <input
				                                className={ 'form-control col-md-12 float-left ' + this.state.sending }
				                                placeholder="Enter your email..."
				                                name="reportEmail"
				                                key="reportEmail"
				                                type="text"
				                                onChange={this.reportEmail}
				                                //required
				                            />
			                            </label>

			                            <input
		                                    className="btn btn-primary col-md-2 float-right form-submit"
		                                    value="Send"
		                                    type="submit"
		                                />

            							<small className={ 'sendingReportStatusMessage' }>{ sendingReportStatusMessage }</small>
		                            </div>
		                        </form>
		                    </div>
		                </div>
		            </div>
	            ]);

	}
}

export default Report; 