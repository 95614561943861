import React, { Component } from 'react'
import Dropzone from 'react-dropzone'
import { Link } from 'react-router-dom'
import axios from 'axios'
import history from '../../history'

// More .jsx and more loaded here
import SortableList from './SortableList'
import UploadtoList from './UploadtoList'
import OnPageMenu from '../navbar/OnPageMenu'

var contentAPI = ''
var sid = {
  "role" : 'role',
  "subscriptions" : 'subscriptionsJSON',
  "expiresAt" : 'expiresAt',
  "idToken" : 'authResult',      
}
sid = JSON.stringify(sid)

const localArray = [
  'localhost',
  '192.168.0.149',
  '192.168.1.31'
]

// console.log(sid)

if (localArray.some(v => window.location.hostname.includes(v))) {
  contentAPI = 'https://' + window.location.hostname + ':3001/api'
} else {
  contentAPI = 'https://' + window.location.hostname + '/api'
}

const parserAPI = 'https://ttbsearchextractorappv0.azurewebsites.net'


class Upload extends Component {
  constructor(props) {
    super(props)

    this.state = {
      showUpload: false
    }
  }

  componentDidMount() {
    let email      = localStorage.getItem('email')

    const allow = [
      'exam@thetitlebox.com',
      'purple2@thetitlebox.com',
      'purple7@thetitlebox.com',
      'blue3@thetitlebox.com',
      'blue5@thetitlebox.com',
      'heather@thetitlebox.com',
      'orders@thetitlebox.com',
      'amber@titleeq.com',
      'matt@titleeq.com',
      'jamie@titleeq.com',
      'matt@thetitlebox.com',
      'jamie@thetitlebox.com',
      'whitney@krape.com',
      'wkrape@gmail.com'
    ]

    if (allow.includes(email)) {
      console.log("email")
      console.log(email)
      
      this.setState({
        admin: true
      })

      if (!window.location.hostname.includes('instant')) {
        console.log("email")
        console.log(email)  
              
        this.setState({
          showUpload: true
        })
      }
    }
  }

  handleOptionReload = (event) => {
    window.location.reload()
  }

  render () {
    let { showUpload } = this.state

    return (
      <div className="p-6 examiner__upload">
        <h1>Welcome to the TitleBox LIVESearch&trade;</h1>

        <div className="col-md-12">
          <OnPageMenu handleOptionReload={ this.handleOptionReload.bind(this) } />
        </div>

          <>
            <div className="row examiner__steps">
            </div>

            <div className="examiner__new">
            </div>
          </>
          
        <a name="examiner__previous"></a>

        <SortableList searchBy="User" />

        <br/><br/><br/><br/><br/><br/>
        <small>{ process.env.REACT_APP_CLIENT_VERSION }</small>
      </div>
    )
  }
}

export default Upload;
