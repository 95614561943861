import 'sanitize.css/sanitize.css';
import './index.css';

import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';
import "core-js/stable";

import ReactDOM from 'react-dom';
import { makeMainRoutes } from './routes';

const routes = makeMainRoutes();

ReactDOM.render(
  routes,
  document.getElementById('root')
);

