import React from 'react';
import { Route, Router, Switch } from 'react-router-dom';
import axios from 'axios';
import history from './history';
import { Auth0Provider } from "@auth0/auth0-react";

import App from './App';
import ProtectedRoute from "./containers/auth/protected-route";
import Callback from "./containers/auth/callback";
// import Auth from './containers/auth';

import LatestUpdates from './containers/latestupdates';

import LoadDocument from './containers/loaddocument';

import Home from './containers/home';
import Upload from './containers/upload';
import Audit from './containers/audit';
import DynamicSearch from './containers/dynamicsearch';
import Dashboard from './containers/dashboard';
import Review from './containers/review';
import Rejected from './containers/rejected';
import IntakeForm from './containers/intakeform';

// const auth = new Auth()


// let $environment = process.env.NODE_ENV
let $domain = window.location.href

axios.interceptors.request.use( async(config) => {
  let accessToken = 'wnJHFmZpMQRKoV3dkzppgaeCgGUG2vDu6tHzbjfZZGv2FieBvzeMdrt'

  if (accessToken) {
    config.headers['authorization'] = `${accessToken}`
    config.headers['Content-Type'] = 'application/json'
    config.headers['Cache-Control'] = 'cache'

    return config
  }

}, function (error) {
  return Promise.reject(error)
})


var masterIssueList = [
  {value: "BANKRUPTCY", label: "BANKRUPTCY"},
  {value: "JUDGMENTS", label: "JUDGMENTS"},
  {value: "FORECLOSURE", label: "FORECLOSURE"},
  {value: "CHAIN OF TITLE", label: "CHAIN OF TITLE"},
  {value: "MORTGAGES", label: "MORTGAGES"},
  {value: "ASSOCIATIONS AND COMMUNITIES", label: "ASSOCIATIONS AND COMMUNITIES"},
  {value: "ENTITIES", label: "ENTITIES"},
  {value: "REAL ESTATE TAXES", label: "REAL ESTATE TAXES"},
  {value: "ESTATES", label: "ESTATES"},
  {value: "MARRIAGE AND DIVORCE", label: "MARRIAGE AND DIVORCE"}
]

const renderMergedProps = (component, ...rest) => {
  const finalProps = Object.assign({}, ...rest);
  return (
    React.createElement(component, finalProps)
  );
}

const PropsRoute = ({ component, ...rest }) => {
  return (
    <Route {...rest} render={routeProps => {
      return renderMergedProps(component, routeProps, rest);
    }}/>
  );
}

export const makeMainRoutes = () => {
  var callbackURI = window.location.origin // window.location.href.split('?')[0];
  let search = window.location.search
  if (search) {
    let params = new URLSearchParams(search)
    let state = params.get('state')
    if (state) {
      let base64ToString = Buffer.from(state, "base64").toString();
      base64ToString = base64ToString.split('?')
      base64ToString = base64ToString[1]
      let base64ToStringparams = new URLSearchParams(base64ToString)
      let temporaryFileId = ''

      if (base64ToStringparams.get('file_id')) {
        temporaryFileId = base64ToStringparams.get('file_id').replace('"', '')

        if (temporaryFileId && temporaryFileId !== "" && temporaryFileId.length) {
          console.log('setTemporary01')
          console.log(temporaryFileId)
          localStorage.setItem('fileid', temporaryFileId)
        }
      }
    }
    
    // console.log(base64ToString)

  }
  
  // console.log(callbackURI)

  /*
  if (callbackURI == "http://localhost:3000" || callbackURI == "http://localhost:3000/") {
    callbackURI = window.location.origin + '/auth/callback'
  } else {
    callbackURI = window.location.origin
  }
  */

  // console.log(callbackURI)
  // var cookie = ("; "+document.cookie).split("; auth0.is.authenticated=").pop().split(";").shift();
  // console.log(cookie)

  var Auth0Domain = ''
  var Auth0clientId = ''

  if (window.location.hostname !== 'instant.thetitlebox.com') {
      Auth0Domain   = "formfunctionio.auth0.com"
      Auth0clientId = "qzolx9nLSq0JDmM0EWinY1zLzdY4Nijq"
  } else {
      Auth0Domain   = "thetitlebox-admin.auth0.com"
      Auth0clientId = "PrCm61ZXK5MdnBhezikisH3EifvRep85"
  }

  return (
      <Router history={history}>
        <Auth0Provider
          domain={Auth0Domain}
          clientId={Auth0clientId}
          redirectUri={callbackURI}
        >
          <Route path="/auth/callback" render={(props) => {
            return <Callback {...props} /> 
          }}/>

          <App masterissues={ masterIssueList } domain={ $domain }>

            <div className="workspace"> 
              <main>
                  <Switch>
                    <ProtectedRoute PropsRoute path="/latestupdates" component={ LatestUpdates } />

                    <ProtectedRoute PropsRoute path="/loaddocument/:folderid/:fileid"  component={ LoadDocument } />
                    <Route PropsRoute path="/loaddocument"      component={ LoadDocument } />

                    <Route PropsRoute path="/intakeform"        component={ IntakeForm } />
                    <Route PropsRoute path="/rejected"          component={ Rejected } />
                    <Route PropsRoute path="/dynamicsearch/"    component={ DynamicSearch } />
                    <Route PropsRoute path="/dashboard"         component={ Dashboard } />
                    <Route PropsRoute path="/audit"             component={ Audit } />
                    <Route PropsRoute path="/upload"            component={ Upload } />
                    <Route path="/review"                       component={ Review } />
                    <PropsRoute path="/"                        component={ Home } />

                  </Switch>
              </main>
            </div>
          </App>
        </Auth0Provider>
      </Router>
  );

  /*
  ProtectedRoute is being werid... Use Route on localhost if needed.
  */
}
