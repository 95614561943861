import React, { Component } from 'react'

let IntakeFormMenu = (props) => {
  const currentSelection    = props.activePage
  const documentStep        = props.documentStep
  const documentObject      = props.documentObject
  const metadata            = documentObject.metadata

  if (metadata) {
    const meta_file_id        = documentObject.metadata.meta_file_id
    const folder_id           = documentObject.metadata.folder_id
    const file_id             = documentObject.metadata.file_id
  }

  // console.log(documentObject.metadata)
  // console.log(documentStep)

  return (
    <div className="pt-3 mb-3 col-md-12 justify-content-center text-center row menu__intakeform">
      <div className="col-md-2 mr-2">
        <a className={ currentSelection === "inprogress__form" ? "active" + " p-2 d-block" : "" + " p-2 d-block" } name="inprogress__form" onClick={ props.inprogressPage }>
          Form
        </a>
      </div>

      <div className="col-md-2 mr-2">
        <a className={ currentSelection === "inprogress__task" ? "active" + " p-2 d-block" : "" + " p-2 d-block" } name="inprogress__task" onClick={ props.inprogressPage }>
          Task
        </a>
      </div>

      <div className="col-md-2 mr-2">
        <a className={ currentSelection === "inprogress__document" ? "active" + " p-2 d-block" : "" + " p-2 d-block" } name="inprogress__document" onClick={ props.inprogressPage }>
          Document Upload
        </a>
      </div>

      { props.documentStep === "step--03-existing" ||
        props.documentStep === "step--03-start" 
      ?
        <div className="col-md-2 mr-2">
          <a className={ currentSelection === "inprogress__open" ? "btn btn-outline-secondary p-2 active" : "btn btn-outline-secondary p-2" } name="inprogress__open" onClick={ () => { props.loadDirectDocument(metadata, documentObject) } }>
            LIVESearch
          </a>
        </div>
       :
       null
      }
    </div>
  )
}

export default IntakeFormMenu