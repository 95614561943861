// Breakup, isn't just UploadFunctions, has multiple load functions also.

import axios from 'axios'
import history from '../../history'

const localArray = [
  'localhost',
  '192.168.0.149',
  '192.168.1.31'
]
var contentAPI = ''
if (localArray.some(v => window.location.hostname.includes(v))) {
  contentAPI = 'https://' + window.location.hostname + ':3001/api'
} else {
  contentAPI = 'https://' + window.location.hostname + '/api'
}

// const parserAPI = 'https://ttbsearchextractorappv0.azurewebsites.net'

export let onDrop = (files) => {
    // console.log(files)

    var selectedDocument = files[0].name
    var selectedWholeDocument = files[0]
    var titleFormat = this.state.metadata.meta_company

    // console.log(this.state)

    if (selectedDocument) {
      //

      this.setState({
        dropzoneActive: false
      })

      var parserData = new FormData()
      parserData.append('file_id', selectedDocument)
      parserData.append('pdf', files[0])

      // console.log('parserData')
      // console.log(parserData)




      /*
      fetch(parserAPI + '/extract', {
        method: 'POST',
        headers: {
          'Authorization': 'Basic ' + btoa('ttb:simple default password'),
          'Accept': 'application/json'
        },
        credentials: 'include',
        body: parserData,
      }).then((response) => {
        // console.log(response)

        response.json().then((body) => {
          // console.log(body)


          // validate
          var validateMetadata = []
          validateMetadata = this.state.metadata
          var faults = 0

          // console.log(validateMetadata)

          Object.values(validateMetadata).forEach((value) => {
            if (value && value !== "") {
              // console.log(value)
              // console.log('Fine')
            } else {
              faults++

              // console.log(value)
              // console.log('Not Fine')

              this.setState({
                faultMessage: 'Check the form fields and reupload.'
              })
            }
          })

          this.setState({
            faults: faults
          })

          if (faults === 0) {
            var filesPDFs = []
            var sessionStorageList = sessionStorage.getItem('filesPDFs')
            var selectionCollection = sessionStorage.getItem('selectionCollection')
            var path = selectionCollection.split(',')
            path = path[0]

            console.log(body)
            body.metadata.meta_file_id = body.metadata.meta_file_id
            body.metadata.property_address = body.data.property_address
            body.metadata.path = path

            this.setState({
              uploadedDocument: body
            })


            var justUploaded = {}
            if (sessionStorageList)
              filesPDFs = JSON.parse(sessionStorageList)
            justUploaded['_id']        = validateMetadata.meta_file_id
            justUploaded['name']       = selectedDocument
            justUploaded['document']   = body
            filesPDFs.unshift(justUploaded)

            // console.log('filesPDFs')
            // console.log(filesPDFs)

            // Get ID from sessionStorage function it here Upload needs to come from API and populate uploadedList 

            this.setState({
              filesPDFs: filesPDFs
            })

            var meta_file_id = validateMetadata.meta_file_id
            validateMetadata.path = path
            validateMetadata = JSON.stringify(validateMetadata)


            // SHOULD ALREADY KNOW ID TO GET FROM EMAIL FROM 
            //
            //
            // CACHE TO MONGO
            var uploadURL = contentAPI + '/uploadCacheDocument'

            // console.log('validateMetadata')
            // console.log(validateMetadata)

            var blobData = new FormData()
            blobData.append('documentName', selectedDocument)
            blobData.append('documentContent', JSON.stringify(body))
            blobData.append('documentWholeFile', selectedWholeDocument)
            blobData.append('clientFile', meta_file_id)
            blobData.append('clientFolder', path)
            blobData.append('path', path)


            // console.log('blobData')
            // console.log(blobData)

            this.setState({ mainMessage: ''})

            fetch(uploadURL, {
              method: 'POST',
              body: blobData
            })
            .then((result) => { 
                // console.log(result)

                if (result.status === 200) {
                  this.setState({ mainMessage: 'Search Document Uploaded. '})

                  setTimeout(() => {
                    this.setState({ mainMessage: ''})
                  }, 2500)
                }
            })
            .catch((result) => {
                // console.log(result)
            })

            sessionStorage.setItem('metadata', validateMetadata)
            sessionStorage.setItem('filesPDFs', JSON.stringify(filesPDFs))
            sessionStorage.setItem('uploadedDocument', JSON.stringify(body))
          } else {
            // console.log('faults')
            // console.log(faults)
          }
        })



      })
      */
    }
}

export let loadThisDocument = (id, filesPDFs, redirect) => {
    //console.log('loadThisDocument')
    //console.log(id)

    //console.log('redirectThisDocument')
    //console.log(redirect)

    //console.log('filesPDFs')
    //console.log(filesPDFs)

    if (filesPDFs)
      var selectedDocument = ''
      if (!redirect) {
        selectedDocument = filesPDFs.find( ({ _id }) => _id === id )
      } else {
        selectedDocument = filesPDFs.find( ({ document }) => document.metadata.meta_file_id === id )
      }
      // console.log(selectedDocument)

        /*
        if (metadata.exactDocumentId && 
          metadata.exactDocumentId !== "Document File ID" && 
          metadata.folder_id &&
          metadata.folder_id !== "Client ID") {

          let getCachedURL = contentAPI + '/loadExactCachedDocument'

          axios.post(getCachedURL, {
            data: {
              'meta_file_id': selectedDocument.document.metadata.meta_file_id,
              'selectionCollection': selectedDocument.document.metadata.folder_id
            }
          })
          .then((response) => { 
            // console.log("response")
            // console.log(response)
            response = JSON.parse(response.data.results)

            console.log("response")
            console.log(response)
          })
          .catch((err) => {
            console.log(err)
          })
        } else {
          console.log('Form error')
        }
        */

      if (selectedDocument) {
        var metadata = selectedDocument.document.metadata

        metadata.exactDocumentId = selectedDocument._id
        selectedDocument = JSON.stringify(selectedDocument.document)
        metadata = JSON.stringify(metadata)

        // console.log('selectedDocument')
        // console.log(selectedDocument)

        // console.log(metadata)
        sessionStorage.setItem('metadata', metadata)
        sessionStorage.setItem('uploadedDocument', selectedDocument)

        if (redirect) {
          history.push('/review')
        }
      }
  }

export let loadDirectDocument = (metadata, documentObject) => {
        const meta_file_id = metadata.meta_file_id
        const folder_id    = metadata.folder_id

        console.log('meta_file_id')
        console.log(meta_file_id)

        console.log('folder_id')
        console.log(folder_id)

        sessionStorage.setItem('metadata', JSON.stringify(metadata))
        sessionStorage.setItem('uploadedDocument', JSON.stringify(documentObject))

        if (
          meta_file_id && 
          folder_id 
        ) {

          history.push('/review')

          /*
          let getCachedURL = contentAPI + '/loadExactCachedDocument'

          axios.post(getCachedURL, {
            data: {
              'meta_file_id': meta_file_id,
              'selectionCollection': folder_id
            }
          })
          .then((response) => { 
            // console.log("response")
            // console.log(response)
            response = JSON.parse(response.data.results)

            let selectedDocument = response.document
            let metadata = response.document.metadata

            metadata.exactDocumentId = response._id
            selectedDocument.metadata.exactDocumentId = response._id
            selectedDocument = JSON.stringify(selectedDocument)
            metadata = JSON.stringify(metadata)


            // console.log(metadata)
            sessionStorage.setItem('metadata', metadata)
            sessionStorage.setItem('uploadedDocument', selectedDocument)

            history.push('/review')
          })
          .catch((err) => {
            console.log(err)
          })
          */
        } else {
          console.log('Form error')
        }
}