import { Link } from 'react-router-dom'
import React, { Component } from 'react'
import axios from 'axios'

import { useSortableData } from '../upload/useSortableData.js';

var contentAPI = ''
var sid = {
  "role" : 'role',
  "subscriptions" : 'subscriptionsJSON',
  "expiresAt" : 'expiresAt',
  "idToken" : 'authResult',      
}
sid = JSON.stringify(sid)

const localArray = [
  'localhost',
  '192.168.0.149',
  '192.168.1.31'
]

// console.log(sid)

if (localArray.some(v => window.location.hostname.includes(v))) {
  contentAPI = 'https://' + window.location.hostname + ':3001/api'
} else {
  contentAPI = 'https://' + window.location.hostname + '/api'
}

const parserAPI = 'https://ttbsearchextractorappv0.azurewebsites.net'

const formatterDollar = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD'
})


export default class IntakeSettlementChecklist extends Component {
    // console.log('props')
    // console.log(props)

    // Array here builds out the forms.
    //

  constructor(props) {
    super(props)

    const settlementChecklistFields = {
      "attributes": { "name": "Settlement Checklist", "name-singular": "Settlement Checklist", "section":"settlementchecklist", "display": "multiple", "class": "col-md-4", "json_adjustment":"settlementchecklist" },
      "sections": [ "All-all" ],
      0: {
        "money":       { "name": "Money",   "type": "text", "class": "col-md-4" },
        "content":     { "name": "Content", "type": "textarea", "class": "col-md-4" }
      }
    }

    if (!this.props.documentObject.settlementchecklist)
      this.props.documentObject.settlementchecklist = []


    this.state = {
      documentObject: this.props.documentObject,
      documentFields: this.props.documentFields,
      settlementChecklistFields: settlementChecklistFields,
      processStatus: this.props.processStatus
    }

    this.duplicateSettlementChecklist = this.duplicateSettlementChecklist.bind(this)
    this.removeSettlementChecklist    = this.removeSettlementChecklist.bind(this)
    
    if (this.props.documentObject.settlementchecklist ||
      this.props.documentObject.settlementchecklist <= 0) {
      this.buildOutChecklist()
    }
  }



  handleTasksChangeInput = (event) => {

  }

  handlePrintClick = (event) => {
    const htmlHead         = document.head
    const htmlOfSettlement = document.getElementById("settlementchecklist")
    const htmlCSS          = "<style> \
        #settlementchecklist { \
          padding: 20px; \
        } \
        #settlementchecklist button { \
          display: none; \
        } \
        #settlementchecklist .print--table { \
          display: table; \
          width: 100%; \
        } \
        #settlementchecklist .print--columns { \
           display: table-row !important; \
        } \
        #settlementchecklist .print--columns .col-md-2 { \
           display: table-cell !important; \
           width: auto !important; \
        } \
        #settlementchecklist .print--columns button { \
           display: block; \
        } \
        #settlementchecklist .settlementChecklist__wrapper { \
          display: table-row !important; \
        } \
        #settlementchecklist .settlementChecklist__wrapper .col-md-4, \
        #settlementchecklist .col-md-6 { \
          vertical-align: top; \
          display: table-cell !important; \
          width: auto !important; \
        } \
        #settlementchecklist .status__message { \
          display: none; \
        } \
     </style>"

    // console.log('htmlOfSettlement')
    // console.log(htmlOfSettlement)

    let openWindow = window.open("", "title", "attributes")
    // openWindow.document.write(htmlHead.innerHTML)
    openWindow.document.write(htmlCSS)
    openWindow.document.write(htmlOfSettlement.outerHTML)
    openWindow.document.close()
    openWindow.focus()
    openWindow.print()
    // openWindow.close()
  }

  duplicateSettlementChecklist = (event) => {
    // console.log("event")
    // console.log(event)
    // console.log("entry")
    // console.log(event.target.className)

    // console.log("duplicate")

    let updatedState                    = this.state.documentObject
    const lengthOfSettlementChecklist   = Object.entries(updatedState.settlementchecklist).length
    const settlementChecklist           = {
      "money":            "",
      "title":            "",
      "content":          "" 
    }


    updatedState.settlementchecklist[lengthOfSettlementChecklist]   = settlementChecklist

    // console.log(updatedState)
    
    this.setState({
      documentFields: updatedState
    })

    /*
    const documentFieldsOriginal  = this.state.documentFieldsOriginal
    let problemForFields          = event.target.className
    problemForFields              = problemForFields.replace(" btn btn-default btn-primary", "")
    const lengthOfProblem         = Object.entries(updatedState[problemForFields]).length-1

    updatedState[problemForFields][lengthOfProblem] = JSON.parse(JSON.stringify(documentFieldsOriginal[problemForFields][0]))
    
    this.setState({
      documentFields: updatedState
    })
    */
  }

  
  buildOutChecklist = (event) => {
    let updatedState              = this.state.documentObject
    let lengthOfChecklist         = Object.entries(updatedState.settlementchecklist).length
    let documentObject            = this.state.documentObject
    let documentFields            = this.state.documentFields
    const settlementPremade       = [
      { "money":    "0", 
        "title":    "SPECIAL INSTRUCTIONS? (AFFS, DEEDS, ESTATE RELATED, CORP DOCS, ETC.)", 
        "content":  "" 
      },
      { 
        "money":    "0", 
        "title":    "WHO ARE MY SIGNERS?", 
        "content":  "" 
      },
      { 
        "money":    "0", 
        "title":    "MORTGAGE PAYOFFS?  HOW MANY MORTGAGES?", 
        "content":  "" 
      },
      { 
        "money":    "0", 
        "title":    "RE TAXES/DELINQUENT TAXES?", 
        "content":  "" 
      },
      { 
        "money":    "0", 
        "title":    "JUDGMENTS/LIENS TO BE PAID?", 
        "content":  "" 
      },
      { 
        "money":    "0", 
        "title":    "HOA/CONDO?", 
        "content":  "" 
      },
      { 
        "money":    "0", 
        "title":    "REIMBURSEMENTS FOR THE CD/HUD?", 
        "content":  "" 
      },
      { 
        "money":    "0", 
        "title":    "UTILITIES", 
        "content":  "" 
      },
      { 
        "money":    "0", 
        "title":    "UCC/MORTGAGE SUBORDINATIONS", 
        "content":  "" 
      }
    ]
    const settlementChecklist     = {
      "money":            "",
      "title":            "",
      "content":          "" 
    }

    let lengthOfField = ''

    // console.log('lengthOfChecklist')
    // console.log(lengthOfChecklist)

    if (lengthOfChecklist <= 0) {
      settlementPremade.map((section, section_index) => {
        // console.log('section')
        // console.log(section)
        
        const sectionTitle   = section[2]
        const sectionContent = section[1]
        const sectionName    = section[0]

        lengthOfChecklist                                     = Object.entries(updatedState.settlementchecklist).length
        updatedState.settlementchecklist[lengthOfChecklist]   = JSON.parse(JSON.stringify(section))
      })
    }

    // console.log('updatedState')
    // console.log(updatedState)
    
    this.setState({
      documentFields: updatedState
    })
  }


  removeSettlementChecklist = (event) => {
    // console.log("event")
    // console.log(event)
    // console.log("entry")
    // console.log(event.target.className)

    // console.log("duplicate")
    // console.log('event.target.attributes.number')
    // console.log(event.target.attributes.number)

    if (event.target.attributes.number) {
      const number = event.target.attributes.number.value

      // console.log(number)

      let updatedState        = this.state.documentObject

      updatedState.settlementchecklist.splice(number, 1)
      
      this.setState({
        documentObject: updatedState
      })
    }
  }

  handleTasksChangeInput = (event) => {
    // console.log(event.target.value)

    // On change for problem attachment adds a space in "documentObject" for a Settlement Checklist underneith
    // The appropriate section. 
    // 

    let name = event.target.name
    let parent = ''
    let number = ''
    let display = ''
    let section = ''
    let value = event.target.value

    console.log('value')
    console.log(value)

    if (name === 'money') {
      value = value.replace(/[^0-9\.-]+/g,"")

      console.log('value')
      console.log(value)

      if (value === 'NaN') {
        value = 0
      }
      
      value = '$' + value

      // value = formatterDollar.format(value)
    }

    console.log('name')
    console.log(name)

    console.log('value')
    console.log(value)

    if (event.target.attributes.section)
      section = event.target.attributes.section.value

    if (event.target.attributes.parent)
      parent = event.target.attributes.parent.value

    if (event.target.attributes.display)
      display = event.target.attributes.display.value

    if (event.target.attributes.number)
      number = event.target.attributes.number.value

    let updatedState = this.state.documentObject

    // console.log(number)

    // console.log(updatedState["settlementchecklist"])
    // console.log(updatedState["settlementchecklist"][0])

    // console.log(number)
    // console.log(updatedState["settlementchecklist"][number])

    updatedState["settlementchecklist"][number][name] = value


    /*
    let sort01 = updatedState["settlementchecklist"].sort((a, b) => {
      return b.priority - a.priority
    })

    let sort02 = sort01.sort((a, b) => {
      var wordsToValues = ['open','reviewed','closed'] 

      // console.log(wordsToValues.indexOf('open'))

        if (wordsToValues.indexOf(a.status) < wordsToValues.indexOf(b.status))
            return -1
        if (wordsToValues.indexOf(a.status) > wordsToValues.indexOf(b.status))
            return 1
        return 0
    })
    */

    // sort02.reverse()

    // console.log(updatedState["settlementchecklist"])
    // console.log(sort01)
    // updatedState["settlementchecklist"] = sort01

    // console.log(this.state)

    const processStatus = this.state.processStatus

    if (processStatus.settlementchecklist === "review") {
      // console.log("On the review page")

      this.setState({
        processStatus: {
          settlementchecklist: "review"
        }
      })

      this.handleTasksSubmit(event)
    }

    this.setState({
      documentObject: updatedState
    })
  }

  handleTasksSubmit = (event) => {
    event.preventDefault()

    // console.log(this.state.documentObject)

    const documentContent           = JSON.stringify(this.state.documentObject)
    const meta_file_id              = this.state.documentObject.metadata.meta_file_id
    const exactDocumentId           = this.state.documentObject.metadata.exactDocumentId
    const folder_id                 = this.state.documentObject.metadata.folder_id
    const processStatus             = this.state.processStatus
    let processStatusOriginal       = {}

    if (processStatus.settlementchecklist === "review") 
      processStatusOriginal.settlementchecklist = "review"

    let blobData = new FormData()
    // Just the settlementchecklist section
    blobData.append('editType', "settlementchecklist")
    blobData.append('documentContent', documentContent)
    blobData.append('folder_id', folder_id)
    blobData.append('meta_file_id', meta_file_id)
    blobData.append('exactDocumentId', exactDocumentId)
    // If "exactId" is set then use editCachedDocument
    // If no exactId, then use createCachedDocument
    // ...

    // console.log('meta_file_id')
    // console.log(meta_file_id)
    // console.log('exactDocumentId')
    // console.log(exactDocumentId)

    let formURL = contentAPI + '/editCachedDocument'

    // console.log('documentContent')
    // console.log(documentContent)

    processStatus.settlementchecklist = "Saving"

    this.setState({
      processStatus: processStatus
    })

    fetch(formURL, {
      method: 'POST',
      body: blobData
    })
    .then((result) => { 
      // console.log('result')
      // console.log(result)

      processStatus.settlementchecklist = "Saved"

      this.setState({
        processStatus: processStatus
      })

      if (processStatusOriginal.settlementchecklist === "review") {
        processStatus.settlementchecklist = "review"

        this.setState({
          processStatus: processStatus
        })
      }

      // console.log(result)
    })
    .catch((result) => {
      processStatus.settlementchecklist = "Error Saving Settlement Checklist"

      this.setState({
        processStatus: processStatus
      })

      // console.log(result)
    })
    // Endpoint to go out to Mongo
  } 

  sortSettlementChecklist = (event) => {
    event.preventDefault()

    let sortBy = event.target.attributes.value.value
    sortBy = sortBy.toLowerCase()
    sortBy = sortBy.replace(' ', '_')

      // console.log(sortBy)
      
      const settlementChecklistList = this.state.documentObject.settlementchecklist

      let sortableItems = [...settlementChecklistList]
      let updatedState = this.state.documentObject

      sortableItems.sort((a, b) => {
        let { aSort, bSort } = ''
        let sortConfig = { direction: 'descending' }
        aSort = a[sortBy]
        bSort = b[sortBy]

        if (aSort < bSort) {
          return sortConfig.direction === 'ascending' ? -1 : 1
        }
        if (aSort > bSort) {
          return sortConfig.direction === 'ascending' ? 1 : -1
        }
        return 0
      })

      updatedState["settlementchecklist"] = sortableItems

      // console.log(sortableItems)

      this.setState({
        documentObject: updatedState
      })

      this.forceUpdate()
  }



    render () {
      let { settlementChecklistFields, documentObject, processStatus } = this.state
      let { filteredPDFs, documentStep, admin } = this.props

      const settlementChecklistColumns = ["Money Due at Settlement","Settlement Checklist"]


      // console.log('documentObject.settlementchecklist')
      // console.log(documentObject.settlementchecklist)

      // console.log(documentStep)

      console.log('admin')
      console.log(admin)

      return (
        documentStep && documentStep !== "all--hidden" && documentObject.metadata.meta_file_id ?
        // documentStep && documentStep === "step--03-existing" ||
        // documentStep && documentStep === "step--03-start" ?
          <form className={ "document__editing pl-20 pr-20" } onSubmit={ this.handleTasksSubmit } id="settlementchecklist">
              <div className="row">
                <h5>Settlement Checklist</h5> <button className="btn btn-default btn-outline-primary ml-5 mb-3" onClick={ this.handlePrintClick.bind(this) }>Print</button>
              </div>

              {
                documentStep && documentStep !== "show-on--review" && admin ?
                  <div className="row pl-0 mb-3 col-md-8">
                    <button type="button" onClick={ this.duplicateSettlementChecklist.bind(this) } className={ "btn btn-default btn-outline-primary" }>Add an Item</button>
                  </div>
                :
                  null
              }

              <div className="section__editing print--table pb-2">
                <div className="editing__header print--columns row col-md-12 d-flex">
                  {
                   settlementChecklistColumns.map((column, column_index) => 
                      <div className="col-md-6 p-0">
                        <h6>
                          <button
                            onClick={ this.sortSettlementChecklist.bind(this) }
                            key={ column + column_index }
                            value={ column }
                          >
                          { column }

                          <span 
                            type="button" 
                            name={ column } 
                            className={ "ml-2" } 
                            title="Collapse Settlement Checklist"
                            aria-hidden="true"
                          > 
                            &#9662;
                          </span> 
                          </button>
                        </h6> 
                      </div>
                    )
                  }
                </div>


                    {
                        documentObject.settlementchecklist.map((settlementChecklist, settlementChecklist_index) =>
                            <div className={ "row mt-2 mb-2 ml-4 mr-4 settlementChecklist__wrapper " }>
                            {
                              Object.entries(settlementChecklistFields[0]).map((settlementChecklistfield, settlementChecklistfield_index) =>
                                  settlementChecklistfield[1]["type"] === 'dropdown' && settlementChecklistfield[1]["options"] ?
                                    <div className={ 'col-md-4 pl-0 ' + settlementChecklistfield[0] }>
                                      <label className="col-md-12 pl-0">
                                        {/* settlementChecklistfield[1]["name"] ? settlementChecklistfield[1]["name"] : '' */}
                                        <select
                                          type={ settlementChecklistfield[1]["type"] }
                                          name={ settlementChecklistfield[0] }
                                          section=""
                                          display={ settlementChecklistfield[1]["display"] }
                                          number={ settlementChecklist_index }
                                          example={ settlementChecklistfield[1]["json_adjustment"] }
                                          onChange={ this.handleTasksChangeInput }
                                          className={ 'form-control ' }
                                          key={ "input" + settlementChecklistfield_index }
                                          disabled={ documentStep && documentStep !== "show-on--review" || settlementChecklistfield[0] === "status" ? "" : "true" }
                                        >
                                        { 
                                          settlementChecklistfield[1]["options"].split(',').map((option, option_index) =>
                                            <option value={ option.split('-')[1] }
                                            selected={
                                              settlementChecklist[settlementChecklistfield[0]] && settlementChecklist[settlementChecklistfield[0]] === option.split('-')[1] ?
                                                true
                                              : 
                                                null
                                            }>{ option.split('-')[0] }</option>
                                          ) 
                                        }
                                        </select>
                                      </label>
                                    </div>
                                  : settlementChecklistfield[1]["type"] === 'textarea' ?
                                     <div className={ 'col-md-6 p-0 ' + settlementChecklistfield[0] }>
                                      <div className='col-md-6 p-0'>
                                        { settlementChecklist['title'] }
                                      </div>
                                      <label className="col-md-12">{/* settlementChecklistfield[1]["name"] */}
                                        <textarea
                                          type={ settlementChecklistfield[1]["type"] }
                                          name={ settlementChecklistfield[0] }
                                          section={ 'settlementChecklists' }
                                          number={ settlementChecklist_index }
                                          // onClick={() => bubbledevent}
                                          onChange={ this.handleTasksChangeInput }
                                          className={ ' form-control ' + settlementChecklistfield[0] }
                                          key={ "input" + settlementChecklistfield_index }
                                          value={ settlementChecklist[settlementChecklistfield[0]] }
                                          disabled={ documentStep && documentStep !== "show-on--review" ? "" : "true" }
                                        >{ settlementChecklist[settlementChecklistfield[0]] }</textarea>
                                      </label>
                                    </div>
                                  :
                                     <div className={ 'col-md-4 pl-0 ' + settlementChecklistfield[0] }>
                                      <label className="col-md-12 pl-0">{/* settlementChecklistfield[1]["name"] */}
                                        <div className='col-md-6 p-0'>
                                          &nbsp;
                                        </div>
                                        <input
                                          type={ settlementChecklistfield[1]["type"] }
                                          name={ settlementChecklistfield[0] }
                                          section={ 'settlementChecklists' }
                                          number={ settlementChecklist_index }
                                          // onClick={() => bubbledevent}
                                          onChange={ this.handleTasksChangeInput }
                                          className={ ' form-control ' + settlementChecklistfield[0] }
                                          key={ "input" + settlementChecklistfield_index }
                                          value={ settlementChecklist[settlementChecklistfield[0]] }
                                          disabled={ documentStep && documentStep !== "show-on--review" ? "" : "true" }
                                        />
                                      </label>
                                    </div>


                              ) // fieldsEnd
                            }
                              { admin ?
                                  <button 
                                    type="button" 
                                    onClick={ this.removeSettlementChecklist.bind(this) } 
                                    number={ settlementChecklist_index } 
                                    className={ "btn btn-secondary ml-2 mb-5" } 
                                    title="Remove Item"
                                  > 
                                    <span aria-hidden="true" number={ settlementChecklist_index } >&times;</span>
                                  </button>
                                :
                                  null
                              }
                            </div>

                        ) // settlementChecklistEnd
                      }
              </div>

              {
                documentStep && documentStep !== "show-on--review" && admin ?
                  <>
                    <br className="clear"/>

                    <div className="col-md-8 row pl-0 mt-20">
                      <button type="submit" className="btn btn-default btn-outline-primary">Save Settlement Checklist</button>

                      <div className="col-md-8 row mt-20">
                        <p className="status__message status--settlementChecklists">{ processStatus.settlementchecklist }</p>
                      </div>
                    </div>

                    <br className="clear"/>
                  </>
                :
                  null
              }
          </form>
      :
        <p>Start or Choose a document to start settlementChecklists.</p>
      )
    }
    // } else {
      // return null
    // }
}


