import React, { Component } from 'react'
import Dropzone from 'react-dropzone'
import { Link } from 'react-router-dom'
import axios from 'axios'
import history from '../../history'

// More .jsx and more loaded here
import PagedSearchSortableList from './PagedSearchSortableList'
import OnPageMenu from '../navbar/OnPageMenu'
import { glossary } from '../upload/termSearch.js';

var contentAPI = ''
var sid = {
  "role" : 'role',
  "subscriptions" : 'subscriptionsJSON',
  "expiresAt" : 'expiresAt',
  "idToken" : 'authResult',      
}
sid = JSON.stringify(sid)

const localArray = [
  'localhost',
  '192.168.0.149',
  '192.168.1.31'
]

function parseQuery(queryString) {
    var query = {};
    var pairs = (queryString[0] === '?' ? queryString.substr(1) : queryString).split('&');
    for (var i = 0; i < pairs.length; i++) {
        var pair = pairs[i].split('=');
        query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '');
    }
    return query;
}

// console.log(sid)

if (localArray.some(v => window.location.hostname.includes(v))) {
  contentAPI = 'https://' + window.location.hostname + ':3001/api'
} else {
  contentAPI = 'https://' + window.location.hostname + '/api'
}

const parserAPI = 'https://ttbsearchextractorappv0.azurewebsites.net'

    // Group items in object. 
    // 
    //  let { items, requestSort, sortConfig } = useSortableData(filesPDFs)
    //  const getClassNamesFor = (name) => {
    //    // console.log(name)
    //    if (!sortConfig) {
    //      return
    //    }
    //    return sortConfig.key === name ? sortConfig.direction : 'off'
    //  }
    //
    // Sort the items, reduce 
    //


class DynamicSearch extends Component {
  constructor(props) {
    super(props)

    let queryParams = window.location.search
    queryParams     = parseQuery(queryParams)

    this.state = {
      searchBy: queryParams.searchBy,
      searchTerm: queryParams.searchTerm,
      dateFrom: queryParams.dateFrom,
      dateTo: queryParams.dateTo
    }
  }

  componentDidMount() {
    // Flatten the arrays that come out of Mongo, then process from that flattened array
  }

  handleOptionChangeInput = (event) => {

  }

  handleOptionReload = (event) => {
    window.location.reload()
  }

  render () {
    let { searchBy, searchTerm, dateTo, dateFrom } = this.state

    // console.log('dateTo')
    // console.log(dateTo)

    return (
      <div className="p-6 examiner__upload">
        <h1>Welcome to the TitleBox LIVESearch&trade;</h1>

        <div className="col-md-12">
          <OnPageMenu handleOptionReload={ this.handleOptionReload.bind(this) } />
        </div>

        <div className="row col-md-12">
          <div className="row col-md-4">
          </div>

          <div className="col-md-4">
          </div>

          <div className="col-md-4">
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <PagedSearchSortableList userLevel="Admin" 
              searchBy={ searchBy } 
              searchTerm={ searchTerm }
              dateFrom={ dateFrom } 
              dateTo={ dateTo } 
            />
          </div>
        </div>

        <br/><br/><br/><br/><br/><br/>
        <small>{ process.env.REACT_APP_CLIENT_VERSION }</small>
      </div>
    )
  }
}

export default DynamicSearch;
