import React from 'react';
import {Link, withRouter} from 'react-router-dom';
import { useAuth0 } from "@auth0/auth0-react";
import axios from 'axios'

var contentAPI = ''
if (window.location.hostname === 'localhost') {
  contentAPI = 'http://' + window.location.hostname + ':3001/api'
} else {
  contentAPI = 'https://tciabapp.azurewebsites.net/api'
}

const Profile = () => {
  const { user, isAuthenticated, isLoading } = useAuth0();
  var selectionCollection = sessionStorage.getItem('selectionCollection')
  var filesPDFs = []

  if (isLoading) {
    return <div>Loading ...</div>;
  }

  // console.log('selectionCollection')
  // console.log(selectionCollection)

  // console.log('user.email')
  // console.log(user.email)

  if (user.email) {
    // This asks for the ID from the MSSQL
    localStorage.setItem('email', user.email)
    // Put the ID into localStorage
  }

  return (
    isAuthenticated && (
      <div></div>
    )
  );
}

let NavBar = (props) => {
  const { isAuthenticated, loginWithRedirect, logout, error } = useAuth0()

  const search = window.location.search;
  const params = new URLSearchParams(search);
  const checkCode = params.get('code');
  const checkState = params.get('state');

  if (error) {
    if (checkCode && checkState) {
      loginWithRedirect();
    }
  }

  return (
    <nav className="my-2 my-md-0 pull-right">            
      {
        !isAuthenticated && (
            <Link
              id="qsLoginBtn"
              to=""
              className="p-2"
              onClick={() => loginWithRedirect()}
            >
              Log In
            </Link>
          )
      }

      {
        isAuthenticated && (
          <>
            <Profile />

            <Link
              id="qsLogoutBtn"
              to=""
              className="p-2"
              onClick={() => logout({ returnTo: window.location.origin })}
            >
              Log Out
            </Link>
          </>
        )
      }
    </nav>
  )
}

export default withRouter(NavBar)