import { Link } from 'react-router-dom'
import React, { Component } from 'react'
import axios from 'axios'

import InputBuilder from './InputBuilder'
import IntakeFormFileInformation from './IntakeFormFileInformation'

var contentAPI = ''
var sid = {
  "role" : 'role',
  "subscriptions" : 'subscriptionsJSON',
  "expiresAt" : 'expiresAt',
  "idToken" : 'authResult',      
}
sid = JSON.stringify(sid)

const localArray = [
  'localhost',
  '192.168.0.149',
  '192.168.1.31'
]

// console.log(sid)

if (localArray.some(v => window.location.hostname.includes(v))) {
  contentAPI = 'https://' + window.location.hostname + ':3001/api'
} else {
  contentAPI = 'https://' + window.location.hostname + '/api'
}

const parserAPI = 'https://ttbsearchextractorappv0.azurewebsites.net'


export default class IntakeFormInputs extends Component{
    // console.log('props')
    // console.log(props)

    // Array here builds out the forms.
    //

  constructor(props) {
    super(props)

    this.state = {
      metaFields: this.props.metaFields,
      documentObject: this.props.documentObject,
      documentObjectOriginal: this.props.documentObjectOriginal,
      documentFields: this.props.documentFields,
      documentFieldsOriginal: this.props.documentFieldsOriginal,
      documentAccordians: this.props.documentAccordians,
      documentFieldCollections: this.props.documentFieldCollections
    }

    this.duplicateFields = this.duplicateFields.bind(this)
    this.removeFields    = this.removeFields.bind(this)
  }

  duplicateFields = (event) => {
    // console.log("event")
    // console.log(event)
    // console.log("entry")
    // console.log(event.target.className)

    // console.log("duplicate")

    let updatedFieldsState  = this.state.documentFields
    let updatedObjectState  = this.state.documentObject
    const documentFieldsOriginal  = this.state.documentFieldsOriginal
    const documentObjectOriginal  = this.state.documentObjectOriginal
    let problemForFields          = event.target.attributes.problem.value
    let collectionForFields       = event.target.attributes.collection.value

    // console.log(problemForFields)
    // console.log(collectionForFields)

    const lengthOfProblem         = Object.entries(updatedFieldsState[collectionForFields][problemForFields]).length-1

    // console.log(lengthOfProblem)

    // console.log('lengthOfProblem')
    // console.log(lengthOfProblem)
    // console.log('updatedObjectState')
    // console.log(updatedObjectState)

    updatedFieldsState[collectionForFields][problemForFields][lengthOfProblem] = JSON.parse(JSON.stringify(documentFieldsOriginal[collectionForFields][problemForFields][0]))
    updatedObjectState.data[problemForFields][lengthOfProblem] = JSON.parse(JSON.stringify(documentObjectOriginal.data[problemForFields][0]))
    // Can you delete too many? 
    // console.log('documentObjectOriginal')
    // console.log(documentObjectOriginal)

    // console.log(lengthOfProblem)
    // console.log(updatedFieldsState)
    // console.log(problemForFields)


    this.setState({
      documentFields: updatedFieldsState,
      documentObject: updatedObjectState
    })
  }

  removeFields = (event) => {
    // console.log("event")
    // console.log(event)
    // console.log("entry")
    // console.log(event.target.className)

    // console.log("duplicate")

    // console.log(this.state.documentObject)

    let updatedObjectStateRemoval  = this.state.documentObject
    let updatedFieldsStateRemoval  = this.state.documentFields
    let problemForFields           = event.target.attributes.problem.value
    let collectionForFields        = event.target.attributes.collection.value
    const numberOfProblem          = event.target.attributes.number.value

    // console.log(numberOfProblem)

    let rearrayObject = updatedObjectStateRemoval.data[problemForFields]
    let rearrayFields = updatedFieldsStateRemoval[collectionForFields][problemForFields]
    const holdAttributes = rearrayFields.attributes

    rearrayObject = JSON.parse(JSON.stringify(rearrayObject))
    rearrayFields = JSON.parse(JSON.stringify(rearrayFields))

    delete rearrayFields.attributes

    rearrayObject = Object.values(rearrayObject)
    rearrayFields = Object.values(rearrayFields)

    rearrayObject.splice(numberOfProblem, 1)
    rearrayFields.splice(numberOfProblem, 1)

    // rearrayObject = { ...rearrayObject }
    rearrayFields = { ...rearrayFields }

    rearrayFields.attributes = holdAttributes

    // console.log('rearrayObject')
    // console.log(rearrayObject)
    // console.log('rearrayFields')
    // console.log(rearrayFields)

    updatedFieldsStateRemoval[collectionForFields][problemForFields] = rearrayFields
    updatedObjectStateRemoval.data[problemForFields] = rearrayObject


    // console.log(updatedObjectState)
    
    this.setState({
      documentFields: updatedFieldsStateRemoval,
      documentObject: updatedObjectStateRemoval
    })
  }

  selectDocumentId = (event) => {
    const clickedDocumentId = event.target.attributes.value.value
    let updatedObjectState  = this.state.documentObject

    updatedObjectState.metadata.meta_file_id = clickedDocumentId

    this.setState({
      documentObject: updatedObjectState
    })
  } 

  accordianFields = (event) => {
    const clickedField = event.target.attributes.name.value
    let updatedState = this.state.documentAccordians

    if (
      updatedState[clickedField] &&
      updatedState[clickedField] === "show"
    ) {
      updatedState[clickedField] = "hide"
    } else {
      updatedState[clickedField] = "show"
    }

    // console.log(updatedState)

    this.setState({
      documentAccordians: updatedState
    })
  }

    render () {
      let { 
        metaFields, 
        documentFields,
        documentAccordians, 
        documentFieldCollections
      } = this.state
      let { 
        documentObject, 
        filteredPDFs, 
        processStatus 
      } = this.props

      if (this.props.documentStep === "step--02-start") {
        documentFields = this.props.documentFields
      }

      // console.log('this.props.documentStep')
      // console.log(this.props.documentStep)

      // console.log('documentFields')
      // console.log(documentFields)

    // if (props) {
      return (
        <div className="examiner__review mt-4 col-md-12 row">
          <div className={ "col-md-12 justify-content-center row buttons__choice " + this.props.documentStep }>
            <button className="form-control col-md-3 mr-1" value="step--02-start"    onClick={(event) => this.props.choiceButtonEffect(event) }>Start New</button>

            <button className="form-control col-md-3 mr-1" value="step--02-existing" onClick={(event) => this.props.choiceButtonEffect(event) }>Edit Existing</button>

            {/*<div>Message</div>*/}
          </div>
        
          <div className={ "col-md-6 row m-auto client__information " + this.props.documentStep }>
            <h2 className={ "col-md-12" }>Search for File</h2>
            {
              Object.entries(metaFields).map((field, field_index) =>

                 <div className={ 'col-md-12 row ' + field[0] } key={ 'col-md-6 row ' + field[0] }>
                  <label className="col-md-12">{ field[1]["name"] }
                    { field[1]["type"] === "dropdown" ?
                      <select
                        type={ field[1]["type"] }
                        name={ field[0] }
                        section={ 'metadata' }
                        parent={ 'summary' }
                        onChange={ this.props.handleChangeInput }
                        className={ ' form-control ' + field[0] }
                        key={ "input" + field_index }
                      >
                        <option value="">Choose</option>
                        {
                          field[1]["options"].split('|').map((option, option_index) =>
                            documentObject.metadata && 
                            documentObject.metadata.folder_id === option.split('-')[1] ?
                              <option 
                                key={ option + option_index } 
                                value={ option }
                                selected>
                                  { option.split('-')[0] }
                              </option>
                            :
                              <option 
                                key={ option + option_index } 
                                value={ option }>
                                  { option.split('-')[0] }
                              </option>
                            
                          )
                        }
                      </select>
                    :
                      <input
                        type={ field[1]["type"] }
                        name={ field[0] }
                        placeholder={ field[1]["placeholder"] }
                        section={ 'metadata' }
                        parent={ 'summary' }
                        onChange={ this.props.handleChangeInput }
                        className={ ' form-control ' + field[0] }
                        key={ "input" + field_index }
                        value={ documentObject['metadata'][field[0]] }
                      />
                    }

                    { 
                      filteredPDFs && field[0] === 'meta_file_id' ?
                        <div className="suggestions">
                        { filteredPDFs.map((option, option_index) => 
                          <p key={ option + option_index } value={ option.document.metadata.meta_file_id } onClick={ this.selectDocumentId }>{ option.document.metadata.meta_file_id }</p>
                        ) }
                        </div>
                      :
                        null
                    }
                  </label>
                </div>
              
              )
            }

            <div className={ 'col-md-12 row' }>
            { this.props.documentStep === 'step--02-start' ?
                <button className="form-control col-md-6 ml-3" onClick={ this.props.createNewDocument }>Create Document</button>
              : this.props.documentStep === 'step--02-existing' ?
                <button className="form-control col-md-6 ml-3" onClick={ this.props.loadExistingDocument }>Load Document</button>
              :
                null
            }

              <div className="col-md-12">
                { processStatus.form }
              </div>
            </div>

            <input
              type="hidden"
              name="exactDocumentId"
              key="exactDocumentId"
              value={ this.props.documentObject.metadata["exactDocumentId"] }
              onChange={ this.props.handleChangeInput }
              section={ 'metadata' }
            />
          </div>

          { this.props.documentStep === 'step--03-start' || this.props.documentStep === 'step--03-existing' ?
            <div className="col-md-12">
              <div className="document__fileinformation col-md-12">
                <div className="col-md-12 mt-4 mb-4 row section__editing">
                      <IntakeFormFileInformation
                        handleFileInformationSubmit={ this.props.handleFileInformationSubmit }
                        handleChangeInput={ this.props.handleChangeInput } 
                        documentObject={ documentObject }
                      />
                </div>
              </div>
            </div>
            :
              null
          }
                
          <form className={ "document__editing row col-md-12 " + this.props.documentStep } onSubmit={ this.props.handleSubmit }>
              <div className={ "document__content col-md-12" }>
              {
                Object.entries(documentFields).map((collection, collection_index) =>
                  <div className={ documentAccordians["sections"+collection_index] + ' col-md-12 mt-4 mb-4 row section__editing' }
                    key={ "sections" + collection_index }>
                    <h2 
                      className={ "col-md-12" }
                      onClick={ this.accordianFields.bind(this) }
                      name={ "sections"+collection_index }
                    >{ collection[0] }</h2>

                  {
                  Object.entries(collection[1]).map((problem, problem_index) =>
                    problem[1] && problem[1]["display"] !== "single" ?
                        <div className={ documentAccordians[problem[0]] + ' col-md-12 row ' + problem[0] } key={ 'problem' + problem[0] }>
                          {/* <h3
                            className={ "col-md-12" }
                            onClick={ this.accordianFields.bind(this) }
                            name={ problem[0] }
                          >
                            { problem[1]["attributes"]["name"] }
                          </h3> */}
                            {
                              Object.entries(problem[1]).map((entry, entry_index) =>
                                entry[0] !== "attributes" ?
                                  <div className={ " col-md-12 row form-group entryNumber" + entry_index } key={ "row form-group entryNumber" + entry_index }>
                                    <div className="row col-md-12 accordian_singlename-controls">
                                      {                                    
                                        problem[1]["attributes"]["display"] === "multiple" ?
                                          <>
                                            <h5 
                                              className="col-md-3"
                                              onClick={ this.accordianFields.bind(this) }
                                              name={ problem[0] + (entry_index+1) }
                                            >
                                              { problem[1]["attributes"]["name"] + " " + (entry_index+1) }
                                            </h5>

                                            <p className={ documentAccordians[problem[0] + (entry_index+1)] + " col-md-1 accordian__arrow" }>
                                              <button 
                                                className="pull-right"
                                                onClick={ this.accordianFields.bind(this) }
                                                name={ problem[0] + (entry_index+1) }
                                              >
                                                &#8249;
                                              </button>
                                            </p>
                                          </>
                                        :
                                          <>
                                            <h5 className="col-md-6">{ problem[1]["attributes"]["name"] + "" }</h5>
                                          </>
                                      }
                                    </div>

                                    <div className={ 
                                      documentAccordians[problem[0] + (entry_index+1)] + " col-md-12 clear row accordian__content " + problem[0] + (entry_index+1)
                                      }>
                                      {
                                        Object.entries(entry[1]).map((field_attr, field_attr_index) =>
                                            <div className={ field_attr[0] + " " + field_attr[1]["class"] } key={ field_attr[0] + " " + field_attr[1]["class"] }>
                                                <InputBuilder 
                                                  problem={ problem } 
                                                  field_attr={ field_attr } 
                                                  entry_index={ entry_index } 
                                                  problem_index={ problem_index } 
                                                  field_attr_index={ field_attr_index }
                                                  documentObject={ this.props.documentObject }
                                                  handleChangeInput={ this.props.handleChangeInput } 
                                                  key={ problem + problem_index + entry_index }
                                                />
                                            </div>
                                          ) /* Field Close */
                                      }

                                      {
                                      problem[1]["attributes"] && problem[1]["attributes"]["display"] === "multiple" ?
                                        <button 
                                          type="button" 
                                          onClick={ this.removeFields.bind(this) } 
                                          className={ problem[0] + " btn btn-default btn-outline-danger ml-2 clear" }
                                          collection={ collection[0] }
                                          problem={ problem[0] }
                                          number={ entry_index }>
                                            <i className="fa fa-trashcan"></i> Remove
                                        </button>
                                      :
                                        null
                                      }
                                    </div>
                                  </div>
                                : /* Entry if */
                                  problem[1]["attributes"] && problem[1]["attributes"]["display"] === "multiple" ?
                                    <div className="col-md-12 accordian__add" key={ entry_index }>
                                      {/* <button type="button" onClick={ this.removeFields.bind(this) } className={ problem[0] + " btn btn-default btn-primary ml-2" }>Remove a { problem[1]["attributes"]["name-singular"] }</button> */}


                                      <button 
                                        type="button" 
                                        onClick={ this.duplicateFields.bind(this) } 
                                        className={ problem[0] + " clear btn btn-default btn-outline-primary" }
                                        collection={ collection[0] }
                                        problem={ problem[0] }>
                                          Add a { problem[1]["attributes"]["name-singular"] }
                                      </button>
                                    </div>
                                  :
                                    null
                                ) /* Entry Close */
                            }
                        </div>
                      : /* Problem if */
                       <div className={ 'col-md-12 row ' + problem[0] }>
                        <label>{ problem[1]["name"] }
                          <input
                            type={ problem[1]["type"] }
                            name={ problem[0] }
                            section={ problem[1]["section"] }
                            // onClick={() => bubbledevent}
                            onChange={ this.props.handleChangeInput }
                            className={ 'col-md-4 form-control ' + problem[0] }
                            key={ "input" + problem_index }
                          />
                        </label>
                      </div>
                    
                  ) /* Problem Close */
                }
                </div>
              )
            }
            </div>

            <div className="save_document">
              <button type="submit" className="btn btn-default btn-outline-primary">Save Document</button>
              
              <p className="status__message status--form">{ processStatus.form }</p>
            </div>


          </form>

        </div>
      )
    }
    // } else {
      // return null
    // }
}


