import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import axios from 'axios'
import DownloadLink from "react-download-link";

import getTitleBoxContent from './TitleBoxContent'
import getTitleBoxProblemDocuments from './TitleBoxProblems'
import checkUWAL from './CheckUWAL'
import OrderForm from './OrderForm'
import Report from './Report'
import ContentLogic from './ContentLogic'
import arrangeArray from './ContentFixedBoxesLayout'
import OnPageMenu from '../navbar/OnPageMenu'
import TaskPopup from './TaskPopup.jsx'
import TaskFormInputs from '../intakeform/TaskFormInputs'
import IntakeSettlementChecklist from '../intakeform/IntakeSettlementChecklist'

var contentAPI = ''
var sid = {
  "role" : 'role',
  "subscriptions" : 'subscriptionsJSON',
  "expiresAt" : 'expiresAt',
  "idToken" : 'authResult',      
}
sid = JSON.stringify(sid)

// console.log(sid)

const localArray = [
  'localhost',
  '192.168.0.149',
  '192.168.1.31'
]

// console.log(sid)

if (localArray.some(v => window.location.hostname.includes(v))) {
  contentAPI = 'https://' + window.location.hostname + ':3001/api'
} else {
  contentAPI = 'https://' + window.location.hostname + '/api'
}

class Review extends Component {
  constructor(props) {
    super(props);

    this.state = {
      documentContent:      '',
      activeTopId:          'mortgages',
      activeSubId:          0,
      activeSubName:        '',
      showOverlay:          false,
      showOverlayAction:    false,
      pageTitleBoxContent:  'Choose a Problem then a Section.',
      uwal:                 'passed',
      loadingStatus:        'Loading Document...',
      reprocessedAddress:   '' 
    }

    this.handleScroll         = this.handleScroll.bind(this)
    this.handleTopClick       = this.handleTopClick.bind(this)
    this.submitOrderForm      = this.submitOrderForm.bind(this)
    this.handleChangeMetadata = this.handleChangeMetadata.bind(this)
    this.handleAnchorLink     = this.handleAnchorLink.bind(this)
    this.hideShowReportCard   = this.hideShowReportCard.bind(this)
  }

  componentDidMount() {
    window.scrollTo(0, 0)
    setTimeout( function() {
      localStorage.setItem('fileid', '')
    }, 500)
    // var component = this
    // console.log(sidebarDocuments)

    const email      = localStorage.getItem('email')
    const allow = [
      'exam@thetitlebox.com',
      'purple2@thetitlebox.com',
      'purple7@thetitlebox.com',
      'blue3@thetitlebox.com',
      'blue5@thetitlebox.com',
      'heather@thetitlebox.com',
      'orders@thetitlebox.com',
      'amber@titleeq.com',
      'matt@titleeq.com',
      'jamie@titleeq.com',
      'matt@thetitlebox.com',
      'jamie@thetitlebox.com',
      'whitney@krape.com',
      'wkrape@gmail.com'
    ]

    if (allow.includes(email)) {
      // console.log(email)
      this.setState({
        admin: true
      })
    }



    const hostname = window.location.hostname

    let uploadedDocument  = sessionStorage.getItem('uploadedDocument')
    let metadata          = {}
    metadata              = sessionStorage.getItem('metadata')
    let folder_id         = ''
    let meta_file_id      = ''

    uploadedDocument      = JSON.parse(uploadedDocument)
    if (metadata) {
      metadata            = JSON.parse(metadata) 

      folder_id           = metadata.folder_id
      meta_file_id        = metadata.meta_file_id
    } else if (uploadedDocument && uploadedDocument.metadata) {
      folder_id           = uploadedDocument.metadata.folder_id
      meta_file_id        = uploadedDocument.metadata.meta_file_id
    }

    if (uploadedDocument && !folder_id) {
      console.log('folder_id')
      console.log(folder_id)

      folder_id           = uploadedDocument.metadata.folder_id

      console.log('folder_id')
      console.log(folder_id)
    }

    console.log('meta_file_id')
    console.log(meta_file_id)

    console.log('folder_id')
    console.log(folder_id)

    if (meta_file_id && folder_id) {


      let getCachedURL = contentAPI + '/loadExactCachedDocument'

      // Reload the document then do everything below
      axios.post(getCachedURL, {
        data: {
          'meta_file_id': meta_file_id,
          // 'exactDocumentId': "618bf26006cdf76428cb7295",
          'selectionCollection': folder_id
        }
      })
      .then((response) => {
        console.log('response')
        console.log(response)

        let documentObject = uploadedDocument

        response = JSON.parse(response.data.results)

        // console.log(response)
        documentObject.metadata.file_id = response.document.metadata.file_id
        documentObject.metadata.path = response.document.metadata.path
        documentObject.data = response.document.data
        documentObject.grade = response.document.grade
        documentObject.tasks = ''

        //console.log('response.document.tasks')
        //console.log(response.document.tasks)

        //console.log('response.document.tasks.length')
        //console.log(response.document.tasks.length)

        if (response.document.tasks && response.document.tasks.length) {
          documentObject.tasks = response.document.tasks
        }
        if (response.document.settlementchecklist && response.document.settlementchecklist.length) {
          documentObject.settlementchecklist = response.document.settlementchecklist
        }

        uploadedDocument = documentObject



      // console.log(metadata)

      sessionStorage.setItem('metadata', JSON.stringify(documentObject.metadata))

      var sidebarDocuments = this.getSupportingDocuments()

      let propertyAddress             = uploadedDocument.data.property_address
      let propertyAddressReprocessed  = uploadedDocument.data.property_address_reprocessed
      let propertyAddressReprocessedMatchAttempt = ''
      if (propertyAddressReprocessed)
        propertyAddressReprocessedMatchAttempt = propertyAddressReprocessed.match(stateMatch)
      let propertyAddressReprocessedCounty  = uploadedDocument.data.property_address_reprocessed_county
      const stateMatch                = /(.*) (AL|AK|AS|AZ|AR|CA|CO|CT|DE|DC|FM|FL|GA|GU|HI|ID|IL|IN|IA|KS|KY|LA|ME|MH|MD|MA|MI|MN|MS|MO|MT|NE|NV|NH|NJ|NM|NY|NC|ND|MP|OH|OK|OR|PW|PA|PR|RI|SC|SD|TN|TX|UT|VT|VI|VA|WA|WV|WI|WY)/g
      if (propertyAddress)
        propertyAddress                 = propertyAddress.match(stateMatch)
      let propertyAddressState        = 'None'
      let propertyAddressCounty       = 'None'
      let propertyAddressStateReprocessed = ''

      if (uploadedDocument.data.property_state) {
        propertyAddressState = uploadedDocument.data.property_state
      }

      if (uploadedDocument.data.property_county) {
        propertyAddressCounty = uploadedDocument.data.property_county
      }

      console.log('propertyAddress Starting out')
      console.log(propertyAddress)

      console.log('propertyAddressState')
      console.log(propertyAddressState)

      // This needs to get the starting address and try getting a state from there, a county also.
      // IF it's got nothing. Then go to the reprocessed. (In fairness, this is what is there but, all it needed was the second check!? )


      if (propertyAddress && propertyAddressState === 'None' || 
        propertyAddress && propertyAddressState.length !== 2
      ) {
        propertyAddress = propertyAddress[0].replace(' ', '').split(',')

        propertyAddressState = propertyAddress[propertyAddress.length-1]
        propertyAddressState = propertyAddressState.trim()

        console.log('propertyAddress In State')
        console.log(propertyAddress)

        propertyAddressCounty = propertyAddress[propertyAddress.length-2]
      }

        // console.log('propertyAddressReprocessed')
        // console.log(propertyAddressReprocessed)

      if (propertyAddressReprocessed && 
        propertyAddressReprocessedMatchAttempt &&
        propertyAddressState.length !== 2) {
        propertyAddressReprocessed = propertyAddressReprocessedMatchAttempt

        propertyAddressStateReprocessed = propertyAddressReprocessed[0].split(',')
        propertyAddressStateReprocessed = propertyAddressStateReprocessed[propertyAddressStateReprocessed.length-1].replace(' ', '')
      
        // console.log('propertyAddressStateReprocessed')
        // console.log(propertyAddressStateReprocessed)

        propertyAddressState = propertyAddressStateReprocessed
      }

      if (propertyAddressState && propertyAddress && propertyAddressState.length !== 2) {
        propertyAddress = propertyAddress[0].replace(' ', '').split(',')

        propertyAddressState = propertyAddress[propertyAddress.length-1]
        propertyAddressState = propertyAddressState.trim()
      }


      // console.log('propertyAddressCounty')
      // console.log(propertyAddressCounty)

      if (!propertyAddressCounty || 
        !propertyAddressCounty.includes('County') && 
        propertyAddressReprocessedCounty) {
        propertyAddressCounty = propertyAddressReprocessedCounty
      }

      propertyAddressCounty = propertyAddressCounty.trim()
      propertyAddressCounty = propertyAddressCounty.replace('County', '')

      if (metadata) {
        metadata.state = propertyAddressState
        metadata.county = propertyAddressCounty
      } else {
        metadata = {}

        metadata.state = propertyAddressState
        metadata.county = propertyAddressCounty
      }


      // console.log(propertyAddressState)
      // console.log(propertyAddressCounty)

      window.addEventListener('scroll', this.handleScroll, { passive: true })

      // console.log(uploadedDocument)

      var assembledContent = ContentLogic(uploadedDocument)
      uploadedDocument = assembledContent.uploadedDocument
      var uploadedDocumentGradeLetter = assembledContent.gradingLetter
      var uploadedDocumentGradeNumber = assembledContent.gradingNumber

      // console.log(uploadedDocument)

      if (uploadedDocument) {
          var separatedContent = {
            'bankruptcies':                     uploadedDocument.data.bankruptcies,
            'judgments':                        uploadedDocument.data.judgments,
            'mortgages':                        uploadedDocument.data.mortgages,
            'real_estate_taxes':                uploadedDocument.data.real_estate_taxes,
            'foreclosure':                      uploadedDocument.data.foreclosure,
            'current_water_and_sewer_rents':    uploadedDocument.data.current_water_and_sewer_rents,
            'mechanics_liens_municipal_claims': uploadedDocument.data.mechanics_liens_municipal_claims,
            'entities':                         uploadedDocument.data.entities,
            'estate':                           uploadedDocument.data.estate,
            'divorce':                          uploadedDocument.data.divorce,
            'hoacondo':                         uploadedDocument.data.HoaCondo,
            'ucc':                              uploadedDocument.data.ucc
          } // 

          // console.log(uploadedDocument)
          if (uploadedDocument.data.recital) {
            Object.values(uploadedDocument.data.recital).forEach((content, index) => {
              // console.log(content.grantee_nextgrantor_match_score)

              if (content &&
                content.grantee_nextgrantor_match_score &&
                content.grantee_nextgrantor_match_score < 100) {
                this.setState({
                  chainOfTitleIssue:   true
                })
              }
            })

            uploadedDocument.data.recital = uploadedDocument.data.recital.reverse()
          } // if recital

          // console.log(uploadedDocument)

          // if (hostname.includes("instant")) {
          //   uploadedDocument.tasks = ""
          // } // if instant

          // console.log('uploadedDocument')
          // console.log(uploadedDocument)

          this.setState({
            documentContent:   uploadedDocument,
            separatedContent:  separatedContent,
            metadata:          metadata,
            gradingLetter:     uploadedDocumentGradeLetter,
            gradingNumber:     uploadedDocumentGradeNumber,
            tasks:             uploadedDocument.tasks, 
          })

          // console.log(propertyAddressState)
          // console.log(propertyAddressCounty)

          if (propertyAddressState && propertyAddressCounty) {
            getTitleBoxProblemDocuments(this, propertyAddressState, propertyAddressCounty, 'mortgages')
          } // if propertystate and county

          checkUWAL()

        } // if uploaded document
      })
      .catch((err) => {
        console.log(err)
      })





    } else {
      this.setState({
        loadingStatus: 'No document found. '
      })
    }
  } // if metadata and supporting document


  copyToClipboard(event) {
    var areaToCopy = document.getElementById('legal_description')

    areaToCopy = areaToCopy.innerHTML
    navigator.clipboard.writeText(areaToCopy)


    event.target.focus();
    this.setState({ 
      copySuccess: 'copied'
    })
  }

  handleTopClick(id, event) {
    if (event)
      event.preventDefault()

    var propertyAddressState = this.state.metadata.state
    var propertyAddressCounty = this.state.metadata.county 
    var pageTitleBoxContentMessage = 'Choose an Option on the Left.'
    var titleBoxId = id

    if (id == "current_water_and_sewer_rents") {
      titleBoxId = "real_estate_taxes"
    } else if (id == "mechanics_liens_municipal_claims") {
      titleBoxId = "judgments"
    }

    console.log('state')
    console.log(propertyAddressState)
    console.log('county')
    console.log(propertyAddressCounty)

    getTitleBoxProblemDocuments(this, propertyAddressState, propertyAddressCounty, titleBoxId)
    // getTitleBoxContent(this)

    this.setState({ 
      activeTopId: id,
      pageTitleBoxContent: pageTitleBoxContentMessage,
      activeSubName: '',
      pageTitleBoxSections: ''
      // Clear out the anchor links
    })
  }

  handleSubClick(id, event, component) {
    event.preventDefault()

    // console.log(id)

    this.setState({ activeSubId: id })
  }

  handleSubCategory(id, event) {
    event.preventDefault()

    // console.log(id)

    var metadata = this.state.metadata
    metadata.documentId = id.DocumentId

    this.setState({
      activeSubName: id.Subcategory,
      metadata 
    })

    getTitleBoxContent(this)

    // console.log(this.state)
  }

  handleOverlayClick(id, event) {
    event.preventDefault()

    let updatedState = this.state.documentContent
    
    if (!this.state.showOverlay) {
      if (id === 'legal_description' || id === 'task__popup' || id === 'settlement__popup') {
          if (id === 'task__popup' && !updatedState.tasks) {

            updatedState.tasks = []

            this.setState({
              documentObject: updatedState
            })
          }

          this.setState({
            showOverlay: id
          })
      } else {
        let propertyAddressState = this.state.metadata.state
        let propertyAddressCounty = this.state.metadata.county

        getTitleBoxProblemDocuments(this, propertyAddressState, propertyAddressCounty, id)

        if (id) {
          this.setState({
            activeTopId: id, 
            showOverlay: id,
            pageTitleBoxContent: 'Choose an Option on the Left.',
            activeSubName: '' 
          })
        }
      }
    } else {
      this.setState({ 
        showOverlay: false 
      })
    }
  }

  handleClearanceClick(id, event) {
    event.preventDefault()

    if (!this.state.showOverlayAction) {
      this.setState({
        showOverlayAction: id
      })
    } else {
      this.setState({ 
        showOverlayAction: false 
      })
    }
  }

  submitOrderForm(event) {
    event.preventDefault()

    // console.log(this.state)

    const meta_file_id = this.state.metadata.meta_file_id
    const path = this.state.metadata.path
    const from = localStorage.getItem('email')
    // const meta_file_form_subject = this.state.metadata.meta_file_form_subject
    const meta_file_form_body = this.state.metadata.meta_file_form_body

    var blobData = new FormData()
    blobData.append('meta_file_id', meta_file_id)
    blobData.append('from', from)
    blobData.append('path', path)
    blobData.append('body', meta_file_form_body)

    var formURL = contentAPI + '/sendform'

    // console.log(meta_file_id + ' ' + path + ' ' + from + ' ' + meta_file_form_body)

    if (meta_file_id && from && path && meta_file_form_body) {
      // console.log('Success')

      fetch(formURL, {
        method: 'POST',
        body: blobData
      })
      .then((result) => { 
          // console.log(result)

          if (result.status === 200) {
            this.setState({
              formStatusMessage: 'Message sent! We will follow up with you soon.',
              formStatusErrorMessage: ''
            })

            setTimeout( () => {
              this.setState({ 
                formStatusMessage: '',
                showOverlayAction: false 
              })
            }, 2500)
          }
      })
      .catch((result) => {
          console.log(result)

          if (result.status != 200)
            this.setState({
              formStatusMessage: 'Message error, please reach out to us at order@thetitlebox.com.'
            })
      })
    } else {
      this.setState({
        formStatusErrorMessage: 'Please fill out all fields!'
      })
    }
  }

  handleChangeMetadata(event) { 
    // console.log(event.target.name)
    var fieldname = event.target.name
    var fieldvalue = event.target.value
    var metadata = this.state.metadata
      // console.log(this.state)
    metadata[fieldname] = fieldvalue

    this.setState({
      metadata
    })  
  }

  handleScroll(event) {
    // console.log(window.scrollY)

    if (window.scrollY > 520) {
      this.setState({ details_fixed: 'details_fixed' })
    } else {
      this.setState({ details_fixed: '' })
    }
    // do something like call `this.setState`
    // access window.scrollY etc
  }

  createMarkup(html) {
    // var html = html
    // console.log(html)
    if (Array.isArray(html)) {
      var rebuild = ''

      // Pipe through arrangeArray

      html.forEach((element) => {
        rebuild += "<div class='col-md-12'><h3><small>Match Score</small>     <span>" + element.grantee_nextgrantor_match_score + "</span></h3></div>"
        rebuild += "<div class='col-md-6'><strong>Grantee</strong>            <span>" + element.grantee                         + "</span></div>"
        rebuild += "<div class='col-md-6'><strong>Grantor</strong>            <span>" + element.grantor                         + "</span></div>"
        rebuild += "<div class='col-md-6'><strong>Date</strong>               <span>" + element.date                            + "</span></div>"
        rebuild += "<div class='col-md-6'><strong>Recorded Date</strong>      <span>" + element.recorded_date                   + "</span></div>"
        rebuild += "<div class='col-md-6'><strong>Recorded In</strong>        <span>" + element.recorded_in                     + "</span></div>"
        rebuild += "<div class='col-md-6'><strong>Recorded Location</strong>  <span>" + element.recorded_location               + "</span></div>"
        // rebuild += "<div class='col-md-12'><strong>How</strong>               <span>" + element.how                             + "</span></div>"
        rebuild += "<div class='col-md-12'><h4>Recital</h4>                   <span>" + element.fulltext                        + "</span></div>"
        rebuild += "<hr />"
      })

      html = rebuild
    }

    html = html.replace(/\n/g, "<br/>");
    // console.log(html)

    return {__html: html}
  }

  getSupportingDocuments = () => { 
    var metadata = sessionStorage.getItem('metadata')
    var selectionCollection = ''
    var meta_file_id = ''
    if (metadata)
      metadata = JSON.parse(metadata)
    if (metadata.path) {
      selectionCollection = metadata.path.split('/')[0]
    } else {
      selectionCollection = sessionStorage.getItem('selectionCollection')
    }
    if (selectionCollection === 'xuXjMWpV787') {
      selectionCollection = 'xuXjMWpV7v6'
    }
    if (metadata.file_id) {
      meta_file_id = metadata.file_id
    } else if (metadata.path.split('/')[1]) {
      meta_file_id = metadata.path.split('/')[1]
    } else {
      meta_file_id = metadata.meta_file_id
    }

    const last = meta_file_id.charAt(meta_file_id.length - 1);

    if (last === '-')
      meta_file_id = metadata.meta_file_id

    // console.log(meta_file_id)
    // console.log(selectionCollection)

    var getURL = contentAPI + '/getSupportingDocuments'

    var blobData = new FormData()
    blobData.append('clientFile',   meta_file_id)
    blobData.append('clientFolder', selectionCollection)

    // console.log('meta_file_id')
    // console.log(meta_file_id)

    // console.log('selectionCollection')
    // console.log(selectionCollection)

    if (meta_file_id) {
      axios.post(getURL, {
        data: {
          'clientFile':   meta_file_id,
          'clientFolder': selectionCollection
        }
      })
      .then((response) => {
          // console.log('response.data.sendBackList') 
          // console.log(response.data.sendBackList)
          var sendBackList = response.data.sendBackList
          var report = "report";
          sendBackList = sendBackList.sort(function(item,itemNext) { 
            // console.log(item.documentName.toLowerCase().includes(report));
            return item.documentName.toLowerCase().includes(report) ? -1 : itemNext.documentName.toLowerCase().includes(report) ? 1 : 0; 
          })

          this.setState({
            supportDocuments: sendBackList
          })
      })
      .catch((err) => {
          // console.log(err)
      })
    }
  }

  handleAnchorLink = (event) => {
    event.preventDefault()
    var targetID = event.target.toString().split('#')
    targetID = targetID[1]
    let targetToScrollTo = document.getElementById(targetID);
    targetToScrollTo && targetToScrollTo.scrollIntoView()

    // console.log(targetID)
  }

  remapTopIDName = (activeTopId, textCase) => {
    const arrayOfNames = {
      // 'current_water_and_sewer_rents'     : 'REAL ESTATE TAXES',
      'mechanics_liens_municipal_claims'  : 'JUDGMENTS',
      'bankruptcies'                      : 'BANKRUPTCY',
      'estate'                            : 'Estates',
      'hoacondo'                          : 'HOA and Condo'
    }
    var renamedTopId = arrayOfNames[activeTopId]

    if (!renamedTopId) {
      renamedTopId = activeTopId.replace(/_/g, ' ').toUpperCase()
    } 

    return renamedTopId
  }

  hideShowReportCard(event) {
    console.log(this.state)

    this.setState(prevState => ({
      showReportCard: !prevState.showReportCard
    }))
  }

  handleOptionReload = (event) => {
    window.location.reload()
  }

  onClickDownload = (documentAddress, documentName) => {
    console.log('https://transfertciabstorage.blob.core.windows.net/attachments/' + documentAddress)

    fetch('https://transfertciabstorage.blob.core.windows.net/attachments/' + documentAddress, { mode: 'no-cors'}).then(response => {
        response.blob().then(blob => {
            // Creating new object of PDF file
            const fileURL = window.URL.createObjectURL(blob);
            // Setting various property values
            let alink = document.createElement('a');
            alink.href = fileURL;
            alink.download = documentName;
            alink.click();
        })
    })
  }

  render () {
    var { 
      documentContent,
      tasks,
      metadata, 
      activeTopId, 
      activeSubId,
      activeSubName, 
      details_fixed, 
      separatedContent, 
      showOverlay, 
      showOverlayAction,
      formStatusMessage,
      formStatusErrorMessage,
      pageTitleBoxContent,
      pageTitleBoxSections,
      pageTitleBoxSubCategories,
      gradingLetter,
      gradingNumber,
      chainOfTitleIssue,
      supportDocuments,
      uwal,
      showReportCard,
      loadingStatus,
      admin
    } = this.state
    let legal_descriptionContent = ''
    let chain_of_titleContent = ''
    // var numberOfMortgages = 0
    // console.log(details_fixed)
    // console.log(activeTopId + ' ' + activeSubId)
    // console.log(this.state)
    let projectState = 'PA'
    let projectCounty = 'Philadelphia'
    let processStatus = { "tasks": "review" }

    const getProblemsListOrigin = [
        {'id' : 'bankruptcies',                         'title' : 'Bankruptcies'},
        {'id' : 'mechanics_liens_municipal_claims',     'title' : 'Mechanics Liens Municipal Claims'},
        {'id' : 'mortgages',                            'title' : 'Mortgages'}, 
        {'id' : 'judgments',                            'title' : 'Judgments'}, 
        {'id' : 'real_estate_taxes',                    'title' : 'Real Estate Taxes'},
        {'id' : 'foreclosure',                          'title' : 'Foreclosure'},
        {'id' : 'current_water_and_sewer_rents',        'title' : 'Water and Sewer'},
        {'id' : 'entities',                             'title' : 'Entities' },
        {'id' : 'estate',                               'title' : 'Estate' },
        {'id' : 'divorce',                              'title' : 'Divorce' },
        {'id' : 'hoacondo',                             'title' : 'HOA Condo' },
        {'id' : 'ucc',                                  'title' : 'UCC' }
      ]

    const alwaysShow = [
        'mortgages', 'judgments'
      ]

    var getProblemsList = []
    var getSubList = []

    if (separatedContent && activeTopId) {
      getSubList = separatedContent[activeTopId]
    }

    if (
      documentContent.data &&
      documentContent.data.recital
    ) {
      chain_of_titleContent = documentContent.data.recital
    } else {
      chain_of_titleContent = 'No Chain of Title Found'
    }

    if (
      documentContent.data &&
      documentContent.data.legal_description
    ) {
      legal_descriptionContent = documentContent.data.legal_description
    } else {
      legal_descriptionContent = 'No Legal Description Found'
    }

    /*
    if (tasks) {
      console.log('this.state.documentContent.tasks')
      console.log(tasks['mortgages'])
      console.log(Object.entries(tasks['mortgages']).length)
    }
    */

    return ( 

      <div className="uncontainer">
        { documentContent.data ?
        <>
          <div className="">
            <OnPageMenu metadata={ documentContent.metadata } handleOptionReload={ this.handleOptionReload.bind(this) } />
          </div>

          <div className="row exam__upper__segment">
            <section className="col-md-2 sidebar exam__sidebar">
              <div className="sidebar__top__introduction">

                <div className="documentStatus">
                  { metadata.status ?
                    <small style={{ textTransform: 'capitalize' }}>
                      { metadata.status.replaceAll('-', ' ') } 
                    </small>
                    : null
                  }
                </div>

                <div className="documentGrading">
                  { documentContent.metadata.upload_filename === 'Att-Title Search Report.20210601125720 (2).pdf' ?
                    'C' :
                    gradingLetter ?
                      gradingLetter :
                    'NA' }
                  { gradingNumber ?
                    <small>{gradingNumber} / 100</small>
                    : null
                  }
                </div>

                <div className="documentID">
                  { metadata.meta_file_id ?
                    metadata.meta_file_id :
                    'No Document ID found' 
                  }
                </div>

                <div className="documentAddress">
                  { documentContent.data.property_address &&
                    documentContent.data.property_address_reprocessed &&
                    documentContent.data.property_address.length > documentContent.data.property_address_reprocessed.length ||
                    documentContent.data.property_address && !documentContent.data.property_address_reprocessed ?
                      documentContent.data.property_address 
                    :
                      documentContent.data.property_address && documentContent.data.property_address_reprocessed && 
                        documentContent.data.property_address.length < documentContent.data.property_address_reprocessed.length ?
                        documentContent.data.property_address_reprocessed
                      :
                        'No Document Address found'
                  }
                </div>

                <div className="documentOwners">
                  <span>owned by:</span><br/> 
                  { documentContent.data.current_owner ?
                    documentContent.data.current_owner :
                    'No Owner Found'
                  }
                </div>
              </div>

            </section>

            <section className="col-md-10 content exam__maincontent">
              <div className="document__sections__highlights row">
                <div className="section__chainoftitle col-md-4">
                  { chainOfTitleIssue ?
                    <img src="/img/tb_exam_cross.png" alt="Chain of Title Check"/>
                    : 
                    <img src="/img/tb_exam_check.png" alt="Chain of Title Check"/>
                  }
                   Chain of Title 
                  <Link 
                    className="see__details"
                    onClick={ this.handleOverlayClick.bind(this, 'chain_of_title') }
                    to="#">
                    See Details
                  </Link>
                </div>
                <div className="section__legaldescription col-md-4">
                  <img src="/img/tb_exam_check.png" alt="Legal Description Check"/> Legal Description 
                  <Link 
                    className="see__details"
                    onClick={ this.handleOverlayClick.bind(this, 'legal_description') }
                    to="#">
                    See Details
                  </Link>
                </div>
                <div className="section__uwal col-md-4 passed">
                  <img src="/img/tb_exam_check.png" alt="UWAL Check"/> UnderWriter Alert List
                  { uwal === 'passed' &&
                    <span>This property <strong>has not been flagged</strong> and is not on the UWAL!</span> 
                  }
                </div>
              </div>

              <div className="row title__row">
                <h1 className="col-md-4">Title clearance</h1> 
                  <button   
                    className="col-md-2 btn btn-secondary" 
                    to="#" 
                    onClick={ this.handleOverlayClick.bind(this, 'settlement__popup') }
                  >
                    Show Settlement Checklist
                  </button>

                  <button   
                    className="col-md-2 ml-0 btn btn-secondary" 
                    to="#" 
                    onClick={ this.handleOverlayClick.bind(this, 'task__popup') }
                  >
                    Show Tasks
                  </button>

                  <button className="col-md-3 ml-0"
                    onClick={ this.handleClearanceClick.bind(this, 'orderform') }
                  >
                    Order Full Service
                  </button>
              </div>

              <div className="document__sections__problems row">
                {
                  getProblemsListOrigin.map(list =>
                    list.id && separatedContent[list.id] !== false ?
                      <Link key={ list.id }
                            className={ activeTopId === list.id ? 'col-md-2 is--active' : 'col-md-2' }
                            onClick={ this.handleTopClick.bind(this, list.id) } 
                            to="#"
                      >
                        { list.title }
                        { separatedContent[list.id] && separatedContent[list.id].length ?
                            " (" + separatedContent[list.id].length + ")"
                          : null
                        }

                        <span className="notification">{ tasks && tasks[list.id.toLowerCase()] && Object.entries(tasks[list.id.toLowerCase()]).length ?
                            Object.entries(tasks[list.id.toLowerCase()]).length
                          : null
                        }</span>

                        { !separatedContent[list.id] &&
                          alwaysShow.indexOf(list.id) > -1 ||
                          separatedContent[list.id] &&
                          !separatedContent[list.id].length &&
                          alwaysShow.indexOf(list.id) > -1
                           ?
                            " (0)"
                          : null
                        }
                      </Link>
                    : null
                  )
                }
              </div>

              <div className="document__sections__subproblems row">
                { Array.isArray(getSubList) ?
                      getSubList.map((list, index) =>
                        <Link key={ index }
                              className={ activeSubId === index ? 'col-md-2 is--active' : 'col-md-2' }
                              onClick={ this.handleSubClick.bind(this, index) } 
                              to="#"
                        >
                          { this.remapTopIDName(activeTopId) + ' ' + (index+1) }
                        </Link>                    
                      )
                    : null
                }
              </div>

              <div className={ showOverlayAction ? 'overlay overlay--action is--active' : 'overlay' }>
                  { showOverlayAction ?
                    <div className={ showOverlayAction ? 'document__clearance__actions__container is--active' : 'document__clearance__actions__container' }>
                      <div className="col-md-10 overlay__content">                  
                        <div className={ showOverlayAction === "orderform" ? 'overlay__orderform is--active' : 'overlay__orderform' }>
                          { !formStatusMessage ?
                           <OrderForm meta_file_id={ metadata.meta_file_id } path={ metadata.path } onSubmitOrderForm={this.submitOrderForm} onHandleChangeMetadata={this.handleChangeMetadata} />
                            : formStatusMessage
                          }

                          {
                            formStatusErrorMessage ?
                              <div className="form_status__message">{formStatusErrorMessage}</div>
                            : null
                          }
                        </div>
                      </div>

                      <div className="overlay__close" onClick={ this.handleClearanceClick.bind(this, '') }>
                        X
                      </div>
                    </div>
                    : null
                  }
              </div>
            </section>
          </div>

          <div className="row exam__middle__segment">
            <section className="col-md-2 sidebar exam__sidebar">
              <div className="sidebar__bottom__introduction">
                <div className="sidebar__documents__list__search">
                  <h2 className="small--header">Title Search</h2>
                    { supportDocuments ?
                      supportDocuments.map(list => (
                        <>
                          <a 
                            target="_blank"
                            href={ "https://transfertciabstorage.blob.core.windows.net/attachments/" + list.documentAddress }
                          >
                            { list.documentName } 
                          </a>
                          <a 
                            target="_blank"
                            href={ "https://transfertciabstorage.blob.core.windows.net/attachments/" + list.documentAddress }
                            download="this"
                          >
                            { list.documentName } 
                          </a>
                        </>
                      ))
                    : null
                    }
                    {/*
                    <Link 
                      target="_blank"
                      to={ "/files/" + documentContent.metadata.upload_filename }>
                    { documentContent.metadata.upload_filename }
                    </Link>
                    */}
                </div>
              </div>
            </section>

            <div className="center__contentcolumn row col-md-8">
            <section className="col-md-12 content exam__maincontent">
              <div className={ showOverlay ? 'document__overall__details__container is--active' : 'document__overall__details__container' }>
                <div className={ details_fixed ? 'document__overall__details fixed__boxes details_fixed row' : 'document__overall__details fixed__boxes row' }>
                  {
                    Array.isArray(documentContent.data[activeTopId]) ?
                      <div className="col-md-12 columns-2 row" dangerouslySetInnerHTML={arrangeArray(documentContent, activeTopId, activeSubId)}></div>
                    : 
                      <div className="col-md-12 columns-2 row" dangerouslySetInnerHTML={arrangeArray(documentContent, activeTopId)}></div>
                  }
                </div>

                <div className={ showOverlay === "chain_of_title" || showOverlay === "legal_description" || showOverlay === "settlement__popup" ? 'overlay is--active' : 'overlay' }>
                  { showOverlay === "chain_of_title" || showOverlay === "legal_description" || showOverlay === "settlement__popup" ?
                    <div className="col-md-10 overlay__content">
                      <div className={ showOverlay === "chain_of_title" ? 'overlay__chain_of_title is--active' : 'overlay__chain_of_title' }>
                        <h5>Chain of Title</h5>  

                        { chain_of_titleContent && 
                            <div className="row col-md-12" dangerouslySetInnerHTML={this.createMarkup(chain_of_titleContent)}></div>
                        }
                      </div>

                      <div className={ showOverlay === "legal_description" ? 'overlay__legal_description is--active' : 'overlay__legal_description' }>
                        <h5>Legal Description</h5>

                        <button onClick={ this.copyToClipboard.bind(this) }>Copy to Clipboard</button>  

                        { legal_descriptionContent && 
                            <div className="row col-md-12" id="legal_description" dangerouslySetInnerHTML={this.createMarkup(legal_descriptionContent)}></div>
                        }
                      </div>

                      <div className={ showOverlay === "settlement__popup" ? 'is--active settlement__popup p-2 col-md-2' : 'd-none settlement__popup p-2 col-md-2' }>
                        <IntakeSettlementChecklist 
                          documentStep={ "show-on--edit" }
                          documentObject={ documentContent }
                          processStatus={ processStatus }
                          admin={ admin }
                        />
                      </div>
                    </div>
                    : null
                  }

                  <div className="overlay__close" onClick={ this.handleOverlayClick.bind(this, '') }>
                    X
                  </div>
                </div>


              </div>
            </section>

            
              <div className="row col-md-12 exam__lower__segment">
                <button className={ 'report ' + this.state.showReportCard } onClick={ this.hideShowReportCard }>Report an <span>Edit or Update</span></button>
                { this.state.showReportCard ? <Report sending={ this.state.sending } hideMe={ this.hideShowReportCard } pageTitle={ this.state.activeTopId } documentName={ this.state.metadata } sections={ this.state.sections } state={ projectState } county={ projectCounty } /> : null }

                <div className="row titlebox__document__breadcrumbs col-md-12">
                  <h3>{ this.remapTopIDName(activeTopId) }</h3>
                    { activeSubName &&
                      <h4 className="title-gray">&raquo; { activeSubName }</h4>
                    }
                </div>

                <div className="sidebar__documents__list__titlebox col-md-3">
                  <div className="sidebar__documents__list__titlebox__inner rounded">
                    {/* <h2 className="small--header">TitleBox</h2> */}
                      <ul className="sidebar-sticky">
                        {
                          pageTitleBoxSubCategories &&
                          pageTitleBoxSubCategories.length > 0 &&
                          Object.entries(pageTitleBoxSubCategories).map((list, index) => 
                            <li><Link 
                              className="see__details"
                              onClick={ this.handleSubCategory.bind(this, list[1]) }
                              to="#">
                                { list[1].Subcategory }
                            </Link></li>                       
                          )
                        }
                        {
                          pageTitleBoxSections && pageTitleBoxSections.length > 1 &&
                            Object.entries(pageTitleBoxSections).map((list, index) => 
                              <li><Link
                              onClick={ this.handleAnchorLink.bind(this) }
                              to={ "/review#section" + list[0] }>
                                { list[1] }
                                </Link>
                              </li>                       
                            )
                        }
                      </ul>
                  </div>
                </div>
              

                <div className="current__titlebox__document col-md-9">
                  {/*
                  <div className="content">
                    { pageContent &&
                      <div dangerouslySetInnerHTML={this.createMarkup(pageContent)}></div>
                    }
                  </div>
                  */}

                  <div className="document__content rounded">
                    { pageTitleBoxContent &&
                      <div dangerouslySetInnerHTML={this.createMarkup(pageTitleBoxContent)}></div>
                    }
                  </div>
                </div>

              </div>
            </div>

            <div className={ showOverlay === "task__popup" ? 'is--active task__popup p-2 col-md-2' : 'd-none task__popup p-2 col-md-2' }>
              { documentContent.tasks ?
                  <TaskFormInputs 
                      documentStep={ "show-on--review" }
                      documentObject={ documentContent }
                      processStatus={ processStatus }
                    />
                :
                  null
              }
            </div>
          </div>
        </>

        : 
          <div className="row">
            <p className="p-6 col-md-10">
              { loadingStatus }
              { 
                loadingStatus === 'No document found. ' ?
                  <a href="/dashboard">Return to List.</a>
                  :
                  null
              }
            </p>
          </div>
        }
      </div>
    )
  }
}

export default Review; 
