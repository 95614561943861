import React, { Component } from 'react'
import axios from 'axios'

var sid = {
  "role" : 'role',
  "subscriptions" : 'subscriptionsJSON',
  "expiresAt" : 'expiresAt',
  "idToken" : 'authResult',      
}

function utf8_for_xml(inputStr) {
  return inputStr.replace(/[^\x20-\xFF\x85\xA0-\uD7FF\uE000-\uFDCF\uFDE0-\uFFFD]/gm, '');
}

export default function getTitleBoxProblemDocuments(component, state, county, problem) {
  var contentAPI = ''

  const localArray = [
    'localhost',
    '192.168.0.149',
    '192.168.1.31'
  ]

  // console.log(sid)

  if (localArray.some(v => window.location.hostname.includes(v))) {
    contentAPI = 'https://' + window.location.hostname + ':3001/api'
  } else {
    contentAPI = 'https://' + window.location.hostname + '/api'
  }



  if (problem === 'bankruptcies') {
    problem = 'bankruptcy'
  }

  if (problem === 'hoacondo') {
    problem = 'ASSOCIATIONS AND COMMUNITIES'
  }

  if (problem === 'divorce') {
    problem = 'MARRIAGE AND DIVORCE'
  }

  if (problem === 'estate') {
    problem = 'ESTATES'
  }

  if (problem === 'ucc') {
    problem = 'MORTGAGES'
  }

  county = county.replace(/ /g, '')

  var issue    = ''
  var finalURL = contentAPI + '/documents/' + state + '/' + county + '/' + problem

  issue = problem
  issue = issue.replace(/_/g, ' ')

  component.setState({
    pageTitleBoxSubCategories: ''
  })

  console.log('problem')
  console.log(problem)

  console.log('finalURL')
  console.log(finalURL)

  axios
  .post(finalURL, {
    headers: { 'Cache-Control': 'no-cache' },
    responseType: 'arraybuffer',
    data: {
      issue: issue,
      checkLogin: 'loggedIn',
      sid: sid
    }
  })
  .then((result) => {
      console.log('result.data.files')
      console.log(result.data.files)


      var pageTitleBoxSubCategoriesResult = result.data.files
      pageTitleBoxSubCategoriesResult = pageTitleBoxSubCategoriesResult.sort((a, b) => (a.Subcategory > b.Subcategory) ? 1 : -1)

      component.setState({
        pageTitleBoxSubCategories: pageTitleBoxSubCategoriesResult
      })
  })
  .catch((result) => {

  }) 
}