import React, { Component } from 'react';
import axios from 'axios'
import { Redirect, Link } from 'react-router-dom'


var contentAPI = ''
var sid = {
  "role" : 'role',
  "subscriptions" : 'subscriptionsJSON',
  "expiresAt" : 'expiresAt',
  "idToken" : 'authResult',      
}
sid = JSON.stringify(sid)

if (window.location.hostname === '192.168.0.149') {
  contentAPI = 'https://' + window.location.hostname + ':3001/api'
} else {
  contentAPI = 'https://' + window.location.hostname + '/api'
}



if (!String.prototype.includes) {
  String.prototype.includes = function(search, start) {
    'use strict';

    if (search instanceof RegExp) {
      throw TypeError('first argument must not be a RegExp');
    } 
    if (start === undefined) { start = 0; }
    return this.indexOf(search, start) !== -1;
  };
}

export class Walkthrough extends Component {
  constructor(props) {
    super(props)

    this.state = {
      loadingprogress: ''
    }

    this.loadThisDocument = this.loadThisDocument.bind(this)
  }

  componentDidMount() {
    var folderid = this.props.match.params.folderid
    var fileid = this.props.match.params.fileid

    // console.log(folderid)
    // console.log(fileid)


    this.setState({
      loadingprogress: 'Starting...'
    })

    let email            = localStorage.getItem('email')
    let uploadedDocument = sessionStorage.getItem('uploadedDocument')
    if (uploadedDocument)
      uploadedDocument = JSON.parse(uploadedDocument)
    var filesPDFs = sessionStorage.getItem('filesPDFs')
    var selectionCollection = sessionStorage.getItem('selectionCollection')

    // console.log(email)
    // console.log(selectionCollection)


    var getCachedURL = contentAPI + '/getCachedDocuments'

      // console.log(selectionCollection)
      // console.log(this.state.selectionCollection)

    var uploadURL = contentAPI + '/companycheck'

    axios.post(uploadURL, {
      data: {
        'ClientEmail': email,
      }
    }).then((response) => {
      // console.log(response.data.result.ClientId)
      selectionCollection = response.data.result['ClientId']

      // console.log('here1')

      if (selectionCollection) {
        var getCachedURL = contentAPI + '/getCachedDocuments'

        this.setState({
          loadingprogress: "Loading Documents..."
        })

        axios.post(getCachedURL, {
          data: {
            'selectionCollection': selectionCollection,
          }
        }).then((response) => {
          sessionStorage.setItem('filesPDFs', JSON.stringify(response.data.result))
          this.setState({
            filesPDFs: response.data.result,
            loadingprogress: 'Getting document!'
          })
          sessionStorage.setItem('selectionCollection', selectionCollection)

          let search = window.location.search
          if (search) {
            let params = new URLSearchParams(search)
            let state = params.get('state')
            if (state) {
              let base64ToString = Buffer.from(state, "base64").toString();
              base64ToString = base64ToString.split('?')
              base64ToString = base64ToString[1]
              let base64ToStringparams = new URLSearchParams(base64ToString)
              
              // console.log(base64ToString)

              folderid = base64ToStringparams.get('folder_id')
              fileid = base64ToStringparams.get('file_id').replace('"', '')

              console.log('folderid')
              console.log(folderid)

              this.loadThisDocument(fileid, folderid)
            } else {
              let params = new URLSearchParams(search)
              let file_id = params.get('file_id')
              file_id = file_id.replace('"', '')

              this.setState({
                loadingprogress: 'Not loading by state.'
              })

              this.loadThisDocument(file_id)
            }

            // console.log(fileid)
          
          }
        }).catch((err) => {
          this.setState({
            loadingprogress: 'Error loading Documents or pulling the found Document from the list.'
            // Create link
          })
          console.log(err)
        })
      }
    }).catch((err) => {
      this.setState({
        loadingprogress: 'Error connecting to server.'
      })
      // console.log(err)
    })
  }

  loadThisDocument = (fileid, folderid) => {
    console.log('loadThisDocument')
    console.log(fileid + ' ' + folderid)

    var filesPDFs = this.state.filesPDFs
    filesPDFs.reverse()

    // This really isn't needed... Or it just needs clean up. 
    if (filesPDFs)
      var selectedDocument = filesPDFs.find( ({ document }) => document.file_id === fileid && document.metadata.folder_id === folderid)
      console.log(filesPDFs)
      console.log('selectedDocument')
      console.log(selectedDocument)

      if (!selectedDocument) {
        console.log('notloadedat01')
        selectedDocument = filesPDFs.find( ({ document }) => document.metadata.file_id === fileid && document.metadata.folder_id === folderid)
      }

      if (!selectedDocument) {
        console.log('notloadedat02')
        selectedDocument = filesPDFs.find( ({ document }) => document.metadata.meta_file_id === fileid && document.metadata.folder_id === folderid)
      }

      console.log('selectedDocument')
      console.log(selectedDocument)

      if (!selectedDocument) {
        console.log('notloadedat03')
        selectedDocument = filesPDFs.find( ({ document }) => document.metadata.summary.file_id === fileid && document.metadatah.folder_id === folderid)
      }

      console.log('selectedDocument')
      console.log(selectedDocument)

      if (selectedDocument) {
        var metadata = selectedDocument.document.metadata
        // console.log(metadata)

        selectedDocument = JSON.stringify(selectedDocument.document)
        metadata = JSON.stringify(metadata)

        // console.log(metadata)
        sessionStorage.setItem('metadata', metadata)
        sessionStorage.setItem('uploadedDocument', selectedDocument)

        this.setState({
          loadingprogress: 'Document loaded, Redirecting...'
        })
      } else {
        this.setState({
          loadingprogress: 'Could not get the document, check the document list below.'
        })
      }
  }

  componentWillReceiveProps(props) {
  }

  render() {
    return (
      <div className="">
        { this.state.loadingprogress }

        {
          this.state.loadingprogress === 'Document loaded, Redirecting...' ?
            <Redirect to="/review" />
          : null
        }

        {
          this.state.loadingprogress === 'Could not get the document, check the document list below.' ?
            <Link to="/upload">Document List</Link>
          : null
        }
      </div>
    )
  }
}

export default Walkthrough;
