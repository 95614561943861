export let glossary = (placeToLook, originalTerm) => {
    const documentAccordians = {
      "sections0"   :  "show",
      "sections1"   :  "hide",
      "sections2"   :  "hide",
      "sections3"   :  "hide",
      "sections4"   :  "hide",
      "sections5"   :  "hide",
      "sections6"   :  "hide",
      "sections7"   :  "hide",
      "sections8"   :  "hide",
      "sections9"   :  "hide",
      "sections10"   :  "hide",
      "sections11"   :  "hide",
      "sections12"   :  "hide",
      "sections13"   :  "hide",
      "sections14"   :  "hide",
      "sections15"   :  "hide",
      "sections16"   :  "hide",
      "sections17"   :  "hide",
      "sections18"   :  "hide",
      "sections19"   :  "hide",
      "sections20"   :  "hide"
    }

    const statusesObject = {
      'undefined':          ' ',
      '':                   'Choose',
      "new-order":          "New Order",       
      "exam":               "Exam",      
      "on-deck":            "On Deck",       
      "clearative":         "Clearative",  
      "clear-to-close":     "Clear to Close",
      "qc":                 "QC",       
      "on-hold":            "On Hold",       
      "cancelled":          "Cancelled"
    }

    const problemTaskObject = {
      "general":          	"General",       
      "mortgages":          "Mortgages",      
      "judgments":          "Judgments",       
      "HoaCondo":         	"HOA / Condo",      
      "entities":          	"Entities",      
      "judgments":          "Judgments",      
      "judgments":          "Judgments",      
      "judgments":          "Judgments"
    }

    const problemTaskSingularObject = {
      "general":            "General",       
      "mortgages":          "Mortgage",      
      "judgments":          "Judgment",       
      "HoaCondo":           "HOA / Condo",      
      "entities":           "Entitie",      
      "judgments":          "Judgment",      
      "judgments":          "Judgment",      
      "judgments":          "Judgment"
    }

    const priorityTaskObject = {
      "1low":          		"Low",       
      "low":          		"Low",       
      "1medium":          "Medium",       
      "3critical":        "Critical",
      "3critcal":         "Critical",
      "p0low":            "Low",       
      "p1medium":         "Medium",       
      "p3critical":       "Critical",
      " ":          		  " "
    }

    const statusesTaskObject = {
      '':                   'Choose',
      "requested":          "Requested",       
      "inprocess":          "In-Process",      
      "closed":            	"Closed",       
      "open":         		"Open"
    }

    const usersObject = {  
      '':                   'Choose',
      "email01@email.com":  "Exam Group",       
      "email02@email.com":  "Orders Group",     
      "email03@email.com":  "Amy Erazmus",      
      "email04@email.com":  "Becky Maddox",     
      "email06@email.com":  "Emily Hartline",   
      "email07@email.com":  "Heather Spickler", 
      "email08@email.com":  "Michael Miller",   
      "email09@email.com":  "Nickole Faust",    
      "email10@email.com":  "Ruth Martell",     
    }

    const clientObject = {  
      'Choose':                   '',
      "Alpha Abstract Agency LLC":"ppGjymvaX33",
      "August Title":"Cir9TLCjUCj",
      "BlockLand Transfer":"g5iLxTJqhF",
      "Bluegrass Land Title, LLC":"4i79ykgc944r",
      "Brownstone Abstract":"RMujPZY3nR",
      "C. Jackson Nantz, III, P.C.":"7jrgVl6yp09",
      "Cassidy Title, LLC":"odf45hs890g",
      "Capstone Land Transfer LLC":"p2gL54mk7I3",
      "Cardinal Settlements LLC":"81a6GSDCr1",
      "CB Title Group LLC":"dCFx2fL5KW",
      "Divvy Title":"qjk5kQrdhT",
      "Duane C. Romanello, P.A.":"OdQciUTzd6",
      "Epoch Property Transfer, LLC":"ua45t6aa1kn",
      "Express Title Agency LLC":"FxhPGHFn8j",
      "Faxon Settlement Services LLC":"qQ9yaRUYlp9",
      "Florida Real Estate Title & Closings, Inc":"h5y8juKk9la0",
      "Flow Title LLC":"rBiuMlk8mo10",
      "Flynn Title":"h9bJVG4wxBV",
      "Forever Home Title":"KsgR0Quc0v",
      "Gennex Settlement Services":"7S4jZp18LO",
      "Good Deeds & Co":"rKbAyYyAfv2",
      "Houwzer Title":"xuXjMWpV7v2",
      "Innovative Land Transfer, LLC":"DC3XTM7W2s",
      "Law Offices of Michael C. Lima":"ff56Y79kla6",
      "Leap Settlement Services":"rddHM56ya46",
      "LemonBrew Abstract LLC":"0frr5yui8lk",
      "Milestone Title Services, LLC":"2g7hG3kL9k0",
      "My Philly Abstract LLC":"6uk7i0f5rty",
      "NextGen":"8J56yt329l",
      "Nova Abstract":"hK6tGi4aAJ",
      "PHL Associates LLC a/b/a PHL Abstract":"ppGjymvaX33",
      "PM Title LLC":"t6yaA7KC4lay",
      "Prominent Title":"hb2IAx3RHf",
      "RARE Title Services LLC dba RARE Settlement Services LLC":"ppGjymvaX33",
      "Real Property Transfer":"oA7wsWCaKC",
      "Signature Abstract":"glo2MXitX0",
      "SJ Abstract LLC dba Interstate Abstract":"po7eVdoaqzo",
      "South Central Agency, Inc":"X2JfTW8Yjd",
      "Stewart Abstract of Berks County, Inc.":"029JGRF5nL6",
      "TestDocuments":"xuXjMWpV7v4",
      "TitleBox":"xuXjMWpV7v6",
      "True Value Title":"Iu9GLACYn7",
      "Truist Abstract, LLC":"8iig5gh6l5j",
      "TitleIQ LLC dba TitleEQ":"p57g1h70id3",
      "Urban Abstract, Inc":"fo758iohkuo",
      "Wagon Wheel Title and Escrow, LLC":"rt5e3klje53"   
    }

    const combineAllObjects = {
    	documentAccordians: 	{ ...documentAccordians },
    	statusesObject: 		  { ...statusesObject },
    	usersObject: 			    { ...usersObject },
    	clientObject: 			  { ...clientObject },
    	statusesTaskObject: 	{ ...statusesTaskObject },
    	problemTaskObject: 		{ ...problemTaskObject },
      problemTaskSingularObject: { ...problemTaskSingularObject },
      priorityTaskObject:   { ...priorityTaskObject },
    	all: 					{ 
    		...documentAccordians,
    		...statusesObject,
    		...usersObject,
    		...clientObject,
    		...statusesTaskObject,
    		...problemTaskObject,
        ...priorityTaskObject
    	}
    }

    let getNewTerm = combineAllObjects[placeToLook][originalTerm]

	return getNewTerm
}