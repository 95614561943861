import React, { Component } from 'react'
import Dropzone from 'react-dropzone'
import { Link } from 'react-router-dom'
import axios from 'axios'
import history from '../../history'

// More .jsx and more loaded here
import DynamicSearch from './DynamicSearch';
import ReviewLink from './ReviewLink';
import { onDrop } from './UploadFunctions.js';

var contentAPI = ''
var sid = {
  "role" : 'role',
  "subscriptions" : 'subscriptionsJSON',
  "expiresAt" : 'expiresAt',
  "idToken" : 'authResult',      
}
sid = JSON.stringify(sid)

const localArray = [
  'localhost',
  '192.168.0.149',
  '192.168.1.31'
]

// console.log(sid)

if (localArray.some(v => window.location.hostname.includes(v))) {
  contentAPI = 'https://' + window.location.hostname + ':3001/api'
} else {
  contentAPI = 'https://' + window.location.hostname + '/api'
}

const parserAPI = 'https://ttbsearchextractorappv0.azurewebsites.net'


class SortableList extends Component {
  constructor(props) {
    super(props);
    
    // console.log('props')
    // console.log(props.searchBy)

    this.state = {
      filesPDFs: [],
      filteredPDFs: [],
      dropzoneActive: false,
      selectedDocument: '',
      uploadedDocument: '',
      mainMessage: '',
      supportingMessage: '',
      metadata: {
        meta_company: '',
        meta_file_state: '',
        meta_file_id: '',
        meta_file_client_name: 'TitleBox'
      },
      faults: 0,
      typing: false,
      typingTimeout: 0,
      admin: false,
      searchByAvailable: props.searchBy
    }

    this.handleChangeMetadata = this.handleChangeMetadata.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    // this.loadThisDocument = this.loadThisDocument.bind(this)
    this.dynamicSearch = this.dynamicSearch.bind(this)
    this.deleteThisDocument = this.deleteThisDocument.bind(this)
  }

  onDragEnter() {
    this.setState({
      //dropzoneActive: true
    });
  }

  onDragLeave() {
    this.setState({
      //dropzoneActive: false
    });
  }

  onDragEnterPDF() {
    this.setState({
      //dropzoneActivePDF: true
    });
  }

  onDragLeavePDF() {
    this.setState({
      //dropzoneActivePDF: false
    });
  }

  getAllDocuments(ID) {

  }

  // On Drop Multiple
  onMultipleNonSearchDrop(files) {

    Object.entries(files).forEach((document) => {
      var uploadURL = contentAPI + '/uploadSupportingDocument'
      var selectedDocument = document.name
      var selectionCollection = sessionStorage.getItem('selectionCollection')
      var validateMetadata = []
      var selectedWholeDocument = files[document[0]]
      validateMetadata = this.state.metadata
      var meta_file_id = validateMetadata.meta_file_id

      var blobData = new FormData()
      blobData.append('documentName', selectedDocument)
      blobData.append('clientFile',   meta_file_id)
      blobData.append('clientFolder', selectionCollection)
      blobData.append('documentWholeFile', selectedWholeDocument)

      // console.log(selectedWholeDocument)

      // console.log('blobData')
      // console.log(meta_file_id)
      // console.log(selectionCollection)

      this.setState({ supportingMessage: ''})
 
      if (meta_file_id) {
        fetch(uploadURL, {
          method: 'POST',
          body: blobData
        })
        .then((result) => { 
            // console.log(result)

            if (result.status === 200) {
              this.setState({ supportingMessage: 'Supporting Document(s) Uploaded. '})

              setTimeout(() => {
                this.setState({ supportingMessage: ''})
              }, 2500)
            }
        })
        .catch((result) => {
            // console.log(result)
        })
      }
    })

    /*
      var uploadURL = contentAPI + '/uploadCacheDocument'

      var blobData = new FormData()
      blobData.append('documentName', selectedDocument)
      blobData.append('clientFolder', selectionCollection)

      // console.log('blobData')
      // console.log(blobData)

      fetch(uploadURL, {
        method: 'POST',
        body: blobData
      })
      .then((result) => { 
          console.log(result)
      })
      .catch((result) => {
          console.log(result)
      })
    */
  }

  recheckUploadedFiles = () => {
    var filesPDFs = sessionStorage.getItem('filesPDFs')
    var selectionCollection = sessionStorage.getItem('selectionCollection')

    var getCachedURL = contentAPI + '/getCachedDocuments'

      // console.log(selectionCollection)
      // console.log(this.state.selectionCollection)

    if (selectionCollection) {
      axios.post(getCachedURL, {
        data: {
          'selectionCollection': selectionCollection,
        }
      }).then((response) => {
        // console.log(response)
        sessionStorage.setItem('filesPDFs', JSON.stringify(response.data.result))

        filesPDFs = JSON.parse(filesPDFs)

        return filesPDFs
      }).catch((err) => {
        // console.log(err)

        return err
      })
    }
  }

  deleteThisDocument = (event, documentId, path, folder_id) => {
    event.preventDefault()

    console.log(path)

    var getDeleteURL = contentAPI + '/deleteCachedDocument'
    var filesPDFs = this.state.filesPDFs
    path = path.split("/")
    path = path[0]

    path = folder_id

    var removethisone = filesPDFs.filter(document => document._id != documentId)

    /*
    console.log('filesPDFs')
    console.log(removethisone)
    console.log('event')
    console.log('id')
    console.log('path')
    console.log(event)
    // console.log(id)
    console.log(path)
    */  

    if (documentId && path) {
      axios.post(getDeleteURL, {
        data: {
          'documentId': documentId,
          'selectionCollection': path
        }
      }).then((response) => {
        // console.log('response.data.result.deletedCount')
        // console.log(response.data.result.deletedCount)
        console.log('response')
        console.log(response)

        if (response.data.result.deletedCount === 1) {
          this.setState({
            filesPDFs: removethisone
          })
        }
      }).catch((err) => {
        console.log(err)

        // return err
      })
    }
  }

  componentDidMount() {
    let email            = localStorage.getItem('email')
    let uploadedDocument = sessionStorage.getItem('uploadedDocument')
    let temporaryFileId  = localStorage.getItem('fileid')
    if (uploadedDocument)
      uploadedDocument = JSON.parse(uploadedDocument)
    var filesPDFs = sessionStorage.getItem('filesPDFs')
    var selectionCollection = sessionStorage.getItem('selectionCollection')
    // var selectionCollection = 'xuXjMWpV7v6'
    let showUpload = false

    // console.log(window.location.hostname)

    /*
    if (window.location.hostname != 'instant.thetitlebox.com' && window.location.hostname != 'examdemoapp.azurewebsites.net') {
      showUpload = true
    }
    */

    const allow = [
      'exam@thetitlebox.com',
      'purple2@thetitlebox.com',
      'purple7@thetitlebox.com',
      'blue3@thetitlebox.com',
      'blue5@thetitlebox.com',
      'heather@thetitlebox.com',
      'orders@thetitlebox.com',
      'amber@titleeq.com',
      'matt@titleeq.com',
      'jamie@titleeq.com',
      'matt@thetitlebox.com',
      'jamie@thetitlebox.com',
      'whitney@krape.com',
      'wkrape@gmail.com'
    ]

    if (allow.includes(email)) {
      // console.log(email)
      this.setState({
        admin: true
      })

      if (!window.location.hostname.includes('instant'))
        showUpload = true
    }

    this.setState({
      filesPDFsLoading: 'Will load documents in a moment. (If you\'ve waited more than a few seconds, reload the page.)',
      showUpload:       showUpload
    })

    var getCachedURL = contentAPI + '/getCachedDocuments'
    var uploadURL = contentAPI + '/companycheck'

    axios.post(uploadURL, {
      data: {
        'ClientEmail': email,
      }
    }).then((response) => {
      // console.log(response.data.result.ClientId)
      selectionCollection = response.data.result['ClientId']

      // console.log('here1')

      if (selectionCollection) {
        var getCachedURL = contentAPI + '/getCachedDocuments'

        this.setState({
          filesPDFsLoading: "Loading documents..."
        })

        if (this.state.searchByAvailable === "Rejected") {
          selectionCollection = 'xuXjMWpV787'
        }

        axios.post(getCachedURL, {
          data: {
            'selectionCollection': selectionCollection,
          }
        }).then((response) => {
          filesPDFs = response.data.result
          filesPDFs = filesPDFs.reverse()

          sessionStorage.setItem('filesPDFs', JSON.stringify(filesPDFs))
          this.setState({
            filesPDFs: filesPDFs,
            filesPDFsLoading: ''
          })
          sessionStorage.setItem('selectionCollection', selectionCollection)

          if (temporaryFileId && temporaryFileId.length) {
            localStorage.setItem('fileid', '')

            if (temporaryFileId && temporaryFileId.length) {
              this.loadThisDocument(temporaryFileId, 'yes')
            }
          }
        }).catch((err) => {
          this.setState({
            filesPDFsLoading: 'Error loading documents.'
          })
          console.log(err)
        })
      }
    }).catch((err) => {
      this.setState({
        filesPDFsLoading: 'Error getting server list.'
      })
      // console.log(err)
    })

    /*
    setTimeout(() => {
      var filesPDFsFromState = this.state.filesPDFs

      console.log('filesPDFsFromState.length')
      console.log(filesPDFsFromState.length)
      console.log('filesPDFs')
      console.log(filesPDFs)

      if (!filesPDFsFromState.length && filesPDFs) {
        console.log('Rechecking storage')

        sessionStorage.setItem('filesPDFs', filesPDFs)
      } else if (!filesPDFsFromState.length && filesPDFs === null) {
        console.log('Rechecking from Mongo')

        axios.post(getCachedURL, {
          data: {
            'selectionCollection': selectionCollection,
          }
        }).then((response) => {
          // console.log(response)
          sessionStorage.setItem('filesPDFs', JSON.stringify(response.data.result))

          filesPDFs = JSON.parse(filesPDFs)

          this.setState({
            filesPDFs: filesPDFs
          })
        }).catch((err) => {
          // console.log(err)
        })
      }
    }, 1000)
    */
  }


  handleChangeMetadata(event) { 
    // console.log(event.target.name)
    var fieldname = event.target.name
    var fieldvalue = event.target.value
    var metadata = this.state.metadata

    if (fieldname === 'meta_file_id') {
      var currentDocumentList = this.state.filesPDFs

      // console.log('currentDocumentList')
      // console.log(currentDocumentList)
      // console.log(this.state)

      if (currentDocumentList) {
        var filteredPDFs = currentDocumentList.filter( ({ document }) => {

          if (typeof document.metadata.meta_file_id !== 'undefined') {
            // document.metadata.meta_file_id.includes(fieldvalue)
          }
        })

        // console.log('filteredPDFs')
        // console.log(filteredPDFs)

        metadata[fieldname] = fieldvalue

        this.setState({
          metadata
        })

        // console.log('filteredPDFs.length')
        // console.log(filteredPDFs.length)

        if (filteredPDFs.length > 0 && fieldvalue.length > 2) {
          var faults = 1

          // console.log('faults')
          // console.log(faults)

          this.setState({
            faults: faults,
            faultMessage: 'File ID exists, filter the available search before uploading.'
          })
        } else {
          this.setState({
            faults: 0,
            faultMessage: ''
          })
        }
      }
    } else {
      // console.log(this.state)
      metadata[fieldname] = fieldvalue

      this.setState({
        metadata
      })  
    }


    // console.log(this.state)  
  }

  handleSubmit(event) {
    event.preventDefault()

    // validate
    var validateMetadata = []
    validateMetadata = this.state.metadata
    var faults = 0

    // console.log(validateMetadata)

    Object.values(validateMetadata).forEach((value) => {
      if (value && value !== "") {
        // console.log(value)
        // console.log('Fine')
      } else {
        faults++

        // console.log(value)
        // console.log('Not Fine')
      }
    })

    if (faults === 0) {
      validateMetadata = JSON.stringify(validateMetadata)
      sessionStorage.setItem('metadata', validateMetadata)
    } else {
      // console.log('faults')
      // console.log(faults)
    }
  }

  dynamicSearch = () => {
    let allPDFs = this.state.filesPDFs
    let searchTerm = this.state.searchTerm
    let searchByAvailable = this.state.searchByAvailable
    let filteredPDFs = allPDFs.filter( ({ document }) => {
      let filterByFilename = ''
      if (document.metadata.all_filenames) {
        filterByFilename  = document.metadata.all_filenames[0]
      }
      let filterById          = document.metadata.meta_file_id
      let filterByClient      = document.data.client_name
      let filterByAddress     = document.data.property_address
      let filterByMortgages   = ""

      /*
      if (document.tasks && document.tasks.length) {
        // Map down to just the needed fields and includes from there
        filterByMortgages   = Object.values(document.tasks.mortgages).map((option, option_index) => {
            console.log(option)
            return option.status
          })
      }
      */


      if (filterById === undefined)
        filterById = '100000001'

      console.log(filterByMortgages)

      filterById = filterById.toString()

      // console.log(filterById)
      // console.log(filterById.includes(searchTerm))

      if (searchByAvailable === "User") {
        if (filterById.includes(searchTerm))
          return document
      } else if (searchByAvailable === "Admin") {
        if (filterById.includes(searchTerm) || 
          filterByClient.includes(searchTerm) || 
          filterByAddress && filterByAddress.includes(searchTerm) || 
          filterByMortgages.includes(searchTerm))
          return document
      } else if (searchByAvailable === "Rejected") {
        if (filterByFilename.includes(searchTerm))
          return document
      }
    })

    // console.log(filteredPDFs)

    this.setState({
      filteredPDFs: filteredPDFs
    })
  }

  editSearchTerm = (e) => {
    const self = this;

    if (self.state.typingTimeout) {
       clearTimeout(self.state.typingTimeout);
    }

    self.setState({
       searchTerm: e.target.value,
       typing: false,
       typingTimeout: setTimeout(function () {
          self.dynamicSearch()
         }, 800)
    })
  }

  render () {   
    let { 
      filesPDFs,
      filesPDFsLoading,
      filteredPDFs, 
      dropzoneActive, 
      uploadedDocument,
      mainMessage,
      supportingMessage,
      faults,
      faultMessage,
      newState,
      showUpload,
      searchByAvailable,
      searchPrompt,
      searchTerm,
      admin
    } = this.state

    // console.log('filesPDFs')
    // console.log(filesPDFs)

    // console.log(filesPDFs)
    // console.log('this.state')
    // console.log(this.state)

    if (searchByAvailable === 'User') {
      searchPrompt = 'File ID'
    } else if (searchByAvailable === 'Admin') {
      searchPrompt = 'Address, Client, File ID'
    } else if (searchByAvailable === 'Rejected') {
      searchPrompt = 'Filename'
    }

    return (
      <>
        { faults !== 0 ?
          <div>
            <h3>{this.state.faultMessage}</h3>
          </div> 
        : null }

        { filesPDFs && filesPDFs.length ? 
          <div className="uploaded__documents">
            <div className="row" style={{"paddingLeft": "27px"}}>
              <h2 className="col-md-12 p-0"><a name="examiner__previous">Available Searches</a></h2>

              <input type='text' className="form-control col-md-5" value={this.state.searchTerm || ''} onChange={this.editSearchTerm} placeholder={ 'Filter Searches by ' + searchPrompt } /> 
            </div>

            { filteredPDFs.length < 1 && !searchTerm ?
              <ReviewLink filesPDFs={ filesPDFs } searchBy={ searchByAvailable } deleteThisDocument={ this.deleteThisDocument } />
              :
              <ReviewLink filesPDFs={ filteredPDFs } searchBy={ searchByAvailable } deleteThisDocument={ this.deleteThisDocument } />
            }
          </div>
        : 
          null
        }

        { filesPDFsLoading }
      </>
    )
  }
}

export default SortableList;
