import React, { Component } from 'react'
import Dropzone from 'react-dropzone'
import { Link } from 'react-router-dom'
import axios from 'axios'
import history from '../../history'

// More .jsx and more loaded here
import SortableList from '../upload/SortableList'
import OnPageMenu from '../navbar/OnPageMenu'

var contentAPI = ''
var sid = {
  "role" : 'role',
  "subscriptions" : 'subscriptionsJSON',
  "expiresAt" : 'expiresAt',
  "idToken" : 'authResult',      
}
sid = JSON.stringify(sid)

const localArray = [
  'localhost',
  '192.168.0.149',
  '192.168.1.31'
]

// console.log(sid)

if (localArray.some(v => window.location.hostname.includes(v))) {
  contentAPI = 'https://' + window.location.hostname + ':3001/api'
} else {
  contentAPI = 'https://' + window.location.hostname + '/api'
}

const parserAPI = 'https://ttbsearchextractorappv0.azurewebsites.net'


class Rejected extends Component {
  constructor(props) {
    super(props)

    this.state = {
    }
  }

  componentDidMount() {
  }

  render () {
    var { 
      showUpload,
      admin
    } = this.state

    return (
      <div className="p-6 examiner__upload">
        <h1>Welcome to the TitleBox LIVESearch&trade;</h1>
        <small>Version 0.2.385</small>

        <div className="col-md-12">
          <OnPageMenu />
        </div>

        <SortableList searchBy="Rejected" />
      </div>
    )
  }
}

export default Rejected;
