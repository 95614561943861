import { Link } from 'react-router-dom'
import React, { Component } from 'react'

import { useSortableData } from './useSortableData.js';
import { loadThisDocument } from './UploadFunctions.js';

let TaskDigestion = (objectToMod) => {

  return objectToMod
}

let ReviewLink = (props) => {
    // console.log('searchBy')
    // console.log(props)

    if (props.filesPDFs) {
      const filesPDFs = props.filesPDFs
      const { items, requestSort, sortConfig } = useSortableData(filesPDFs)
      const getClassNamesFor = (name) => {
        // console.log(name)
        if (!sortConfig) {
          return
        }
        return sortConfig.key === name ? sortConfig.direction : 'off'
      }

      let sortButtons = []
      let columnSpan = 3

      if (props.searchBy === "User") {
        sortButtons = [
          ['data.search_date',      'Search Date'],
          ['data.property_address', 'Address'],
          ['metadata.meta_file_id', 'File ID']
        ]

        columnSpan = 4
      } else if (props.searchBy === "Admin") {
        sortButtons = [
          ['metadata.uploaded_at',  'Uploaded Date'],
          ['data.property_address', 'Address'],
          ['data.client_name',      'Client'],
          ['metadata.meta_file_id', 'File ID']
        ]

        columnSpan = 3
      } else if (props.searchBy === "Rejected") {
        sortButtons = [
          ['metadata.uploaded_at',  'Uploaded Date'],
          ['data.metadata.all_filenames', 'Filename']
        ]

        columnSpan = 5
      }

      return (
        <div className="examiner__review mt-4 row">
          { TaskDigestion("") }
          { TaskDigestion("") }

          <div className="col-md-12 row">
            {
              sortButtons.map((item, index) => 
                <button
                  type="button"
                  onClick={ () => requestSort(item[0]) }
                  className={'col-md-' + columnSpan + ' sort__button ' + getClassNamesFor(item[0])}
                  key={"sortButton" + index}
                >
                  { item[1] } <span className="indicator">&#9650;</span>
                </button>
              )
            }
          </div>
          {
            items.map((list, index) =>
              list &&
              <div className="col-md-12 row document__list__item" id={list._id} key={list._id + '__list__item'}>

                { props.searchBy === "User" ?
                  <Link
                    onClick={() => { loadThisDocument(list._id, filesPDFs) }}
                    key={list._id}
                    to="/review"
                    className="col-md-12 row">
                    <div className="col-md-4"><strong>{ list.document.data.search_date }</strong></div>

                    <div className="col-md-4"><strong>{ list.document.data.property_address }</strong></div>
                    
                    { list.document.metadata.meta_file_id ? 
                      <div className="col-md-4"><strong>{ list.document.metadata.meta_file_id }</strong></div>
                      :
                      <div className="col-md-4"><strong>{ list.name }</strong></div>
                    }
                  </Link>
                : '' }

                { props.searchBy === "Admin" ?
                  <Link
                    onClick={() => { loadThisDocument(list._id, filesPDFs) }}
                    key={list._id}
                    to="/review"
                    className="col-md-12 row">

                    { list.document.metadata.uploaded_at ? 
                      <div className="col-md-3"><strong>{ new Date(list.document.metadata.uploaded_at).getFullYear() + "-" + (new Date(list.document.metadata.uploaded_at).getMonth()+1) + "-" + new Date(list.document.metadata.uploaded_at).getDate()  }</strong></div>
                      :
                      <div className="col-md-3"><strong></strong></div>
                    }

                    <div className="col-md-3"><strong>{ list.document.data.property_address }</strong></div>
                    
                    <div className="col-md-3"><strong>{ list.document.data.client_name }</strong></div>

                    { list.document.metadata.meta_file_id ? 
                      <div className="col-md-3"><strong>{ list.document.metadata.meta_file_id }</strong></div>
                      :
                      <div className="col-md-3"><strong>{ list.name }</strong></div>
                    }

                    <div className="col-md-12">
                      <h5>Tasks</h5>
                      { list.document.tasks ? 
                        Object.entries(list.document.tasks).map((task, task_index) =>
                          <div key={ list._id + task[0]  } className="d-flex">
                            <p key={ list._id + task[0] }>Type : { task[1].problem } : Status : { task[1].status } : Due Date { task[1].due_date }</p>
                          </div>
                        )
                        :
                          null
                      }
                    </div>                
                  </Link>
                : '' }

                { props.searchBy === "Rejected" ?
                  <Link
                    onClick={() => { loadThisDocument(list._id, filesPDFs) }}
                    key={list._id}
                    to="/review"
                    className="col-md-12 row">

                    { list.document.metadata.uploaded_at ? 
                      <div className="col-md-5"><strong>{ new Date(list.document.metadata.uploaded_at).getFullYear() + "-" + (new Date(list.document.metadata.uploaded_at).getMonth()+1) + "-" + new Date(list.document.metadata.uploaded_at).getDate()  }</strong></div>
                      :
                      <div className="col-md-5"><strong></strong></div>
                    }

                    { list.document.metadata.all_filenames ? 
                      <div className="col-md-5"><strong>{ list.document.metadata.all_filenames[0] }</strong></div>
                      : ''
                    }               
                  </Link>
                : '' }

                <Link className="option__delete"
                    onClick={(event) => { props.deleteThisDocument(event, list._id, list.document.metadata.path, list.document.metadata.folder_id)}}
                    key={list._id + '_delete'}
                    to="/deleteDocument">X</Link>
              </div>
            )
          }

        </div>
      )
    } else {
      return null
    }
  }

export default ReviewLink