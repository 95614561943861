import { Link } from 'react-router-dom'
import React, { Component } from 'react'
import axios from 'axios'

var contentAPI = ''
var sid = {
  "role" : 'role',
  "subscriptions" : 'subscriptionsJSON',
  "expiresAt" : 'expiresAt',
  "idToken" : 'authResult',      
}
sid = JSON.stringify(sid)

const localArray = [
  'localhost',
  '192.168.0.149',
  '192.168.1.31'
]

// console.log(sid)

if (localArray.some(v => window.location.hostname.includes(v))) {
  contentAPI = 'https://' + window.location.hostname + ':3001/api'
} else {
  contentAPI = 'https://' + window.location.hostname + '/api'
}

const parserAPI = 'https://ttbsearchextractorappv0.azurewebsites.net'


export default class IntakeHistory extends Component{
    // console.log('props')
    // console.log(props)

    // Array here builds out the forms.
    //

  constructor(props) {
    super(props)

    this.state = {
      metaFields: this.props.metaFields,
      documentObject: this.props.documentObject
    }


  }


    render () {
      let { 
        documentObject
      } = this.state
      let { 
      } = this.props

      let listHistory = documentObject.history

    // if (props) {
      return (
        this.props.documentStep === 'step--03-start' || this.props.documentStep === 'step--03-existing' ?
            <>
              <h2 className="col-md-12">File History</h2>
              <div className="col-md-12 row">
                <table class="table">
                  <thead>
                    { listHistory ?
                        <tr>
                          <th scope="col"></th> 
                          <th scope="col">User</th> 
                          <th scope="col">Changed</th> 
                          <th scope="col">Updated At</th>
                        </tr>
                      :
                        null  
                    }
                  </thead>
                  <tbody>
                  { listHistory ?
                      Object.entries(listHistory).map((row, row_index) =>
                        row[1].user ?
                          <tr>
                            <th scope="row">{ row_index }</th>
                            <td>{ row[1].user }</td>
                            <td>
                              { row[1].changed ?
                                  Object.entries(JSON.parse(row[1].changed)).map((changedRow, changedRow_index) =>
                                    <>
                                      <span>{ changedRow[0] } </span>
                                      { changedRow[1].map((changedRowItem, changedRowItem_index) =>
                                          <span>{ changedRowItem } </span>
                                        )
                                      }
                                    </>
                                  )
                                :
                                  null
                              }
                            </td>
                            <td>{ new Date(row[1].updated_at).getFullYear() + "-" + (new Date(row[1].updated_at).getMonth()+1) + "-" + new Date(row[1].updated_at).getDate() + " " + new Date(row[1].updated_at).getHours() }
                            </td>
                          </tr>
                        :
                          null
                      )
                    :
                      null
                  }
                  </tbody>
                </table>
              </div>
            </>
          :
            null
      
      )
    }
    // } else {
      // return null
    // }
}


