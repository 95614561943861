import React, { Component, useState } from 'react'
import axios from 'axios'

var contentAPI = ''

const localArray = [
  'localhost',
  '192.168.0.149',
  '192.168.1.31'
]

// console.log(sid)

if (localArray.some(v => window.location.hostname.includes(v))) {
  contentAPI = 'https://' + window.location.hostname + ':3001/api'
} else {
  contentAPI = 'https://' + window.location.hostname + '/api'
}

export default function getSupportingDocuments(documentObject) {
    var metadata = documentObject.metadata
    var selectionCollection = ''
    var meta_file_id = ''

    console.log(metadata)

    if (metadata)
      metadata = metadata

    if (metadata.path) {
      selectionCollection = metadata.path.split('/')[0]
    } else {
      selectionCollection = sessionStorage.getItem('selectionCollection')
    }
    if (selectionCollection === 'xuXjMWpV787') {
      selectionCollection = 'xuXjMWpV7v6'
    }
    meta_file_id = metadata.meta_file_id

    const last = meta_file_id.charAt(meta_file_id.length - 1);

    if (last === '-')
      meta_file_id = metadata.meta_file_id

    // console.log(meta_file_id)
    // console.log(selectionCollection)

    var getURL = contentAPI + '/getSupportingDocuments'

    var blobData = new FormData()
    blobData.append('clientFile',   meta_file_id)
    blobData.append('clientFolder', selectionCollection)

    console.log('meta_file_id')
    console.log(meta_file_id)

    console.log('selectionCollection')
    console.log(selectionCollection)

    if (meta_file_id) {
      axios.post(getURL, {
        data: {
          'clientFile':   meta_file_id,
          'clientFolder': selectionCollection
        }
      })
      .then((response) => {
          console.log('response.data.sendBackList') 
          console.log(response.data.sendBackList)
          var sendBackList = response.data.sendBackList
          var report = "report";
          sendBackList = sendBackList.sort(function(item,itemNext) { 
            // console.log(item.documentName.toLowerCase().includes(report));
            return item.documentName.toLowerCase().includes(report) ? -1 : itemNext.documentName.toLowerCase().includes(report) ? 1 : 0; 
          })

          console.log(sendBackList)
          console.log(JSON.stringify(sendBackList))

          console.log('this.state')
          console.log(this.state)
      })
      .catch((err) => {
          // console.log(err)
      })
    }
}