import React, { Component } from 'react'

export default function arrangeArray(documentContent, activeTopId, activeSubId) {
  var buildContent          = ''
  var arraytoBuildWith      = []
  // var documentContent       = documentContent
  var orderOfArray          = []
  var itemKey               = ''
  var itemKeyDisplay        = ''
  var itemValue             = ''
  var availableFormats      = ['bankruptcies', 'mechanics_liens_municipal_claims', 'mortgages', 'real_estate_taxes', 'judgments', 'current_water_and_sewer_rents', 'divorce', 'ucc']
  let taskPopupHTML             = ''

  if (activeTopId === 'ucc') {
    activeTopId = 'UCC'
  }

  if (activeTopId && typeof activeSubId != 'undefined') {
    arraytoBuildWith    = documentContent.data[activeTopId][activeSubId]
  } else if (activeTopId && typeof activeSubId === 'undefined') {
    arraytoBuildWith    = documentContent.data[activeTopId]
  }

  if (activeTopId === 'mortgages') {
    orderOfArray = [
      ['amount',        'Amount'], 
      ['lender',        'Lender'],
      ['borrower',      'Borrower'],
      ['recorded_in',   'Recorded In'], 
      ['recorded_date', 'Recorded Date'],  
      ['date',          'Date'],  
      ['open_end',      'Open Ended?'],  
      ['fulltext',      'Comments']
    ]
    
    orderOfArray.map((list, index) => {
      // console.log(index)
      itemKey         = list[0]
      itemKeyDisplay  = list[1]
      itemValue       = ''
      
      if (arraytoBuildWith)
        itemValue       = arraytoBuildWith[list[0]]

      if (itemValue && itemKey === 'amount' && itemValue.indexOf('$') < 0) {
        itemValue = '$' + itemValue.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      }

      if (arraytoBuildWith && arraytoBuildWith[list[0]]) 
        return (
          buildContent    += '<div class="col-md-5 row ' + itemKey + '"><h3 class="col-md-5">' + itemKeyDisplay + '</h3><strong class="col-md-6">' + itemValue + '</strong>' + taskPopupHTML + '</div>')
    })
  } else if (activeTopId === 'real_estate_taxes') {
    orderOfArray = [
      ['assessment',                      'Assessment'], 
      ['parcel_id',                       'Parcel ID'],
      ['assessment_year',                 'Assessment Year'],
      ['assessed_land',                   'Assessed Land'],
      ['assessed_building',               'Assessed Building'],
      ['assessed_value',                  'Assessed Value'],
      ['annual_real_estate_tax_amount',   'Annual Real Estate Tax Amount'],
      ['actual_due_date',                 'Actual Due Date'],
      ['real_estate_tax_status',          'Real Estate Tax Status'],
      ['real_estate_tax_deliquency',      'Real Estate Tax Deliquency'],
      ['water_stormwater_and_sewer_rents','Water Stormwater and Sewer Rents'],
      ['storm_water_charges',             'Storm Water Charges'],
      ['real_estate_tax_comments',        'Real Estate Tax Comments'],
      ['fulltext',                        'Comments']
    ]

    if (documentContent.data['taxes_and_assessment'])
      arraytoBuildWith    = documentContent.data['taxes_and_assessment']
    
    orderOfArray.map((list, index) => {
      itemKey         = list[0]
      itemKeyDisplay  = list[1]
      itemValue       = ''
      
      if (arraytoBuildWith)
        itemValue       = arraytoBuildWith[list[0]]

      if (itemValue && itemKey === 'assessment' && itemValue.indexOf('$') < 0) {
        itemValue = '$' + itemValue.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      }

      // console.log(list[0])
      if (arraytoBuildWith && arraytoBuildWith[list[0]])
        return (buildContent    += '<div class="col-md-5 row ' + itemKey + '"><h3 class="col-md-5">' + itemKeyDisplay + '</h3><strong class="col-md-6">' + itemValue + '</strong></div>')
    })

  } else if (activeTopId === 'judgments') {
    orderOfArray = [
      ['judgment_type','Judgment Type'],
      ['address',      'Address'],
      ['case_id',      'Case ID'],
      ['case_name',    'Case Name'],
      ['case_type',    'Case Type'],
      ['names_run',    'Names'],
      ['amount',       'Amount'],
      ['case',         'Case'],
      ['defendant',    'Defendant'],
      ['plaintiff',    'Plaintiff'],
      ['recorded_date','Recorded Date'],
      ['recorded_in',  'Recorded In'],
      /*
      ['JUDG',         'Judgment'],
      ['FTL',          'FTL'],
      ['STL',          'STL'],
      ['LIENS',        'LIENS'],
      ['LIS',          'LIS'],
      ['UCC',          'UCC'],
      */
      ['comments',     'Comments']
    ]

    orderOfArray.map((list, index) => {

    })
    
    orderOfArray.map((list, index) => {
      // console.log(arraytoBuildWith[list[0]])

      if (arraytoBuildWith && arraytoBuildWith[list[0]] && list[1] != 'JUDG' && arraytoBuildWith[list[0]] != '$N/A')
        return (buildContent    += '<div class="col-md-5 row ' + list[0] + '"><h3 class="col-md-5">' + list[1] + '</h3><strong class="col-md-6">' + arraytoBuildWith[list[0]] + '</strong></div>')
    })

  } if (activeTopId === 'current_water_and_sewer_rents') {
    orderOfArray = [
      ['water_stormwater_and_sewer_rents',    'Water, Stormwater and Sewer Rents'], 
      ['water_account_numbers',               'Account Numbers'], 
      ['bill_cycle',                          'Bill Cycle'],
      ['fulltext',           'Comments']
    ]
    
    orderOfArray.map((list, index) => {
      itemKey         = list[0]
      itemKeyDisplay  = list[1]
      itemValue       = ''
      
      if (arraytoBuildWith)
        itemValue       = arraytoBuildWith[list[0]]

      if (itemValue && itemKey === 'assessment' && itemValue.indexOf('$') < 0) {
        itemValue = '$' + itemValue.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      } else if (itemValue) {
        // console.log(itemValue)
        // itemValue = itemValue.replace(/\n/g, "<br />")
      }

      // console.log(list[0])
      if (arraytoBuildWith && arraytoBuildWith[list[0]])
        return (buildContent    += '<div class="col-md-5 row ' + itemKey + '"><h3 class="col-md-5">' + list[1] + '</h3><strong class="col-md-6">' + arraytoBuildWith[list[0]] + '</strong></div>')
    })
  } if (activeTopId === 'mechanics_liens_municipal_claims') {
    orderOfArray = [
      ['fulltext',           'Complete Notes']
    ]
    
    orderOfArray.map((list, index) => {
      itemKey         = list[0]
      itemKeyDisplay  = list[1]
      itemValue       = ''
      
      if (arraytoBuildWith) {
        itemValue       = arraytoBuildWith[list[0]]
        itemValue       = itemValue.replaceAll('Amount:', '<br /> Amount:')
        // console.log(itemValue)
      }

      if (itemValue && itemKey === 'assessment') {
        itemValue = '$' + itemValue.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      } else if (itemValue) {
        // console.log(itemValue)
        // itemValue = itemValue.replace(/\n/g, "<br />")
      }

      if (arraytoBuildWith && arraytoBuildWith[list[0]])
        return (buildContent    += '<div class="col-md-5 row ' + itemKey + '"><h3 class="col-md-5">' + list[1] + '</h3><strong class="col-md-6">' + itemValue + '</strong></div>')
    })
  } if (activeTopId === 'bankruptcies') {
    orderOfArray = [
      ['name_run',           'Name'],
      ['results',            'Results']
    ]
    
    orderOfArray.map((list, index) => {
      itemKey         = list[0]
      itemKeyDisplay  = list[1]
      itemValue       = ''
      
      if (arraytoBuildWith) {
        itemValue       = arraytoBuildWith[list[0]]
        itemValue       = itemValue.replaceAll('Amount:', '<br /> Amount:')
        // console.log(itemValue)
      }

      if (itemValue && itemKey === 'assessment') {
        itemValue = '$' + itemValue.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      } else if (itemValue) {
        // console.log(itemValue)
        // itemValue = itemValue.replace(/\n/g, "<br />")
      }

      if (arraytoBuildWith && arraytoBuildWith[list[0]])
        return (buildContent    += '<div class="col-md-5 row ' + itemKey + '"><h3 class="col-md-5">' + list[1] + '</h3><strong class="col-md-6">' + itemValue + '</strong></div>')
    })
  } else if (activeTopId === 'divorce') {
    orderOfArray = [
      ['case',         'Case'],
      ['case_id',      'Case ID'],
      ['case_name',    'Case Name'],
      ['case_type',    'Case Type'],
      ['names_run',    'Names'],
      ['amount',       'Amount'],
      ['case',         'Case'],
      ['defendant',    'Defendant'],
      ['plaintiff',    'Plaintiff'],
      ['recorded_date','Recorded Date'],
      ['recorded_in',  'Recorded In'],
    ]
    
    orderOfArray.map((list, index) => {
      itemKey         = list[0]
      itemKeyDisplay  = list[1]
      itemValue       = ''
      
      if (arraytoBuildWith) {
        itemValue       = arraytoBuildWith[list[0]]
        // itemValue       = itemValue.replaceAll('Amount:', '<br /> Amount:')
        // console.log(itemValue)
      }

      if (itemValue && itemKey === 'assessment') {
        itemValue = '$' + itemValue.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      } else if (itemValue) {
        // console.log(itemValue)
        // itemValue = itemValue.replace(/\n/g, "<br />")
      }

      if (arraytoBuildWith && arraytoBuildWith[list[0]] && arraytoBuildWith[list[0]] != '$N/A')
        return (buildContent    += '<div class="col-md-5 row ' + itemKey + '"><h3 class="col-md-5">' + list[1] + '</h3><strong class="col-md-6">' + itemValue + '</strong></div>')
    })
  } else if (activeTopId === 'UCC') {
    orderOfArray = [
      ['case',         'Case'],
      ['case_id',      'Case ID'],
      ['case_name',    'Case Name'],
      ['case_type',    'Case Type'],
      ['names_run',    'Names'],
      ['amount',       'Amount'],
      ['case',         'Case'],
      ['defendant',    'Defendant'],
      ['plaintiff',    'Plaintiff'],
      ['recorded_date','Recorded Date'],
      ['recorded_in',  'Recorded In'],
    ]

      console.log(arraytoBuildWith)

    arraytoBuildWith = arraytoBuildWith[0]

      console.log(arraytoBuildWith)

    orderOfArray.map((list, index) => {
      itemKey         = list[0]
      itemKeyDisplay  = list[1]
      itemValue       = ''
      
      if (arraytoBuildWith) {
        itemValue       = arraytoBuildWith[list[0]]
        // itemValue       = itemValue.replaceAll('Amount:', '<br /> Amount:')
        // console.log(itemValue)
      }


      if (itemValue && itemKey === 'assessment') {
        itemValue = '$' + itemValue.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      } else if (itemValue) {
        // console.log(itemValue)
        // itemValue = itemValue.replace(/\n/g, "<br />")
      }

      if (arraytoBuildWith && arraytoBuildWith[list[0]] && arraytoBuildWith[list[0]] != '$N/A')
        return (buildContent    += '<div class="col-md-5 row ' + itemKey + '"><h3 class="col-md-5">' + list[1] + '</h3><strong class="col-md-6">' + itemValue + '</strong></div>')
    })
  } else {
    // console.log(arraytoBuildWith)

    if (availableFormats.includes(activeTopId)) {

    } else if (arraytoBuildWith) {
      Object.entries(arraytoBuildWith).map((list, index) => {
        return ( buildContent    +=  `<div class="row">
                  <h3 class="col-md-6">` + list[0] + `</h3>
                  <div class="col-md-6">` + list[1] + `</div>
                </div>`
              )
      })
    } else  {
      buildContent    += ''
    }
  }

  return {__html: buildContent}
}