import React, { Component } from 'react'

import NavBar from './containers/navbar'

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pageContent: '',
      selectedDocument: ''
    }
  }

  componentDidMount() {
  }

  clearSlate(event) {
    event.preventDefault()
  }

  renewToken() {
    // const { renewSession } = this.props.auth

    // renewSession()
  }

  render() {
    return (
      <div>
        <div>
          <header>       
            <div className="col-md-12">
              <a href="/" className="my-0 mr-md-auto font-weight-normal" >
                <img className="logo-nav" alt="logo-nav" src="/img/logo20.png"/>
              </a>

              <a name="top"></a>

              <NavBar/>
            </div>



            <div className="col-md-12 search-area">
            </div>

            <div className="latest-update-button">
              {/*
              <a href="/latestupdates">
                LATEST UPDATES
              </a>
              */}
            </div>

          </header>

          { this.props.children }

        </div>
      </div>
    )
  }
}

export default App;