import { Link } from 'react-router-dom'
import React, { Component } from 'react'
import axios from 'axios'

import InputBuilder from './InputBuilder'

var contentAPI = ''
var sid = {
  "role" : 'role',
  "subscriptions" : 'subscriptionsJSON',
  "expiresAt" : 'expiresAt',
  "idToken" : 'authResult',      
}
sid = JSON.stringify(sid)

const localArray = [
  'localhost',
  '192.168.0.149',
  '192.168.1.31'
]

// console.log(sid)

if (localArray.some(v => window.location.hostname.includes(v))) {
  contentAPI = 'https://' + window.location.hostname + ':3001/api'
} else {
  contentAPI = 'https://' + window.location.hostname + '/api'
}

const parserAPI = 'https://ttbsearchextractorappv0.azurewebsites.net'


export default class IntakeFormGrade extends Component{
    // console.log('props')
    // console.log(props)

    // Array here builds out the forms.
    //

  constructor(props) {
    super(props)

    this.state = {
      metaFields: this.props.metaFields,
      documentObject: this.props.documentObject
    }


  }


    render () {
      let { 
        documentObject
      } = this.state
      let { 

      } = this.props

      // console.log(documentObject)

      const gradingNumbers = [ "", 80, 60, 40, 20, 10 ]
      const gradingLetters = [ "Choose Grade", 'A', 'B', 'C', 'D', 'F' ]
      let gradingScore     = ""
      if (documentObject.grade)
        gradingScore       = documentObject.grade.score
      const gradingClosest = gradingNumbers.reduce((prev, curr) => Math.abs(curr - gradingScore) < Math.abs(prev - gradingScore) ? curr : prev);

      const fileId       = documentObject.metadata.meta_file_id
      const fileStatus   = documentObject.metadata.status
      const fileStatuses = {
        "Choose Status":    "",
        "New Order":        "new-order",
        "Exam" :            "exam",
        "On Deck":          "on-deck",
        "Clearative" :      "clearative",
        "Clear to Close" :  "clear-to-close",
        "QC" :              "qc",
        "On Hold":          "on-hold",
        "Cancelled":        "cancelled"
      }

      const fileOwner  = documentObject.metadata.assignedto
      const fileOwners = {
        "Choose Owner":     "",
        "Exam Group":       "exam@thetitlebox.com",
        "Orders Group":     "email02@email.com",
        "Amber Pfromm":     "amber@titleeq.com",
        "Amy Erazmus":      "purple7@thetitlebox.com",
        "Becky Maddox":     "purple2@thetitlebox.com",
        "Colleen Mullen":   "exam@thetitlebox.com",
        "Heather Spickler": "heather@thetitlebox.com",
        "Katie Gannon":     "blue5@thetitlebox.com",
        "Michael Miller":   "blue3@thetitlebox.com",
        "Sreejit Gopinath": "orders@thetitlebox.com",
      }
      const fileNotifcation  = documentObject.metadata.notifcation
      const fileNotifcations = {
        "Choose Email to Send":     "",
        "Exam":                     "exam",
        "Clear to Close":           "clear-to-close"
      }

      // console.log('documentObject.metadata')
      // console.log(documentObject.metadata)
// 
      // console.log('fileOwner')
      // console.log(fileOwner)
// 
      // console.log('fileNotifcation')
      // console.log(fileNotifcation)

    // if (props) {
      return (
        <>
          <h2 className="col-md-12">File Settings</h2>
          <div className="col-md-12 row">
            <form onSubmit={ this.props.handleFileInformationSubmit }>
              <strong>Grade</strong>

                    <select 
                      className="form-control grade" 
                      type="text" 
                      name="score"
                      section={ 'grade' }
                      parent={ '' }
                      placeholder="Grade" 
                      onChange={ this.props.handleChangeInput }
                      defaultValue={ gradingClosest }
                    >
                      {
                        gradingNumbers.map((option, option_index) =>
                          <option 
                            value={ option }
                            key={ option }
                          >
                            { gradingLetters[option_index] }
                          </option>
                        )
                      }
                    </select>

              <strong>Owner</strong>
              <select 
                className="form-control owner" 
                type="text" 
                name="assignedto"
                section={ 'metadata' }
                parent={ '' }
                onChange={ this.props.handleChangeInput }
                defaultValue={ fileOwner }
              >
                {
                  Object.entries(fileOwners).map((option, option_index) =>
                    <option 
                      value={ option[1] }
                      key={ option[1] }
                    >
                      { option[0] }
                    </option>
                  )
                }
              </select>
          
              <strong>Status</strong>
              <select 
                className="form-control status" 
                type="text" 
                name="status"
                section={ 'metadata' }
                parent={ '' }
                onChange={ this.props.handleChangeInput }
                defaultValue={ fileStatus }
              >
                {
                  Object.entries(fileStatuses).map((option, option_index) =>
                    <option 
                      value={ option[1] }
                      key={ option[1] }
                    >
                      { option[0] }
                    </option>
                  )
                }
              </select>

              <strong>Send Notifcation</strong>

              <select 
                className="form-control notifcation" 
                type="text" 
                name="notifcation"
                section={ 'metadata' }
                parent={ '' }
                onChange={ this.props.handleChangeInput }
                value={ fileNotifcation || "" }
              >
                {
                  Object.entries(fileNotifcations).map((option, option_index) =>

                      <option 
                        value={ option[1] }
                        key={ option[1] }
                      >
                        { option[0] }
                      </option>
                  )
                }
              </select>

              <strong>Change File ID</strong>

              <input
                type="text"
                name="meta_file_id"
                section={ 'metadata' }
                onChange={ this.props.handleChangeInput }
                className={ ' form-control ' }
                value={ fileId }
              />

              <p>Changing the File ID can cause duplicates and instability. <br/>When changing the ID, go back to the Dashboard, <br/>then click back into the document, and choose Intake Form again.</p>


              {/*<button type="submit" className="btn btn-default btn-outline-primary">Save File Information</button>*/}
            </form>
          </div>
        </>
      )
    }
    // } else {
      // return null
    // }
}


