import React, { Component } from 'react'
import axios from 'axios'

var sid = {
  "role" : 'role',
  "subscriptions" : 'subscriptionsJSON',
  "expiresAt" : 'expiresAt',
  "idToken" : 'authResult',      
}

function utf8_for_xml(inputStr) {
  return inputStr.replace(/[^\x20-\xFF\x85\xA0-\uD7FF\uE000-\uFDCF\uFDE0-\uFFFD]/gm, '');
}

export default function getTitleBoxContent(component) {
      var contentAPI = ''

      const localArray = [
        'localhost',
        '192.168.0.149',
        '192.168.1.13'
      ]

      // console.log(sid)

      if (localArray.some(v => window.location.hostname.includes(v))) {
        contentAPI = 'https://' + window.location.hostname + ':3001/api'
      } else {
        contentAPI = 'https://' + window.location.hostname + '/api'
      }

      console.log('state')
      console.log(component.state.metadata.state)
      console.log('county')
      console.log(component.state.metadata.county)

      let finalURL    = contentAPI + '/read-xml-exam'
      let issue       = ''
      let subissue    = ''
      let state       = component.state.metadata.state
      let county      = component.state.metadata.county
      // var id          = ''
      let section     = ''
      // var folders     = ''
      let documentId  = component.state.metadata.documentId

      issue = ''
      subissue = ''

        component.serverRequest = 
          axios
            .post(finalURL, {
              headers: { 'Cache-Control': 'no-cache' },
              responseType: 'arraybuffer',
          data: {
            documentId:       documentId,
            ProblemName:      issue,
            SubcategoryName:  subissue,
            StateName:        state,
            CountyName:       county,
            checkLogin:      'loggedIn',
            sid: sid
          }
            })
            .then((result) => { 

              const parser = new DOMParser()

              var decoded = result.data
              var sectionMatch = []
              var HTMLtextFinal = ''

              // console.log(result)

            if (decoded && decoded.result && decoded.result.recordset && decoded.result.recordset[0]) {
                  var repairXML = decoded.result.recordset[0].Content
                  var repairingNeeded = repairXML.match(/<img (.*)><figcaption/g)
                  // console.log(repairingNeeded)
                  if (repairingNeeded)
                    repairXML = repairXML.replace('><figcaption', '></img><figcaption')
                  var decodedXML = utf8_for_xml(repairXML)
                  decodedXML = parser.parseFromString(decodedXML, 'text/xml');
                  decodedXML = decodedXML.querySelector('documentRoot')
                  var innerHTMLupdated = ''
                  var HTMLtextFinal = ''
                  var momentaryTitle = ''

                  for (var i = 0; i < decodedXML.childNodes.length; i++) {
                    if (decodedXML.childNodes[i].querySelector('content').innerHTML) {
                      // console.log(new XMLSerializer().serializeToString(decodedXML.childNodes[i].querySelector('content')))
                      innerHTMLupdated = '<div id="section' + i + '"></div>' + decodedXML.childNodes[i].querySelector('content').innerHTML
                      momentaryTitle = decodedXML.childNodes[i].querySelector('title').innerHTML

                      // console.log(decodedXML.childNodes[i])

                      sectionMatch.push(momentaryTitle)
                    } else {
                      // console.log(JSON.stringify(decodedXML.childNodes[i].querySelector('content')))
                      // innerHTMLupdated = new XMLSerializer().serializeToString(decodedXML.childNodes[i].querySelector('content'))
                    }

                    // if ($domain.indexOf('thetitlebox') > 0) {
                      innerHTMLupdated = innerHTMLupdated.replace(/tciab.*?i\/(.*?)">/gi, "app.thetitlebox.com/documents/$1\"\>")
                      innerHTMLupdated = innerHTMLupdated.replace(/api\/(.*?)">/gi, "documents/$1\"\>")

                      innerHTMLupdated = innerHTMLupdated.replace(/(\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*)/gi, "mailto:$1")
                      innerHTMLupdated = innerHTMLupdated.replace(/mailto:mailto:/gi, "mailto:")
                      innerHTMLupdated = innerHTMLupdated.replace(/href=("http)/gi, "target='_blank' href=$1")
                      innerHTMLupdated = innerHTMLupdated.replace(/\{county\}/gi, county)
                      innerHTMLupdated = innerHTMLupdated.replace(/%7Bcounty%7D/gi, county)

                      innerHTMLupdated = innerHTMLupdated.replace(/contenteditable="true"/gi, '')
                      
                      HTMLtextFinal += innerHTMLupdated
                    } // forNodes

            if (!sectionInUse)
             var sectionInUse = 0

              var $holdingText = ''

              $holdingText = HTMLtextFinal
              HTMLtextFinal = $holdingText

              component.setState({
                  pageTitleBoxContent:  HTMLtextFinal,
                  pageTitleBoxSections: sectionMatch,
              })
          } else {
            component.setState({
                pageTitleBoxContent: 'Content coming soon!',
                sections: ''
            })
          } // if decoded
    })

}