import React, { Component } from 'react'
import axios from 'axios'

let contentAPI = ''

const localArray = [
  'localhost',
  '192.168.0.149',
  '192.168.1.31'
]

if (localArray.some(v => window.location.hostname.includes(v))) {
  contentAPI = 'https://' + window.location.hostname + ':3001/api'
} else {
  contentAPI = 'https://' + window.location.hostname + '/api'
}

export default function checkUWAL(component) {
      let uploadedDocument = sessionStorage.getItem('uploadedDocument')
      uploadedDocument = JSON.parse(uploadedDocument)
      let propertyAddressReprocessed  = uploadedDocument.data.property_address_reprocessed
      let send_formatted_address      = propertyAddressReprocessed
      const stateMatch                = /(.*) (AL|AK|AS|AZ|AR|CA|CO|CT|DE|DC|FM|FL|GA|GU|HI|ID|IL|IN|IA|KS|KY|LA|ME|MH|MD|MA|MI|MN|MS|MO|MT|NE|NV|NH|NJ|NM|NY|NC|ND|MP|OH|OK|OR|PW|PA|PR|RI|SC|SD|TN|TX|UT|VT|VI|VA|WA|WV|WI|WY)/g
      let zipMatch                    = /([0-9]{5})/g
      let propertyAddress             = 'None'

      if (propertyAddressReprocessed) {
        zipMatch                        = propertyAddressReprocessed.match(zipMatch)
        propertyAddress                 = propertyAddressReprocessed.match(stateMatch)
      }
      let propertyAddressState        = 'None'
      let propertyAddressCounty       = 'None'
      let propertyAddressZip          = 'None'
      let send_arrayed_address        = {}

      // console.log('propertyAddressReprocessed')
      // console.log(propertyAddressReprocessed)

      if (propertyAddress && propertyAddressReprocessed) {
        propertyAddress = propertyAddress[0].replace(' ', '').split(',')

        propertyAddressState = propertyAddress[propertyAddress.length-1]
        propertyAddressState = propertyAddressState.trim()
        send_arrayed_address.state            = {}
        send_arrayed_address.state.short_name = propertyAddressState

        propertyAddressCounty = propertyAddress[propertyAddress.length-2]
        propertyAddressCounty = propertyAddressCounty.trim()
        send_arrayed_address.locality            = {}
        send_arrayed_address.locality.short_name = propertyAddressCounty

      // console.log('zipMatch')
      // console.log(zipMatch)

        send_arrayed_address.postal_code            = {}
        if (zipMatch)
          send_arrayed_address.postal_code.short_name = zipMatch[0]
        
        send_arrayed_address = JSON.stringify(send_arrayed_address)

        // console.log('send_arrayed_address')
        // console.log(send_arrayed_address)
      } else {
        /* Need a non-erroring version here. */
      }

      // console.log(contentAPI + "/lookup")
      
      if (send_formatted_address && send_formatted_address !== null && send_arrayed_address && send_arrayed_address !== null) {
          return axios.get(contentAPI + "/lookup?searchString=" + send_formatted_address + "&searchArray=" + send_arrayed_address, {
              method: 'HEAD',
                  headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                  }
              })
            .then((response) => {
              // console.log('response.data')
              // console.log(response.data)

              // console.log('response.data.status')
              // console.log(response.data.status)

              // REPLACE - with class
              if (response.data.status === 'found') {
                  
              } else if (response.data.status === 'notfound') {

              }
            }).then((json) => {
              // return { options: json };
            }).catch((response) => {
              console.log(response)
            })
        }
      
  }
