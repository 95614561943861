import React, { Component } from 'react'
import Dropzone from 'react-dropzone'
import { Link } from 'react-router-dom'
import axios from 'axios'
import history from '../../history'

// More .jsx and more loaded here
import ReviewLink from './ReviewLink';
import { onDrop } from './UploadFunctions.js';

var contentAPI = ''
var sid = {
  "role" : 'role',
  "subscriptions" : 'subscriptionsJSON',
  "expiresAt" : 'expiresAt',
  "idToken" : 'authResult',      
}
sid = JSON.stringify(sid)

const localArray = [
  'localhost',
  '192.168.0.149',
  '192.168.1.31'
]

// console.log(sid)

if (localArray.some(v => window.location.hostname.includes(v))) {
  contentAPI = 'https://' + window.location.hostname + ':3001/api'
} else {
  contentAPI = 'https://' + window.location.hostname + '/api'
}

const parserAPI = 'https://ttbsearchextractorappv0.azurewebsites.net'


class UploadtoList extends Component {
  constructor(props) {
    super(props);
    
    // console.log('props')
    // console.log(props)

    this.state = {
      filesPDFs: [],
      filteredPDFs: [],
      dropzoneActive: false,
      selectedDocument: '',
      uploadedDocument: '',
      mainMessage: '',
      supportingMessage: '',
      metadata: {
        meta_company: '',
        meta_file_state: '',
        meta_file_id: '',
        meta_file_client_name: 'TitleBox'
      },
      faults: 0,
      typing: false,
      typingTimeout: 0,
      admin: false,
      documentObject: this.props.documentObject,
      additionalSupportingDocuments: []
    }

    this.handleChangeMetadata = this.handleChangeMetadata.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.loadThisDocument = this.loadThisDocument.bind(this)
    this.deleteThisDocument = this.deleteThisDocument.bind(this)
  }

  onDragEnter() {
    this.setState({
      //dropzoneActive: true
    });
  }

  onDragLeave() {
    this.setState({
      //dropzoneActive: false
    });
  }

  onDragEnterPDF() {
    this.setState({
      //dropzoneActivePDF: true
    });
  }

  onDragLeavePDF() {
    this.setState({
      //dropzoneActivePDF: false
    });
  }

  getAllDocuments(ID) {

  }

  // On Drop Multiple
  onMultipleNonSearchDrop(files) {

    console.log(files)

    Object.entries(files).forEach((document) => {
      console.log(document)

      let uploadURL = contentAPI + '/uploadSupportingDocument'
      let selectedDocument = document.name
      let selectionCollection = this.state.documentObject.metadata.folder_id
      let validateMetadata = []
      let selectedWholeDocument = files[document[0]]
      validateMetadata = this.state.documentObject.metadata
      let meta_file_id = validateMetadata.meta_file_id
      let preparse_id  = this.state.documentObject.metadata.file_id

      if (preparse_id && preparse_id.length) {
        selectionCollection = this.state.documentObject.metadata.path
        selectionCollection = selectionCollection.split("/")[0]
        meta_file_id = preparse_id
      }

      var blobData = new FormData()
      blobData.append('documentName', selectedDocument)
      blobData.append('clientFile',   meta_file_id)
      blobData.append('clientFolder', selectionCollection)
      blobData.append('documentWholeFile', selectedWholeDocument)

      // console.log("selectionCollection")
      // console.log(selectionCollection)
      // console.log("meta_file_id")
      // console.log(meta_file_id)

      this.setState({ supportingMessage: ''})
 
      if (meta_file_id) {
        fetch(uploadURL, {
          method: 'POST',
          body: blobData
        })
        .then((result) => { 
            console.log(result)

            const documentName = document[1].name

            let additionalSupportingDocuments = []
            additionalSupportingDocuments = this.state.additionalSupportingDocuments
            additionalSupportingDocuments.push(
              { 
                "documentName":documentName,
                "documentAddress":selectionCollection + "/" + meta_file_id + "/" + documentName,
              }
            )
            // console.log('additionalSupportingDocuments')
            // console.log(additionalSupportingDocuments)

            if (result.status === 200) {
              this.setState({ 
                supportingMessage: 'Document(s) Uploaded. ',
                additionalSupportingDocuments: additionalSupportingDocuments
              })

              setTimeout(() => {
                this.setState({ supportingMessage: ''})
              }, 2500)
            }
        })
        .catch((result) => {

            this.setState({ 
              supportingMessage: 'Document(s) did not Upload, try again. '
            })
            console.log(result)
        })
      }
    })

    /*
      var uploadURL = contentAPI + '/uploadCacheDocument'

      var blobData = new FormData()
      blobData.append('documentName', selectedDocument)
      blobData.append('clientFolder', selectionCollection)

      // console.log('blobData')
      // console.log(blobData)

      fetch(uploadURL, {
        method: 'POST',
        body: blobData
      })
      .then((result) => { 
          console.log(result)
      })
      .catch((result) => {
          console.log(result)
      })
    */
  }

  recheckUploadedFiles = () => {
    var filesPDFs = sessionStorage.getItem('filesPDFs')
    var selectionCollection = sessionStorage.getItem('selectionCollection')

    var getCachedURL = contentAPI + '/getCachedDocuments'

      // console.log(selectionCollection)
      // console.log(this.state.selectionCollection)

    if (selectionCollection) {
      axios.post(getCachedURL, {
        data: {
          'selectionCollection': selectionCollection,
        }
      }).then((response) => {
        // console.log(response)
        sessionStorage.setItem('filesPDFs', JSON.stringify(response.data.result))

        filesPDFs = JSON.parse(filesPDFs)

        return filesPDFs
      }).catch((err) => {
        // console.log(err)

        return err
      })
    }
  }

  componentDidMount() {
    let email            = localStorage.getItem('email')
    let uploadedDocument = sessionStorage.getItem('uploadedDocument')
    let documentObject   = this.state.documentObject
    let temporaryFileId  = localStorage.getItem('fileid')
    if (uploadedDocument)
      uploadedDocument = JSON.parse(uploadedDocument)
    var filesPDFs = sessionStorage.getItem('filesPDFs')
    var selectionCollection = sessionStorage.getItem('selectionCollection')
    // var selectionCollection = 'xuXjMWpV7v6'
    let showUpload = false

    // console.log(window.location.hostname)

    /*
    if (window.location.hostname != 'instant.thetitlebox.com' && window.location.hostname != 'examdemoapp.azurewebsites.net') {
      showUpload = true
    }
    */

    const allow = [
      'exam@thetitlebox.com',
      'purple2@thetitlebox.com',
      'purple7@thetitlebox.com',
      'blue3@thetitlebox.com',
      'blue5@thetitlebox.com',
      'heather@thetitlebox.com',
      'orders@thetitlebox.com',
      'amber@titleeq.com',
      'matt@titleeq.com',
      'jamie@titleeq.com',
      'matt@thetitlebox.com',
      'jamie@thetitlebox.com',
      'whitney@krape.com',
      'wkrape@gmail.com'
    ]

    if (allow.includes(email)) {
      // console.log(email)
      this.setState({
        admin: true
      })

      if (!window.location.hostname.includes('instant'))
        showUpload = true
    }

    this.setState({
      filesPDFsLoading: 'Will load documents in a moment. (If you\'ve waited more than a few seconds, reload the page.)',
      showUpload:       showUpload,
    })

    var getCachedURL = contentAPI + '/getCachedDocuments'
    var uploadURL = contentAPI + '/companycheck'

    axios.post(uploadURL, {
      data: {
        'ClientEmail': email,
      }
    }).then((response) => {
      // console.log(response.data.result.ClientId)
      selectionCollection = response.data.result['ClientId']

      // console.log('here1')

      if (selectionCollection) {
        var getCachedURL = contentAPI + '/getCachedDocuments'

        this.setState({
          filesPDFsLoading: "Loading documents..."
        })

        axios.post(getCachedURL, {
          data: {
            'selectionCollection': selectionCollection,
          }
        }).then((response) => {
          filesPDFs = response.data.result
          filesPDFs = filesPDFs.reverse()

          sessionStorage.setItem('filesPDFs', JSON.stringify(filesPDFs))
          this.setState({
            filesPDFs: filesPDFs,
            filesPDFsLoading: ''
          })
          sessionStorage.setItem('selectionCollection', selectionCollection)

          if (temporaryFileId && temporaryFileId.length) {
            localStorage.setItem('fileid', '')

            if (temporaryFileId && temporaryFileId.length) {
              this.loadThisDocument(temporaryFileId, 'yes')
            }
          }
        }).catch((err) => {
          this.setState({
            filesPDFsLoading: 'Error loading documents.'
          })
          console.log(err)
        })
      }
    }).catch((err) => {
      this.setState({
        filesPDFsLoading: 'Error getting server list.'
      })
      // console.log(err)
    })

    /*
    setTimeout(() => {
      var filesPDFsFromState = this.state.filesPDFs

      console.log('filesPDFsFromState.length')
      console.log(filesPDFsFromState.length)
      console.log('filesPDFs')
      console.log(filesPDFs)

      if (!filesPDFsFromState.length && filesPDFs) {
        console.log('Rechecking storage')

        sessionStorage.setItem('filesPDFs', filesPDFs)
      } else if (!filesPDFsFromState.length && filesPDFs === null) {
        console.log('Rechecking from Mongo')

        axios.post(getCachedURL, {
          data: {
            'selectionCollection': selectionCollection,
          }
        }).then((response) => {
          // console.log(response)
          sessionStorage.setItem('filesPDFs', JSON.stringify(response.data.result))

          filesPDFs = JSON.parse(filesPDFs)

          this.setState({
            filesPDFs: filesPDFs
          })
        }).catch((err) => {
          // console.log(err)
        })
      }
    }, 1000)
    */
  }


  handleChangeMetadata(event) { 
    // console.log(event.target.name)
    var fieldname = event.target.name
    var fieldvalue = event.target.value
    var metadata = this.state.metadata

    console.log(metadata)

    if (fieldname === 'meta_file_id') {
      var currentDocumentList = this.state.filesPDFs

      // console.log('currentDocumentList')
      // console.log(currentDocumentList)
      // console.log(this.state)

      if (currentDocumentList) {
        var filteredPDFs = currentDocumentList.filter( ({ document }) => {

          if (typeof document.metadata.meta_file_id !== 'undefined') {
            // document.metadata.meta_file_id.includes(fieldvalue)
          }
        })

        // console.log('filteredPDFs')
        // console.log(filteredPDFs)

        metadata[fieldname] = fieldvalue

        this.setState({
          metadata
        })

        // console.log('filteredPDFs.length')
        // console.log(filteredPDFs.length)

        if (filteredPDFs.length > 0 && fieldvalue.length > 2) {
          var faults = 1

          // console.log('faults')
          // console.log(faults)

          this.setState({
            faults: faults,
            faultMessage: 'File ID exists, filter the available search before uploading.'
          })
        } else {
          this.setState({
            faults: 0,
            faultMessage: ''
          })
        }
      }
    } else if (fieldname === 'renameFile') {
      const nameOriginal = event.target.attributes.nameoriginal.value
      let documentObject = this.state.documentObject

      console.log(event.target)
      console.log(nameOriginal)
      console.log(fieldvalue)

      // console.log(this.state)
      if (!documentObject.metadata.supportingDocuments)
        documentObject.metadata.supportingDocuments = {}

      documentObject.metadata.supportingDocuments[nameOriginal] = fieldvalue

      console.log(metadata)

      this.setState({
        documentObject: documentObject
      })  
    } else {
      // console.log(this.state)
      metadata[fieldname] = fieldvalue

      this.setState({
        metadata
      })  
    }


    // console.log(this.state)  
  }

  handleSubmit(event) {
    event.preventDefault()

    // validate
    var validateMetadata = []
    validateMetadata = this.state.metadata
    var faults = 0

    // console.log(validateMetadata)

    Object.values(validateMetadata).forEach((value) => {
      if (value && value !== "") {
        // console.log(value)
        // console.log('Fine')
      } else {
        faults++

        // console.log(value)
        // console.log('Not Fine')
      }
    })

    if (faults === 0) {
      validateMetadata = JSON.stringify(validateMetadata)
      sessionStorage.setItem('metadata', validateMetadata)
    } else {
      // console.log('faults')
      // console.log(faults)
    }
  }

  loadThisDocument = (id, redirect) => {
    // console.log('loadThisDocument')
    // console.log(id)

    var filesPDFs = this.state.filesPDFs
    if (filesPDFs)
      var selectedDocument = ''
      if (!redirect) {
        selectedDocument = filesPDFs.find( ({ _id }) => _id === id )
      } else {
        selectedDocument = filesPDFs.find( ({ document }) => document.metadata.meta_file_id === id )
      }
      // console.log(selectedDocument)

      if (selectedDocument) {
        var metadata = selectedDocument.document.metadata

        metadata.exactDocumentId = selectedDocument._id
        selectedDocument = JSON.stringify(selectedDocument.document)
        metadata = JSON.stringify(metadata)

        // console.log(metadata)
        sessionStorage.setItem('metadata', metadata)
        sessionStorage.setItem('uploadedDocument', selectedDocument)

        if (redirect) {
          history.push('/review')
        }
      }
  }

  deleteThisDocument = (event, documentId, path) => {
    event.preventDefault()

    // console.log(path)

    var getDeleteURL = contentAPI + '/deleteCachedDocument'
    var filesPDFs = this.state.filesPDFs
    path = path.split("/")
    path = path[0]

    var removethisone = filesPDFs.filter(document => document._id != documentId)

    /*
    console.log('filesPDFs')
    console.log(removethisone)
    console.log('event')
    console.log('id')
    console.log('path')
    console.log(event)
    // console.log(id)
    console.log(path)
    */  

    if (documentId && path) {
      axios.post(getDeleteURL, {
        data: {
          'documentId': documentId,
          'selectionCollection': path
        }
      }).then((response) => {
        // console.log('response.data.result.deletedCount')
        // console.log(response.data.result.deletedCount)

        if (response.data.result.deletedCount === 1) {
          this.setState({
            filesPDFs: removethisone
          })
        }
      }).catch((err) => {
        console.log(err)

        // return err
      })
    }
  }



  render () {   
    let { 
      filesPDFs,
      filesPDFsLoading,
      dropzoneActive, 
      uploadedDocument,
      mainMessage,
      supportingMessage,
      faults,
      faultMessage,
      newState,
      showUpload,
      searchTerm,
      admin,
      documentObject,
      additionalSupportingDocuments
    } = this.state

    let { 
      filteredPDFs, 
      documentStep,
      supportingDocuments
    } = this.props

    // console.log('filesPDFs')
    // console.log(filesPDFs)

    // console.log(filesPDFs)
    // console.log('this.state')
    // console.log(this.state)

    console.log('documentObject.metadata')
    console.log(documentObject.metadata)

    return (
      documentStep && documentStep !== "all--hidden" && documentObject.metadata.meta_file_id ?
        <div className="examiner__new">
          <div className="editor-layout documentdrop row">
            <Dropzone
              accept=".pdf,.eml,.doc,.docx"
              onDrop={ this.onMultipleNonSearchDrop.bind(this) }
              className="dropZone documents__search col-md-12"
            >
              { dropzoneActive && <div>Drop files...</div> }
              <div>
                <h2>Upload Files</h2>

                <p className="uploader__message main--message">{ mainMessage }</p>

                { supportingDocuments ?
                  supportingDocuments.map(list => (
                    <a 
                      target="_blank"
                      href={ "https://transfertciabstorage.blob.core.windows.net/attachments/" + list.documentAddress }><br/>
                      { list.documentName } 
                    </a>  
                  ))
                : null
                }

                { additionalSupportingDocuments ?
                  additionalSupportingDocuments.map(list => (
                    <a 
                      target="_blank"
                      href={ "https://transfertciabstorage.blob.core.windows.net/attachments/" + list.documentAddress }><br/>
                      { list.documentName } 
                    </a>  
                  ))
                : null
                }

              </div>
            </Dropzone>

            <form className="form-group col-md-12 row" onSubmit={this.handleSubmit}>
              <label className="col-md-12">
                { supportingMessage }

                { supportingDocuments ?
                  supportingDocuments.map(list => (
                    <>
                      <label className="col-md-6 p-0 m-t-2">Rename file:
                        <input 
                          className="form-control col-md-6"
                          name={ "renameFile" }
                          nameOriginal={ list.documentName }
                          value={ documentObject.metadata.supportingDocuments && documentObject.metadata.supportingDocuments[list.documentName] ? documentObject.metadata.supportingDocuments[list.documentName] : list.documentName } 
                          onChange={ this.handleChangeMetadata }
                        />
                      </label>
                      <br/>
                    </>
                  ))
                : null
                }

                (For debugging) File ID:
                <input name="meta_file_id" type="text" className="form-control" value={ documentObject.metadata.meta_file_id } /> 
                <input name="preparseid" type="text" className="form-control" value={ documentObject.metadata.file_id } /> 
              </label>
            </form>
          </div>
        </div>
    :
      <p>Start or Choose a document to start uploading files.</p>
    )
  }
}

export default UploadtoList;


// <div className="editor-layout documentdrop col-md-12 hidden" >
//           <Dropzone
//             accept=".pdf"
//             onDrop={this.onMultipleNonSearchDrop.bind(this)}
//             className="dropZone documents__supporting col-md-12"
//           >
//             { dropzoneActive && <div>Drop files...</div> }
//             <div>
//               <h2>Upload Supporting Title Search Documents</h2>

//               <p className="uploader__message supporting--message">{supportingMessage}</p>

//             </div>
//           </Dropzone>
//         </div>
