import { Link } from 'react-router-dom'
import React, { Component } from 'react'

export default class IntakeFormInputs extends Component {

  constructor(props) {
    super(props)

    this.state = {
      ...props
    }
  }
    
  render () {
    let { 
      field_attr, 
      problem, 
      entry_index, 
      problem_index, 
      field_attr_index, 
      documentObject,
      handleChangeInput
    } = this.state

    // console.log(documentObject.data)
    // console.log(documentObject.data['HoaCondo'])

    return (
      field_attr[1]["type"] === 'dropdown' && 
      field_attr[1]["options"] && 
      problem[1]["attributes"] ?
          <label>{ field_attr[1]["name"] ? field_attr[1]["name"] : field_attr[0] }
            <select
              type={ field_attr[1]["type"] }
              name={ field_attr[0] }
              section={ 
                problem[1]["attributes"]["section"] ? 
                  problem[1]["attributes"]["section"]
                :
                  'data'
              }
              parent={ problem[0] }
              display={ problem[1]["attributes"]["display"] }
              number={ entry_index }
              example={ problem[1]["attributes"]["json_adjustment"] }
              onChange={ handleChangeInput }
              className={ 'form-control ' + field_attr[0] }
              key={ "input" + problem_index + entry_index + field_attr_index }
            >
            { 
              field_attr[1]["options"].split(',').map((option, option_index) =>
                documentObject.data[field_attr[0]] && 
                documentObject.data[field_attr[0]] === option.split('-')[1] ||
                documentObject.data[problem[1]["attributes"]["json_adjustment"]] &&
                documentObject.data[problem[1]["attributes"]["json_adjustment"]][entry_index] &&
                documentObject.data[problem[1]["attributes"]["json_adjustment"]][entry_index][field_attr[0]] && 
                documentObject.data[problem[1]["attributes"]["json_adjustment"]][entry_index][field_attr[0]] === option.split('-')[1] ?
                  <option 
                    key={ option + option_index } 
                    value={ option.split('-')[1] }
                    selected>
                      { option.split('-')[0] }
                  </option>
                :
                  <option 
                    key={ option + option_index } 
                    value={ option.split('-')[1] }>
                      { option.split('-')[0] }
                  </option>

                // <option key={ option + option_index } value={ option.split('-')[1] }>{ option.split('-')[0] }</option>
              ) 
            }
            </select>
          </label>
      : field_attr[1]["type"] === 'radio' && field_attr[1]["options"] ?
          <>{ field_attr[1]["name"] ? field_attr[1]["name"] : field_attr[0] }
          {
            field_attr[1]["options"].split(',').map((option, option_index) =>
              <div className="form-check" key={ option + option_index }> 
                <input
                  type={ field_attr[1]["type"] }
                  name={ field_attr[0] }
                  section={ 
                    problem[1]["attributes"]["section"] ? 
                      problem[1]["attributes"]["section"]
                    :
                      'data'
                  }
                  parent={ problem[0] }
                  display={ problem[1]["attributes"]["display"] }
                  number={ entry_index }
                  example={ problem[1]["attributes"]["json_adjustment"] }
                  value={ 
                    option.split('-')[1]
                  }
                  onChange={ handleChangeInput }
                  id={ field_attr[0] + option_index }
                  className={ 'form-check-input ' + field_attr[0] }
                  key={ "input" + problem_index + entry_index + field_attr_index }
                  
                  checked={ 
                    documentObject.data[field_attr[0]] === false && option.split('-')[1] === "no" ||
                    documentObject.data[problem[1]["attributes"]["json_adjustment"]] &&
                    documentObject.data[problem[1]["attributes"]["json_adjustment"]][entry_index] && 
                    documentObject.data[problem[1]["attributes"]["json_adjustment"]][entry_index][field_attr[0]] === false && option.split('-')[1] === "no" ||
                    documentObject.data[problem[1]["attributes"]["json_adjustment"]] &&
                    documentObject.data[problem[1]["attributes"]["json_adjustment"]][field_attr[0]] === option.split('-')[1] ||
                    documentObject.data[problem[1]["attributes"]["json_adjustment"]] &&
                    documentObject.data[problem[1]["attributes"]["json_adjustment"]][field_attr[0]] === false && option.split('-')[1] === "no" ?
                      'no'
                    : 
                    documentObject.data['HoaCondo'] === true && option.split('-')[1] === "hoa" ||
                    documentObject.data[field_attr[0]] === true && option.split('-')[1] === "yes" ||
                    documentObject.data[problem[1]["attributes"]["json_adjustment"]] &&
                    documentObject.data[problem[1]["attributes"]["json_adjustment"]][entry_index] && 
                    documentObject.data[problem[1]["attributes"]["json_adjustment"]][entry_index][field_attr[0]] === true && option.split('-')[1] === "yes" ||
                    documentObject.data[problem[1]["attributes"]["json_adjustment"]] &&
                    documentObject.data[problem[1]["attributes"]["json_adjustment"]][field_attr[0]] === option.split('-')[1] ||
                    documentObject.data[problem[1]["attributes"]["json_adjustment"]] &&
                    documentObject.data[problem[1]["attributes"]["json_adjustment"]][field_attr[0]] === true && option.split('-')[1] === "yes" ?
                      'yes'
                    :
                      null
                  }
                />
                <label className="form-check-label" htmlFor={ field_attr[0] + option_index }>
                  { option.split('-')[0] }
                </label>
              </div>
            )
        }</>
      : problem[1]["attributes"] && field_attr[1]["type"] === 'textarea' ?
        <label>{ field_attr[1]["name"] ? field_attr[1]["name"] : field_attr[0] }
          <textarea
            cols="100"
            type={ field_attr[1]["type"] }
            name={ field_attr[0] }
            section={
              problem[1]["attributes"] && problem[1]["attributes"]["section"] ? 
                problem[1]["attributes"]["section"]
              :
                'data'
            }
            parent={ problem[0] }
            display={ problem[1]["attributes"]["display"] }
            number={ entry_index }
            example={ problem[1]["attributes"]["json_adjustment"] }
            value={ 
              documentObject.data[problem[1]["attributes"]["json_adjustment"]] ? 
                documentObject.data[problem[1]["attributes"]["json_adjustment"]][entry_index] ?
                  documentObject.data[problem[1]["attributes"]["json_adjustment"]][entry_index][field_attr[0]]
                :
               documentObject.data[problem[1]["attributes"]["json_adjustment"]][field_attr[0]] 
              :
               documentObject.data[field_attr[0]] 
            }
            onChange={ handleChangeInput }
            className={ 'form-control ' + field_attr[0] }
            key={ "input" + problem_index + entry_index + field_attr_index }
          ></textarea>
        </label>
      :
        problem[1]["attributes"] ?
          <label>
            { 
              field_attr[1]["name"] ? 
                field_attr[1]["name"] === "Grantee" && entry_index === 0 || 
                field_attr[1]["name"] === "Grantor" && entry_index === 0 ?
                  "Current " + field_attr[1]["name"]
                :
                  field_attr[1]["name"]
              : 
                field_attr[0] 
            }
            <input
              type={ field_attr[1]["type"] }
              name={ field_attr[0] }
              section={ 
                problem[1]["attributes"]["section"] ? 
                  problem[1]["attributes"]["section"]
                :
                  'data'
              }
              parent={ problem[0] }
              display={ problem[1]["attributes"]["display"] }
              number={ entry_index }
              example={ problem[1]["attributes"]["json_adjustment"] }
              value={ 
                documentObject.data[problem[1]["attributes"]["json_adjustment"]] ? 
                  documentObject.data[problem[1]["attributes"]["json_adjustment"]][entry_index] ?
                    documentObject.data[problem[1]["attributes"]["json_adjustment"]][entry_index][field_attr[0]]
                  :
                 documentObject.data[problem[1]["attributes"]["json_adjustment"]][field_attr[0]] 
                :
                 documentObject.data[field_attr[0]] 
              }
              onChange={ handleChangeInput }
              className={ 'form-control ' + field_attr[0] }
              key={ "input" + problem_index + entry_index + field_attr_index }
            />
          </label>
        :
          null
    )
  }
}