import React, { Component } from 'react'
import Dropzone from 'react-dropzone'
import { Link } from 'react-router-dom'
import axios from 'axios'
import history from '../../history'

// More .jsx and more loaded here
import PagedSearchSortableBox from './PagedSearchSortableBox'
import BuilderBox from './BuilderBox'
import OnPageMenu from '../navbar/OnPageMenu'
import { glossary } from '../upload/termSearch.js';

function convertDate(date) {
  var yyyy = date.getFullYear().toString();
  var mm = (date.getMonth()+1).toString();
  var dd  = date.getDate().toString();

  var mmChars = mm.split('');
  var ddChars = dd.split('');

  return yyyy + '-' + (mmChars[1]?mm:"0"+mmChars[0]) + '-' + (ddChars[1]?dd:"0"+ddChars[0]);
}

var contentAPI = ''
var sid = {
  "role" : 'role',
  "subscriptions" : 'subscriptionsJSON',
  "expiresAt" : 'expiresAt',
  "idToken" : 'authResult',      
}
sid = JSON.stringify(sid)

const localArray = [
  'localhost',
  '192.168.0.149',
  '192.168.1.31'
]

// console.log(sid)

if (localArray.some(v => window.location.hostname.includes(v))) {
  contentAPI = 'https://' + window.location.hostname + ':3001/api'
} else {
  contentAPI = 'https://' + window.location.hostname + '/api'
}

const parserAPI = 'https://ttbsearchextractorappv0.azurewebsites.net'

const loadingBundle = (input) => {
  let selectionCollection = sessionStorage.getItem('selectionCollection')
  let getFilesCachedURL        = contentAPI + '/getCachedDocumentsDashboard'
  let getTasksCachedURL        = contentAPI + '/getCachedDocumentsDashboardTasks'
  let uploadURL                = contentAPI + '/companycheck'

  let dateNow             = new Date()
  let lastWeekByMonday        = new Date(dateNow.setDate(dateNow.getDate() - (dateNow.getDay() + 1) % 7))
  lastWeekByMonday        = convertDate(lastWeekByMonday)

  // console.log('lastWeekByMonday')
  // console.log(lastWeekByMonday)

  dateNow                 = new Date()
  dateNow                 = new Date(dateNow.setDate(dateNow.getDate() + 1))
  dateNow                 = convertDate(dateNow)

  // console.log('Yep, go get all the dashboard pieces')

  selectionCollection = selectionCollection.split(',')

  input.setState({
    allDashboardFilesLoading: "Reloading All File lists...",
    allDashboardTasksLoading: "Reloading All Task lists..."
  })

  axios.post(getFilesCachedURL, {
    data: {
      'selectionCollection' : selectionCollection,
      'dateFrom'            : lastWeekByMonday,
      'dateTo'              : dateNow
    }
  }).then((response) => {
    console.log('allDashboardFiles response')
    console.log(response)

    let allDashboardFiles = response.data.result

    sessionStorage.setItem('allDashboardFiles', JSON.stringify(allDashboardFiles))
    input.setState({
      allDashboardFiles: allDashboardFiles,
      allDashboardFilesLoading: ''
    })
  }).catch((err) => {
    // console.log('err')
    // console.log(err)

    input.setState({
      allDashboardFilesLoading: 'Error loading File lists.'
    })
  })

  axios.post(getTasksCachedURL, {
    data: {
      'selectionCollection' : selectionCollection,
      'dateFrom'            : lastWeekByMonday,
      'dateTo'              : dateNow
    }
  }).then((response) => {
    console.log('allDashboardTasks response')
    console.log(response)

    let allDashboardTasks = response.data.result

    sessionStorage.setItem('allDashboardTasks', JSON.stringify(allDashboardTasks))
    input.setState({
      allDashboardTasks: allDashboardTasks,
      allDashboardTasksLoading: ''
    })
  }).catch((err) => {
    // console.log('err')
    // console.log(err)

    input.setState({
      allDashboardTasksLoading: 'Error loading Task lists.'
    })
  })
}


class Dashboard extends Component {
  constructor(props) {
    super(props)

    // Basic date from state, may not need these
    this.state = {
      allDashboardFiles: [],
      allDashboardTasks: [],
      searchBy: '',
      dateFrom: "06-02-2021",
      dateTo: "08-09-2022"
    }
  }

  componentDidMount() {
    // Flatten the arrays that come out of Mongo, then process from that flattened array
    let email               = localStorage.getItem('email')
    let selectionCollection = sessionStorage.getItem('selectionCollection')
    let allDashboardFiles   = sessionStorage.getItem('allDashboardFiles')
    let allDashboardTasks   = sessionStorage.getItem('allDashboardTasks')


    let getFilesCachedURL        = contentAPI + '/getCachedDocumentsDashboard'
    let getTasksCachedURL        = contentAPI + '/getCachedDocumentsDashboardTasks'
    let uploadURL                = contentAPI + '/companycheck'

    
    axios.post(uploadURL, {
      data: {
        'ClientEmail': email,
      }
    }).then((response) => {
      // console.log(response.data.result.ClientId)
      selectionCollection = response.data.result['ClientId']

      // If 
      if (allDashboardFiles &&
        allDashboardFiles.length
      ) {
        console.log('allDashboardFiles cache load')
        console.log(allDashboardFiles)

        this.setState({
          allDashboardFilesLoading: 'Pulling from cache'
        })

        this.setState({
          allDashboardFiles: JSON.parse(allDashboardFiles)
        })
      }

      if (allDashboardTasks &&
        allDashboardTasks.length
      ) {
        this.setState({
          allDashboardTasksLoading: 'Pulling from cache'
        })

        this.setState({
          allDashboardTasks: JSON.parse(allDashboardTasks)
        })
      }

      // console.log('selectionCollection')
      // console.log(selectionCollection)

      if (selectionCollection) {
        // console.log('selectionCollection is set')

        this.setState({
          allDashboardFilesLoading: "Reloading All File lists...",
          allDashboardTasksLoading: "Reloading All Task lists..."
        })


        // LOADING MESSAGING
        setTimeout(() => {
          if (this.state.allDashboardFilesLoading.length > 1) {
            this.setState({
              allDashboardFilesLoading: "Still Loading File lists..."
            })
          }

          if (this.state.allDashboardTasksLoading.length > 1) {
            this.setState({
              allDashboardTasksLoading: "Still Loading Task lists..."
            })
          }
        }, 5000)

        setTimeout(() => {
          if (this.state.allDashboardFilesLoading.includes('Still') > 1) {
            this.setState({
              allDashboardFilesLoading: "Error Loading File lists..."
            })
          }

          if (this.state.allDashboardTasksLoading.includes('Still') > 1) {
            this.setState({
              allDashboardTasksLoading: "Error Loading Task lists..."
            })
          }
        }, 10000)


        let dateNow             = new Date()
        let lastWeekByMonday    = new Date(dateNow.setDate(dateNow.getDate() - (dateNow.getDay() + 1) % 7))
        lastWeekByMonday        = convertDate(lastWeekByMonday)

        // console.log('lastWeekByMonday')
        // console.log(lastWeekByMonday)

        dateNow                 = new Date()
        dateNow                 = new Date(dateNow.setDate(dateNow.getDate() + 1))
        dateNow                 = convertDate(dateNow)

        // console.log('Yep, go get all the dashboard pieces')

        selectionCollection = selectionCollection.split(',')

        // console.log(lastWeekByMonday)
        // console.log(dateNow)

        axios.post(getFilesCachedURL, {
          data: {
            'selectionCollection' : selectionCollection,
            'dateFrom'            : lastWeekByMonday,
            'dateTo'              : dateNow
          }
        }).then((response) => {
          console.log('allDashboardFiles response')
          console.log(response)

          let allDashboardFiles = response.data.result

          sessionStorage.setItem('allDashboardFiles', JSON.stringify(allDashboardFiles))
          this.setState({
            allDashboardFiles: allDashboardFiles,
            allDashboardFilesLoading: ''
          })
        }).catch((err) => {
          // console.log('err')
          // console.log(err)

          this.setState({
            allDashboardFilesLoading: 'Error loading File lists.'
          })
        })

        axios.post(getTasksCachedURL, {
          data: {
            'selectionCollection' : selectionCollection,
            'dateFrom'            : lastWeekByMonday,
            'dateTo'              : dateNow
          }
        }).then((response) => {
          console.log('allDashboardTasks response')
          console.log(response)

          let allDashboardTasks = response.data.result

          sessionStorage.setItem('allDashboardTasks', JSON.stringify(allDashboardTasks))
          this.setState({
            allDashboardTasks: allDashboardTasks,
            allDashboardTasksLoading: ''
          })
        }).catch((err) => {
          // console.log('err')
          // console.log(err)

          this.setState({
            allDashboardTasksLoading: 'Error loading Task lists.'
          })
        })
      }

    }).catch((err) => {
      this.setState({
        allDashboardFilesLoading: 'Error getting server list.',
        allDashboardTasksLoading: 'Error loading server lists.'
      })
      // console.log(err)
    })

  }

  handleOptionChangeInput = (event) => {
    let name = event.target.name
    let value = event.target.value

    this.setState({
      searchBy: value
    })
  }

  handleOptionReload = (event) => {
    let input = this

    loadingBundle(input)
  }

  render () {
    let { 
      dateTo, 
      dateFrom,
      allDashboardFiles,
      allDashboardFilesLoading,
      allDashboardTasks,
      allDashboardTasksLoading,
      searchBy
    } = this.state

    // console.log('allDashboardFiles')
    // console.log(allDashboardFiles)

    let dateNow             = new Date()
    let lastWeekByMonday    = new Date(dateNow.setDate(dateNow.getDate() - (dateNow.getDay() + 1) % 7))
    lastWeekByMonday        = convertDate(lastWeekByMonday)

    // console.log('lastWeekByMonday')
    // console.log(lastWeekByMonday)

    dateNow         = new Date()
    let lastWeek    = new Date(dateNow.setDate(dateNow.getDate() - 7))
    lastWeek        = convertDate(lastWeek)

    // console.log('lastWeek')
    // console.log(lastWeek)

    dateNow                 = new Date()
    let lastMonthbyStart    = new Date(dateNow.getFullYear(), dateNow.getMonth() - 1, 1)
    lastMonthbyStart        = convertDate(lastMonthbyStart)
    
    // console.log('lastMonthbyStart')
    // console.log(lastMonthbyStart)    

    dateNow           = new Date()
    let last30days    = new Date(dateNow.setDate(dateNow.getDate() - 30))
    last30days        = convertDate(last30days)
    
    // console.log('last30days')
    // console.log(last30days)

    dateNow                 = new Date()
    let yearToDate          = new Date(dateNow.getFullYear(), 0, 1)
    yearToDate              = convertDate(yearToDate)
    
    // console.log('yearToDate')
    // console.log(yearToDate)  

    dateNow   = new Date(dateNow.setDate(dateNow.getDate() + 1))
    dateNow   = convertDate(dateNow)

    // console.log('dateNow')
    // console.log(dateNow)

    let statusesObject = {
      '':                   'Choose',
      "new-order":          "New Order",       
      "exam":               "Exam",      
      "on-deck":            "On Deck",       
      "clearative":         "Clearative",  
      "clear-to-close":     "Clear to Close",
      "qc":                 "QC",       
      "on-hold":            "On Hold",       
      "cancelled":          "Cancelled"
    }

    let assignedObject = {  
      "Choose Owner":     "",
      "Exam Group":       "exam@thetitlebox.com",
      "Orders Group":     "email02@email.com",
      "Amber Pfromm":     "amber@titleeq.com",
      "Amy Erazmus":      "purple7@thetitlebox.com",
      "Becky Maddox":     "purple2@thetitlebox.com",
      "Colleen Mullen":   "exam@thetitlebox.com",
      "Heather Spickler": "heather@thetitlebox.com",
      "Katie Gannon":     "blue5@thetitlebox.com",
      "Michael Miller":   "blue3@thetitlebox.com",
      "Sreejit Gopinath": "orders@thetitlebox.com"     
    }

    let clientObject = {  
      'Choose':                   '',
      "Alpha Abstract Agency LLC":"ppGjymvaX33",
      "August Title":"Cir9TLCjUCj",
      "BlockLand Transfer":"g5iLxTJqhF",
      "Bluegrass Land Title, LLC":"4i79ykgc944r",
      "Brownstone Abstract":"RMujPZY3nR",
      "C. Jackson Nantz, III, P.C.":"7jrgVl6yp09",
      "Cassidy Title, LLC":"odf45hs890g",
      "Capstone Land Transfer LLC":"p2gL54mk7I3",
      "Cardinal Settlements LLC":"81a6GSDCr1",
      "CB Title Group LLC":"dCFx2fL5KW",
      "Divvy Title":"qjk5kQrdhT",
      "Duane C. Romanello, P.A.":"OdQciUTzd6",
      "Epoch Property Transfer, LLC":"ua45t6aa1kn",
      "Express Title Agency LLC":"FxhPGHFn8j",
      "Faxon Settlement Services LLC":"qQ9yaRUYlp9",
      "Florida Real Estate Title & Closings, Inc":"h5y8juKk9la0",
      "Flow Title LLC":"rBiuMlk8mo10",
      "Flynn Title":"h9bJVG4wxBV",
      "Forever Home Title":"KsgR0Quc0v",
      "Gennex Settlement Services":"7S4jZp18LO",
      "Good Deeds & Co":"rKbAyYyAfv2",
      "Houwzer Title":"xuXjMWpV7v2",
      "Innovative Land Transfer, LLC":"DC3XTM7W2s",
      "Law Offices of Michael C. Lima":"ff56Y79kla6",
      "Leap Settlement Services":"rddHM56ya46",
      "LemonBrew Abstract LLC":"0frr5yui8lk",
      "Milestone Title Services, LLC":"2g7hG3kL9k0",
      "My Philly Abstract LLC":"6uk7i0f5rty",
      "NextGen":"8J56yt329l",
      "Nova Abstract":"hK6tGi4aAJ",
      "PHL Associates LLC a/b/a PHL Abstract":"ppGjymvaX33",
      "PM Title LLC":"t6yaA7KC4lay",
      "Prominent Title":"hb2IAx3RHf",
      "RARE Title Services LLC dba RARE Settlement Services LLC":"ppGjymvaX33",
      "Real Property Transfer":"oA7wsWCaKC",
      "Signature Abstract":"glo2MXitX0",
      "SJ Abstract LLC dba Interstate Abstract":"po7eVdoaqzo",
      "South Central Agency, Inc":"X2JfTW8Yjd",
      "Stewart Abstract of Berks County, Inc.":"029JGRF5nL6",
      "TestDocuments":"xuXjMWpV7v4",
      "TitleBox":"xuXjMWpV7v6",
      "Title Union LLC":"jymry67d0k0",
      "TitleIQ LLC dba TitleEQ":"p57g1h70id3",
      "True Value Title":"Iu9GLACYn7",
      "Truist Abstract, LLC":"8iig5gh6l5j",
      "Urban Abstract, Inc":"fo758iohkuo",
      "Wagon Wheel Title and Escrow, LLC":"rt5e3klje53"   
    }

    const search00 = "/dynamicsearch?"

    const searchLastWeek    = "/dynamicsearch?dateFrom=" + lastWeekByMonday + "&dateTo=" + dateNow + ""
    const searchLastMonth   = "/dynamicsearch?dateFrom=" + lastMonthbyStart + "&dateTo=" + dateNow + ""
    const searchLastYear    = "/dynamicsearch?dateFrom=" + yearToDate + "&dateTo=" + dateNow + ""

    const search01 = "/dynamicsearch?dateFrom=" + lastMonthbyStart + "&dateTo=" + dateNow + ""
    const search02 = "/dynamicsearch?dateFrom=" + last30days + "&dateTo=" + dateNow + ""
    const search03 = "/dynamicsearch?dateFrom=2022-07-18&dateTo=2022-07-29"

    return (
      <div className="p-6 examiner__upload">
        <h1>Welcome to the TitleBox LIVESearch&trade;</h1>

        <div className="col-md-12">
          <OnPageMenu handleOptionReload={ this.handleOptionReload.bind(this) } />
        </div>

        <div className="row col-md-12">
          <div className="col-md-4">
          </div>

          <div className="col-md-4">
              <form action="dynamicsearch">
                <div className="row col-md-12 pt-4">
                  <div className="col-md-3 pt-2 p-0">
                    Search By:
                  </div>

                  <div className="col-md-8">
                    <select name="searchBy" className="form-control" onChange={ this.handleOptionChangeInput.bind(this) }>
                      <option value="">Choose</option>
                      <option value="statusfiles">Status</option>
                      <option value="client_name">Client Name</option>
                      <option value="assignedto">Assigned To</option>
                      <option value="meta_file_id">File ID</option>
                    </select>
                  </div>
                </div>

                <div className="row col-md-12 pt-4">
                  <div className="col-md-3 pt-2 p-0">
                    Query:
                  </div>

                  {
                    searchBy ? 
                      <div className="col-md-8">
                        
                          { 
                            searchBy === "statusfiles" ?
                              <select name="searchTerm" className="form-control">
                                {
                                  Object.entries(statusesObject).map((elm, ind) =>
                                    <option value={ elm[0] }>{ elm[1] }</option>
                                  )
                                }
                              </select>
                            :
                              null
                          }
                          { 
                            searchBy === "client_name" ?
                              <select name="searchTerm" className="form-control">
                                {
                                  Object.entries(clientObject).map((elm, ind) =>
                                    <option value={ elm[1] }>{ elm[0] }</option>
                                  )
                                }
                              </select>
                            :
                              null
                          }
                          { 
                            searchBy === "assignedto" ?
                              <select name="searchTerm" className="form-control">
                                {
                                  Object.entries(assignedObject).map((elm, ind) =>
                                    <option value={ elm[1] }>{ elm[0] }</option>
                                  )
                                }
                              </select>
                            :
                              null
                          }
                          { 
                            searchBy === "meta_file_id" ?
                              <input name="searchTerm" className="form-control" />
                            :
                              null
                          }
                      </div>
                    :
                      "Choose a Search Type"
                    }
                </div>

                <div className="row col-md-12 pt-4">
                  <div className="col-md-3 pt-2 p-0">
                    Document Created By Date:
                  </div>

                  <div className="row col-md-8 text-center">
                    <input type="date" name="dateFrom" className="form-control"/>
                      to
                    <input type="date" name="dateTo" className="form-control"/>
                  </div>

                  <div className="row col-md-12 pt-4 p-0">
                    <div className="col-md-9"></div>

                    <button type="submit" className="form-control col-md-3">Search</button>
                  </div>
                </div>
              </form>
          </div>

          <div className="col-md-4">
          </div>
        </div>

        <div className="row col-md-12 pt-4 mt-4">
          <h2 className="row col-md-12">New Files</h2>

          <div className="col-md-4">
            New Files <strong>Created</strong> Today :<br/>
            <Link to={ searchLastWeek + "&searchBy=&searchTerm=&dateField=" } className="mr-1">
              Last Week
            </Link>
             | 
            <Link to={ searchLastMonth + "&searchBy=&searchTerm=&dateField=" } className="mr-1 ml-1">
              Last Month
            </Link>
             | 
            <Link to={ searchLastYear + "&searchBy=&searchTerm=&dateField=" } className="mr-1 ml-1">
              Last Year
            </Link>
            <hr/>

            <Link to={ search00 + "dateFrom=" + dateNow + "&dateTo=" + dateNow + "&searchBy=&searchTerm=&dateField=" }>
              {
                allDashboardFiles['newfiles'] ? 
                  <BuilderBox 
                    filesPDFs={ allDashboardFiles['newfiles'] } 
                    userLevel='admin'
                  />
                :
                  null
              }

              {
                allDashboardFilesLoading ?
                  allDashboardFilesLoading
                :
                  null
              } 
            </Link>
          </div>

          <div className="col-md-4">
            Files Moved to Status <strong>Exam</strong> since { lastWeekByMonday }:<br/>
            <Link to={ searchLastWeek + "&searchBy=statusfiles&searchTerm=exam&dateField=statusDate" } className="mr-1">
              Last Week
            </Link>
             | 
            <Link to={ searchLastMonth + "&searchBy=statusfiles&searchTerm=exam&dateField=statusDate" } className="mr-1 ml-1">
              Last Month
            </Link>
             | 
            <Link to={ searchLastYear + "&searchBy=statusfiles&searchTerm=exam&dateField=statusDate" } className="mr-1 ml-1">
              Last Year
            </Link>
            <hr/>

            <Link to={ search00 + "dateFrom=" + lastWeekByMonday + "&dateTo=" + dateNow + "&searchBy=statusfiles&searchTerm=exam&dateField=statusDate" }>
              {
                allDashboardFiles['movedtoexam'] ? 
                  <BuilderBox 
                    filesPDFs={ allDashboardFiles['movedtoexam'] } 
                    userLevel='admin'
                  />
                :
                  null
              }

              {
                allDashboardFilesLoading ?
                  allDashboardFilesLoading
                :
                  null
              }
            </Link>
          </div>

          <div className="col-md-4">
            Files Moved to <strong>Clear to Close</strong> since { lastWeekByMonday }:<br/>
            <Link to={ searchLastWeek + "&searchBy=statusfiles&searchTerm=clear-to-close&dateField=statusDate" } className="mr-1">
              Last Week
            </Link>
             | 
            <Link to={ searchLastMonth + "&searchBy=statusfiles&searchTerm=clear-to-close&dateField=statusDate" } className="mr-1 ml-1">
              Last Month
            </Link>
             | 
            <Link to={ searchLastYear + "&searchBy=statusfiles&searchTerm=clear-to-close&dateField=statusDate" } className="mr-1 ml-1">
              Last Year
            </Link>
            <hr/>

            <Link to={ search00 + "dateFrom=" + lastWeekByMonday + "&dateTo=" + dateNow + "&searchBy=statusfiles&searchTerm=clear-to-close&dateField=statusDate" }>
              {
                allDashboardFiles['movedtoclear-to-close'] ? 
                  <BuilderBox 
                    filesPDFs={ allDashboardFiles['movedtoclear-to-close'] } 
                    userLevel='admin'
                  />
                :
                  null
              }

              {
                allDashboardFilesLoading ?
                  allDashboardFilesLoading
                :
                  null
              } 
            </Link>
          </div>
        </div>

        <div className="row col-md-12 pt-4 mt-4">
          <h2 className="row col-md-12">Active Pipeline</h2>

          <div className="col-md-4">
            File with Status of <strong>New Order, Exam, On-Deck, Clearative</strong>:<br/>
            <hr/>
            <Link to={ search00 + "&searchBy=new-order,exam,on-deck,clearative&searchTerm=" }>
              {
                allDashboardFiles['statusnew-order,exam,on-deck,clearative'] ? 
                  <BuilderBox 
                    filesPDFs={ allDashboardFiles['statusnew-order,exam,on-deck,clearative'] } 
                    userLevel='admin'
                  />
                :
                  null
              }

              {
                allDashboardFilesLoading ?
                  allDashboardFilesLoading
                :
                  null
              }
            </Link>
          </div>

          <div className="col-md-4">
            File with Status <strong>Exam</strong>:<br/>
            <hr/>
            <Link to={ search00 + "&searchBy=statusfiles&searchTerm=exam" }>
              {
                allDashboardFiles['statusinexam'] ? 
                  <BuilderBox 
                    filesPDFs={ allDashboardFiles['statusinexam'] } 
                    userLevel='admin'
                  />
                :
                  null
              }

              {
                allDashboardFilesLoading ?
                  allDashboardFilesLoading
                :
                  null
              }
            </Link>
          </div>

          <div className="col-md-4">
            File with Status <strong>Clear to Close</strong>:<br/>
            <hr/>
            <Link to={ search00 + "&searchBy=statusfiles&searchTerm=clear-to-close" }>
              {
                allDashboardFiles['statusinclear-to-close'] ? 
                  <BuilderBox 
                    filesPDFs={ allDashboardFiles['statusinclear-to-close'] } 
                    userLevel='admin'
                  />
                :
                  null
              }

              {
                allDashboardFilesLoading ?
                  allDashboardFilesLoading
                :
                  null
              }
            </Link>
          </div>
        </div>

        <div className="row col-md-12 pt-4 mt-4">
          <h2 className="row col-md-12">Task Queues</h2>

          <div className="col-md-3">
            Task by Status:<br/>
            <hr/>
            <Link to={ search00 + "&searchBy=statustasks&searchTerm=" }>
              {
                allDashboardTasks['statustasks'] ? 
                  <BuilderBox 
                    filesPDFs={ allDashboardTasks['statustasks'] } 
                    displayType='tasks'
                    searchTerm='status'
                    userLevel='admin'
                  />
                :
                  null
              }

              {
                allDashboardTasksLoading ?
                  allDashboardTasksLoading
                :
                  null
              }

              {/*
              <PagedSearchSortableBox userLevel="Admin" searchBy="statustasks" searchTerm="open" dateField="" dateFrom={ dateFrom } dateTo={ dateTo } />
              */}
            </Link>
          </div>

          <div className="col-md-3">
            Task by Problem:<br/>
            <hr/>
            <Link to={ search00 + "&searchBy=problemtasks&searchTerm=" }>
              {
                allDashboardTasks['problemtasks'] ? 
                  <BuilderBox 
                    filesPDFs={ allDashboardTasks['problemtasks'] } 
                    displayType='tasks'
                    searchTerm='problem'
                    userLevel='admin'
                  />
                :
                  null
              }

              {
                allDashboardTasksLoading ?
                  allDashboardTasksLoading
                :
                  null
              }

              {/*
              <PagedSearchSortableBox userLevel="Admin" searchBy="problemtasks" searchTerm="mortgages" dateFrom={ dateFrom } dateTo={ dateTo } />
              */}
            </Link>
          </div>

          <div className="col-md-3">
            Task by Priority:
            <hr/>
            <Link to={ search00 + "&searchBy=prioritytasks&searchTerm=" }>
              {
                allDashboardTasks['prioritytasks'] ? 
                  <BuilderBox 
                    filesPDFs={ allDashboardTasks['prioritytasks'] } 
                    displayType='tasks'
                    searchTerm='priority'
                    userLevel='admin'
                  />
                :
                  null
              }

              {
                allDashboardTasksLoading ?
                  allDashboardTasksLoading
                :
                  null
              }

              {/*
              <PagedSearchSortableBox userLevel="Admin" searchBy="prioritytasks" searchTerm="low" dateFrom={ dateFrom } dateTo={ dateTo } />
              */}
            </Link>
          </div>

          <div className="col-md-3">
            Task by Due Date:
            <hr/>
            <Link to={ search00 + "&searchBy=duedatetasks&searchTerm=" }>
              {
                allDashboardTasks['duedatetasks'] ? 
                  <BuilderBox 
                    filesPDFs={ allDashboardTasks['duedatetasks'] } 
                    displayType='tasks'
                    searchTerm='due_date'
                    userLevel='admin'
                  />
                :
                  null
              }

              {
                allDashboardTasksLoading ?
                  allDashboardTasksLoading
                :
                  null
              }

              {/*
              <PagedSearchSortableBox userLevel="Admin" searchBy="duedatetasks" searchTerm="" dateFrom={ dateFrom } dateTo={ dateTo } />
              */}
            </Link>
          </div>
        </div>


        <br/><br/><br/><br/><br/><br/>
        <small>{ process.env.REACT_APP_CLIENT_VERSION }</small>
      </div>
    )
  }
}

export default Dashboard;
