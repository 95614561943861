import React, { Component } from 'react';
import { Redirect } from 'react-router'

// import loading from './loading.svg';

class Callback extends Component {
  render() {
    return (
      <div className="loading">
        Loading...

      </div>
    );
  }
}

export default Callback;

// <Redirect to="/" />
