import React from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import { Redirect } from 'react-router-dom'


let Home = (props) => {
  const { isAuthenticated, loginWithRedirect, isLoading } = useAuth0()

  // console.log('props')
  // console.log(props)
  let email      = localStorage.getItem('email')
  let admin      = false

  const allow = [
    'exam@thetitlebox.com',
    'purple2@thetitlebox.com',
    'purple7@thetitlebox.com',
    'blue3@thetitlebox.com',
    'blue5@thetitlebox.com',
    'heather@thetitlebox.com',
    'orders@thetitlebox.com',
    'amber@titleeq.com',
    'matt@titleeq.com',
    'jamie@titleeq.com',
    'matt@thetitlebox.com',
    'jamie@thetitlebox.com',
    'whitney@krape.com',
    'wkrape@gmail.com'
  ]

  if (allow.includes(email)) {
    console.log("email")
    console.log(email)
    
    admin = true 
  }
  
  if (isLoading) {
    return (
      <div className="p-6 container">
        <h4>
          Loading Options...
        </h4>
      </div>
    )
  }

  return (
    <div className="p-6 container">

      {
        isAuthenticated && (
            admin ?
              <>
                <h4>
                  Thank you for logging in, click here to go to the <a href="/dashboard">dashboard</a>!
                </h4>

                <Redirect to="/dashboard"/>
              </>
            :
              <>
                <h4>
                  Thank you for logging in, click here to go to your <a href="/upload">document list</a>!
                </h4>

                <Redirect to="/upload"/>
              </>
          )
      }
      {
        !isAuthenticated && (
            <>
              <h4>
                Please log in.
              </h4>

              <button onClick={() => loginWithRedirect()}>Log In</button>
            </>
          )
      }
    </div>
  );
}

export default Home;
