import { Link } from 'react-router-dom'
import React, { Component } from 'react'

import { useSortableData } from '../upload/useSortableData.js';
import { loadThisDocument } from '../upload/UploadFunctions.js';
import { glossary } from '../upload/termSearch.js';

// Not really a fan of this... Move and replace
let TaskDigestion = (objectToMod) => {
  let taskTypes = []
  // let bankruptcies = Object.values(objectToMod).length
  console.log('objectToMod')
  console.log(objectToMod)

  if (objectToMod) {
    taskTypes = Object.values(objectToMod.reduce((c, { problem }) => {
      c[problem] = c[problem] || { problem: problem, count: 0 };
      c[problem].count++;

      console.log('c')
      console.log(c)
      return c;
    }, {}));
  }

  console.log('taskTypes')
  console.log(taskTypes)

  return taskTypes
}

let BuilderList = (props) => {
    // console.log('userLevel')
    // console.log(props)

    if (props.filesPDFs) {
      const filesPDFs = props.filesPDFs
      // if taskview do something different 

      // console.log('filesPDFs')
      // console.log(filesPDFs)

      let { items, requestSort, sortConfig } = useSortableData(filesPDFs)
      const getClassNamesFor = (name) => {
        // console.log(name)
        if (!sortConfig) {
          return
        }
        return sortConfig.key === name ? sortConfig.direction : 'off'
      }

      let sortButtons = []
      let columnSpan = 3

      if (props.userLevel === "User") {
        sortButtons = [
          ['data.search_date',      'Search Date'],
          ['data.property_address', 'Address'],
          ['metadata.meta_file_id', 'File ID']
        ]

        columnSpan = 4
      } else if (props.userLevel === "Admin") {
        sortButtons = [
          ['metadata.uploaded_at',  'Created Date'],
          ['data.property_address', 'Address'],
          ['metadata.meta_file_id', 'ID'],
          ['data.client_name',      'Client'],
          ['metadata.assignedto',   'Assigned To'],
          ['tasks.problem.list',    'Tasks']
        ]

        columnSpan = 3
      } else if (props.userLevel === "Rejected") {
        sortButtons = [
          ['metadata.uploaded_at',  'Uploaded Date'],
          ['data.metadata.all_filenames', 'Filename']
        ]

        columnSpan = 5
      }

      let filesPDFsLength = filesPDFs.length

      console.log('items')
      console.log(items)

      return (
        <div className="examiner__review mt-4 row">
          <div className="col-md-12 row">
            {
              sortButtons.map((item, index) => 
                <button
                  type="button"
                  onClick={ () => requestSort(item[0]) }
                  className={'col-md-2 sort__button p-1 ' + getClassNamesFor(item[0])}
                  key={"sortButton" + index}
                >
                <small>  { item[1] } </small><span className="indicator">&#9650;</span>
                </button>
              )
            }
          </div>
          {
            
            // List Item... 
            // items comes from the sortableData... Make another "sortable" this (or put it there for the moment)
            // 
            items.map((list, index) =>
              // If list includes key do another map otherwise go right to link
              // Replace with Review list
              list &&
              <div className="col-md-12 row document__list__item" id={list._id} key={list._id + '__list__item'}>

                { props.userLevel === "Admin" && 
                list ?
                  <Link
                    onClick={() => { loadThisDocument(list._id, filesPDFs) }}
                    key={ list._id }
                    to="/review"
                    className="col-md-12 row">

                    { list.document.metadata.uploaded_at ? 
                      <div className="col-md-2 p-1"><small>{ new Date(list.document.metadata.uploaded_at).getFullYear() + "-" + (new Date(list.document.metadata.uploaded_at).getMonth()+1) + "-" + new Date(list.document.metadata.uploaded_at).getDate()  }</small></div>
                      :
                      <div className="col-md-2 p-1"><small></small></div>
                    }

                    <div className="col-md-2 p-1"><small>{ list.document.data.property_address }</small></div>
                    
                    { list.document.metadata.meta_file_id ? 
                      <div className="col-md-2 p-1"><small>{ list.document.metadata.meta_file_id }</small></div>
                      :
                      <div className="col-md-2 p-1"><small>{ list.name }</small></div>
                    }

                    <div className="col-md-2 p-1"><small>{ list.document.data.client_name }</small></div>

                    <div className="col-md-2 p-1"><small>{ list.document.metadata.assignedto }</small></div>

                    <div className="col-md-12 p-1">
                      { list.document.tasks && 
                        list.document.tasks.length ?
                          <>
                            <h6>{ list.document.tasks.length } Task(s)</h6>
                            <ul className="d-flex flex-wrap col-md-12 row p-0">
                              { list.document.tasks.length ? 
                                list.document.tasks.map((task, task_index) =>
                                  <li className={ task.priority + " task__wrapper card col-md-2 mr-2 p-2 "}>
                                    <>{ glossary('all', task.problem) ? glossary('problemTaskSingularObject', task.problem) : task.problem  } </>
                                    <small>{ glossary('all', task.priority) ? glossary('all', task.priority) : task.priority  } Priority </small> 
                                    <><span class="d-inline badge badge-light badge-pill"><small>Due Date</small> { task.due_date  }</span> </>
                                  </li>
                                )
                                :
                                  null
                              }
                            </ul>
                          </>
                        :
                          null
                      }
                    </div>     

                  </Link>
                : '' }

                <Link className="option__delete"
                    onClick={(event) => { props.deleteThisDocument(event, list._id, list.document.metadata.path, list.document.metadata.folder_id)}}
                    key={list._id + '_delete'}
                    to="/deleteDocument">X</Link>
              </div>
            )
          }

        </div>
      )
    } else {
      return null
    }
  }

export default BuilderList