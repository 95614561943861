import React, { Component } from 'react';

export class LatestUpdates extends Component {
  componentDidMount() {
    /*
    const { renewSession } = this.props.auth;

    if (localStorage.getItem('isLoggedIn') === 'true') {
      renewSession();
    }
    */

    var postContent = 'Latest updates on the TitleBox Examiner'
    // postContent = JSON.stringify(postContent)
    // postContent = postContent.toString()

    this.setState({
       updatesContent: postContent
    })

    document.querySelector('.contentContainer').innerHTML = postContent
  }

  render() {
    // const { isAuthenticated } = this.props.auth;

    return (
      <div className="p-6 container contentContainer">
        <i className="fa fa-spinner fa-pulse"></i> Loading...
      </div>
    )
  }
}

export default LatestUpdates;
