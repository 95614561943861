import React, { Component } from 'react'

let OnPageMenu = (props) => {
  const email = localStorage.getItem('email')
  let showAdmin = false
  let showEdit  = false

  // console.log(props)

  if (
    props.metadata && 
    props.metadata.folder_id && 
    props.metadata.meta_file_id
  ) {
    showEdit = true
  }

  // console.log(showEdit)

  const allow = [
    'exam@thetitlebox.com',
    'purple2@thetitlebox.com',
    'purple7@thetitlebox.com',
    'blue3@thetitlebox.com',
    'blue5@thetitlebox.com',
    'heather@thetitlebox.com',
    'orders@thetitlebox.com',
    'amber@titleeq.com',
    'matt@titleeq.com',
    'jamie@titleeq.com',
    'matt@thetitlebox.com',
    'jamie@thetitlebox.com',
    'whitney@krape.com',
    'wkrape@gmail.com'
  ]

  if (allow.includes(email)) {
    showAdmin = true
  }

  // console.log('showAdmin')
  // console.log(showAdmin)

  return (
    <div className="pt-3 mb-3 row menu__onpagemenu">
      { showAdmin ?
        <>
          <a className="mr-2 btn btn-secondary" href="#" onClick={ props.handleOptionReload.bind(this) }>
            <span className="reload">&#x21bb;</span>
          </a>          

          <a className="col-md-1 mr-2 btn btn-secondary" href="/dashboard">
            Dashboard
          </a>

          { /*
          <strong>Choose a list to edit (Old)</strong>

          <a className="col-md-1 mr-2 btn btn-secondary" href="/upload">
            Client List
          </a>

          <a className="col-md-1 mr-2 btn btn-secondary" href="/audit">
            Administration List
          </a>

          <a className="col-md-1 mr-2 btn btn-secondary" href="/rejected">
            Rejected List
          </a>
          */ }

          <a className="col-md-1 mr-2 btn btn-secondary" href="/intakeform">
            Intake Form
          </a>


          { showEdit ?
            <a className="col-md-1 btn btn-secondary" href="/intakeform"
              // onClick={ }
            >
              Edit
            </a>
          :
            null
          }
        </>
      : 
        <>
          <a className="col-md-2 mr-2 btn btn-secondary" href="/upload">
            Document List
          </a>
        </>
      }
    </div>
  )
}

export default OnPageMenu