import React, { Component } from 'react'

export default function OrderForm({meta_file_id, path, onSubmitOrderForm, onHandleChangeMetadata}) {
  return (
    <form className="form-group col-md-6 row" onSubmit={onSubmitOrderForm}>
      <h3>Full-Service Title Clearance</h3>

      <p>
        You are ordering Full-Service title clearance. 
        We will take action to clear and satisfy any title clearance related issues and deliver all documents and your Settlement Checklist when it's complete. 
        Visit our <a href="http://thetitlebox.com/portal" target="_blank">Full-Service portal</a> for access to your Full-Service files
      </p>

      <label className="col-md-12">
        File:
        <input name="meta_file_id" type="text" className="form-control" value={meta_file_id} disabled />
        <input name="meta_file_folder" type="hidden" className="form-control" value={path} disabled /> 
      </label>

      <div className="col-md-12"></div>

      <label className="col-md-12">
        Comments:
        <textarea name="meta_file_form_body" type="text" className="form-control" onChange={onHandleChangeMetadata}></textarea>
      </label>

      <input
          className="btn btn-primary col-md-2 float-right form-submit"
          value="Send"
          type="submit"
      />
    </form>
  )
}