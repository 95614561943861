import React, { Component } from 'react'
import Dropzone from 'react-dropzone'
import { Link } from 'react-router-dom'
import axios from 'axios'
import history from '../../history'

// More .jsx and more loaded here
import DynamicSearch from '../upload/DynamicSearch';
import BuilderBox from './BuilderBox';
import { onDrop } from '../upload/UploadFunctions.js';
import { glossary } from '../upload/termSearch.js';

var contentAPI = ''
var sid = {
  "role" : 'role',
  "subscriptions" : 'subscriptionsJSON',
  "expiresAt" : 'expiresAt',
  "idToken" : 'authResult',      
}
sid = JSON.stringify(sid)

const localArray = [
  'localhost',
  '192.168.0.149',
  '192.168.1.31'
]

// console.log(sid)

if (localArray.some(v => window.location.hostname.includes(v))) {
  contentAPI = 'https://' + window.location.hostname + ':3001/api'
} else {
  contentAPI = 'https://' + window.location.hostname + '/api'
}

const parserAPI = 'https://ttbsearchextractorappv0.azurewebsites.net'


class PagedSearchSortableBox extends Component {
  constructor(props) {
    super(props);
    
    // console.log('props')
    // console.log(props.searchBy)

    this.state = {
      filesPDFs: [],
      filteredPDFs: [],
      dropzoneActive: false,
      selectedDocument: '',
      uploadedDocument: '',
      mainMessage: '',
      supportingMessage: '',
      metadata: {
        meta_company: '',
        meta_file_state: '',
        meta_file_id: '',
        meta_file_client_name: 'TitleBox'
      },
      faults: 0,
      typing: false,
      typingTimeout: 0,
      admin: false,
      userLevel: props.userLevel,
      searchTermNice: props.searchTermNice,
      dateFrom: props.dateFrom,
      dateTo: props.dateTo
    }

    this.handleChangeMetadata = this.handleChangeMetadata.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    // this.loadThisDocument = this.loadThisDocument.bind(this)
    this.dynamicSearch = this.dynamicSearch.bind(this)
  }

  componentDidMount() {
    let email            = localStorage.getItem('email')
    let uploadedDocument = sessionStorage.getItem('uploadedDocument')
    let temporaryFileId  = localStorage.getItem('fileid')
    if (uploadedDocument)
      uploadedDocument = JSON.parse(uploadedDocument)
    let filesPDFs = sessionStorage.getItem('filesPDFs')
    let selectionCollection = sessionStorage.getItem('selectionCollection')

    let searchBy            = ""
    let dateTo              = ""
    let dateFrom            = ""
    let dateField           = ""
    let criteriaDate        = ""
    let searchTerm          = ""


    // var selectionCollection = 'xuXjMWpV7v6'
    let showUpload = false

    // console.log(window.location.hostname)

    /*
    if (window.location.hostname != 'instant.thetitlebox.com' && window.location.hostname != 'examdemoapp.azurewebsites.net') {
      showUpload = true
    }
    */

    const allow = [
      'exam@thetitlebox.com',
      'purple2@thetitlebox.com',
      'purple7@thetitlebox.com',
      'blue3@thetitlebox.com',
      'blue5@thetitlebox.com',
      'heather@thetitlebox.com',
      'orders@thetitlebox.com',
      'amber@titleeq.com',
      'matt@titleeq.com',
      'jamie@titleeq.com',
      'matt@thetitlebox.com',
      'jamie@thetitlebox.com',
      'whitney@krape.com',
      'wkrape@gmail.com'
    ]

    if (allow.includes(email)) {
      // console.log(email)
      this.setState({
        admin: true
      })

      if (!window.location.hostname.includes('instant'))
        showUpload = true
    }

    this.setState({
      filesPDFsLoading: 'Loading...',
      showUpload:       showUpload
    })

    var getCachedURL = contentAPI + '/getCachedDocumentsEnhanced'
    var uploadURL = contentAPI + '/companycheck'

    axios.post(uploadURL, {
      data: {
        'ClientEmail': email,
      }
    }).then((response) => {
      // console.log(response.data.result.ClientId)
      selectionCollection = response.data.result['ClientId']

      // console.log('here1')

      if (selectionCollection) {
        var getCachedURL = contentAPI + '/getCachedDocumentsEnhanced'

        this.setState({
          filesPDFsLoading: "Loading documents..."
        })

        if (this.state.userLevel === "Rejected") {
          selectionCollection = 'xuXjMWpV787'
        }


        searchBy            = this.props.searchBy
        dateTo              = this.props.dateTo
        dateFrom            = this.props.dateFrom
        criteriaDate        = this.props.criteriaDate
        searchTerm          = this.props.searchTerm
        dateField           = this.props.dateField

        // console.log('dateField')
        // console.log(dateField)
        // console.log('dateTo')
        // console.log('dateFrom')
        // console.log(dateTo)
        // console.log(dateFrom)

        if (dateTo && dateFrom) {
          searchBy = searchBy + ',date'
        }

        axios.post(getCachedURL, {
          data: {
            'selectionCollection' : selectionCollection,
            'searchBy'            : searchBy,
            'dateFrom'            : dateFrom,
            'dateTo'              : dateTo,
            'criteriaDate'        : criteriaDate,
            'searchTerm'          : searchTerm,
            'dateField'           : dateField
          }
        }).then((response) => {
          // console.log('files box response ' + searchBy)
          // console.log(response)

          filesPDFs = response.data.result
          filesPDFs = filesPDFs.reverse()

          sessionStorage.setItem('filesPDFs', JSON.stringify(filesPDFs))
          this.setState({
            filesPDFs: filesPDFs,
            filesPDFsLoading: ''
          })
          sessionStorage.setItem('selectionCollection', selectionCollection)

          if (temporaryFileId && temporaryFileId.length) {
            localStorage.setItem('fileid', '')

            if (temporaryFileId && temporaryFileId.length) {
              this.loadThisDocument(temporaryFileId, 'yes')
            }
          }
        }).catch((err) => {
          this.setState({
            filesPDFsLoading: 'Error loading documents.'
          })
          console.log(err)
        })
      }
    }).catch((err) => {
      this.setState({
        filesPDFsLoading: 'Error getting server list.'
      })
      // console.log(err)
    })

    /*
    setTimeout(() => {
      var filesPDFsFromState = this.state.filesPDFs

      console.log('filesPDFsFromState.length')
      console.log(filesPDFsFromState.length)
      console.log('filesPDFs')
      console.log(filesPDFs)

      if (!filesPDFsFromState.length && filesPDFs) {
        console.log('Rechecking storage')

        sessionStorage.setItem('filesPDFs', filesPDFs)
      } else if (!filesPDFsFromState.length && filesPDFs === null) {
        console.log('Rechecking from Mongo')

        axios.post(getCachedURL, {
          data: {
            'selectionCollection': selectionCollection,
          }
        }).then((response) => {
          // console.log(response)
          sessionStorage.setItem('filesPDFs', JSON.stringify(response.data.result))

          filesPDFs = JSON.parse(filesPDFs)

          this.setState({
            filesPDFs: filesPDFs
          })
        }).catch((err) => {
          // console.log(err)
        })
      }
    }, 1000)
    */
  }


  handleChangeMetadata(event) { 
    // console.log(event.target.name)
    var fieldname = event.target.name
    var fieldvalue = event.target.value
    var metadata = this.state.metadata

    if (fieldname === 'meta_file_id') {
      var currentDocumentList = this.state.filesPDFs

      // console.log('currentDocumentList')
      // console.log(currentDocumentList)
      // console.log(this.state)

      if (currentDocumentList) {
        var filteredPDFs = currentDocumentList.filter( ({ document }) => {

          if (typeof document.metadata.meta_file_id !== 'undefined') {
            // document.metadata.meta_file_id.includes(fieldvalue)
          }
        })

        // console.log('filteredPDFs')
        // console.log(filteredPDFs)

        metadata[fieldname] = fieldvalue

        this.setState({
          metadata
        })

        // console.log('filteredPDFs.length')
        // console.log(filteredPDFs.length)

        if (filteredPDFs.length > 0 && fieldvalue.length > 2) {
          var faults = 1

          // console.log('faults')
          // console.log(faults)

          this.setState({
            faults: faults,
            faultMessage: 'File ID exists, filter the available search before uploading.'
          })
        } else {
          this.setState({
            faults: 0,
            faultMessage: ''
          })
        }
      }
    } else {
      // console.log(this.state)
      metadata[fieldname] = fieldvalue

      this.setState({
        metadata
      })  
    }


    // console.log(this.state)  
  }

  handleSubmit(event) {
    event.preventDefault()

    // validate
    var validateMetadata = []
    validateMetadata = this.state.metadata
    var faults = 0

    // console.log(validateMetadata)

    Object.values(validateMetadata).forEach((value) => {
      if (value && value !== "") {
        // console.log(value)
        // console.log('Fine')
      } else {
        faults++

        // console.log(value)
        // console.log('Not Fine')
      }
    })

    if (faults === 0) {
      validateMetadata = JSON.stringify(validateMetadata)
      sessionStorage.setItem('metadata', validateMetadata)
    } else {
      // console.log('faults')
      // console.log(faults)
    }
  }

  dynamicSearch = () => {
    let allPDFs = this.state.filesPDFs
    let searchTerm = this.state.searchTerm
    let userLevel = this.state.userLevel
    let filteredPDFs = allPDFs.filter( ({ document }) => {
      let filterByFilename = ''
      if (document.metadata.all_filenames) {
        filterByFilename  = document.metadata.all_filenames[0]
      }
      let filterById          = document.metadata.meta_file_id
      let filterByClient      = document.data.client_name
      let filterByAddress     = document.data.property_address
      let filterByMortgages   = ""

      /*
      if (document.tasks && document.tasks.length) {
        // Map down to just the needed fields and includes from there
        filterByMortgages   = Object.values(document.tasks.mortgages).map((option, option_index) => {
            console.log(option)
            return option.status
          })
      }
      */


      if (filterById === undefined)
        filterById = '100000001'

      console.log(filterByMortgages)

      filterById = filterById.toString()

      // console.log(filterById)
      // console.log(filterById.includes(searchTerm))

      if (userLevel === "User") {
        if (filterById.includes(searchTerm))
          return document
      } else if (userLevel === "Admin") {
        if (filterById.includes(searchTerm) || 
          filterByClient.includes(searchTerm) || 
          filterByAddress.includes(searchTerm) || 
          filterByMortgages.includes(searchTerm))
          return document
      } else if (userLevel === "Rejected") {
        if (filterByFilename.includes(searchTerm))
          return document
      }
    })

    // console.log(filteredPDFs)

    this.setState({
      filteredPDFs: filteredPDFs
    })
  }

  editSearchTerm = (e) => {
    const self = this;

    if (self.state.typingTimeout) {
       clearTimeout(self.state.typingTimeout);
    }

    self.setState({
       searchTerm: e.target.value,
       typing: false,
       typingTimeout: setTimeout(function () {
          self.dynamicSearch()
         }, 800)
    })
  }

  render () {   
    let { 
      filesPDFs,
      filesPDFsLoading,
      filteredPDFs, 
      dropzoneActive, 
      uploadedDocument,
      mainMessage,
      supportingMessage,
      faults,
      faultMessage,
      newState,
      showUpload,
      userLevel,
      searchPrompt,
      searchTerm,
      searchTermNice,
      dateFrom,
      dateTo,
      admin
    } = this.state

    // console.log('filesPDFs')
    // console.log(filesPDFs)

    // console.log(filesPDFs)
    // console.log('this.state')
    // console.log(this.state)

    if (userLevel === 'User') {
      searchPrompt = 'File ID'
    } else if (userLevel === 'Admin') {
      searchPrompt = 'Address, Client, File ID'
    } else if (userLevel === 'Rejected') {
      searchPrompt = 'Filename'
    }

    return (
      <>
        { faults !== 0 ?
          <div>
            <h3>{this.state.faultMessage}</h3>
          </div> 
        : null }

        { filesPDFs && filesPDFs.length ? 
          <div className="uploaded__documents mt-0">
            { filteredPDFs.length < 1 && !searchTerm ?
              <BuilderBox 
                filesPDFs={ filesPDFs } 
                userLevel={ userLevel } 
                searchTermNice={ searchTermNice }
                dateFrom={ dateFrom }
                dateTo={ dateTo }
              />
              :
              <BuilderBox 
                filesPDFs={ filteredPDFs } 
                userLevel={ userLevel } 
                searchTermNice={ searchTermNice }
                dateFrom={ dateFrom }
                dateTo={ dateTo }
              />
            }
          </div>
        : 
          null
        }

        { filesPDFsLoading }
      </>
    )
  }
}

export default PagedSearchSortableBox;
