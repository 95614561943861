import React, { Component } from 'react'

export default function ContentLogic(Content) {
  const documentContent = Content
  var gradingLetter = ''
  var assembledContent = {}

  const gradingNumbers = [ 80, 60, 40, 20, 10 ]
  const gradingLetters = [ 'A', 'B', 'C', 'D', 'F' ]

  // console.log(Content)

  // Digest the Grade into a Letter
  if (documentContent.grade) {
      const rawGradingNumber = documentContent.grade.score
      var closestGrade = ''

      if (gradingNumbers.indexOf(rawGradingNumber) == -1) {
        closestGrade = Math.max.apply(null, gradingNumbers.filter(function(v){return v <= rawGradingNumber}))
        closestGrade = gradingNumbers.indexOf(closestGrade)
      } else {
        closestGrade = gradingNumbers.indexOf(rawGradingNumber)
      }

      gradingLetter = gradingLetters[closestGrade]
      assembledContent.gradingLetter = gradingLetter
      assembledContent.gradingNumber = rawGradingNumber
  }

  if (documentContent.data.judgments) {
    // console.log(documentContent.data.judgments[0])
    // documentContent.data.ucc = false

    if (documentContent.data.judgments.length && !documentContent.data.judgments[0].hasOwnProperty('attached')) {
      let newJudgmentsArray = []

      Object.entries(documentContent.data.judgments).map((listFromJudgments, index) => {
        console.log(listFromJudgments[1])

        // console.log(listFromJudgments[1])

          let numberOfJudgments = 0

          // console.log('listFromJudgments[1]')
          // console.log(listFromJudgments[1])

          if (parseInt(listFromJudgments[1].JUDG) > 0 ||
            parseInt(listFromJudgments[1].FTL) > 0 ||
            parseInt(listFromJudgments[1].STL) > 0 ||
            parseInt(listFromJudgments[1].LIENS) > 0 ||
            parseInt(listFromJudgments[1].LIS) > 0) {

            // console.log('listFromJudgments[1]')
            // console.log(listFromJudgments[1])

            Object.entries(listFromJudgments[1].details).map((detail, index) => {
              // console.log(detail)

              newJudgmentsArray.push(detail[1])
            })

            // if (listFromJudgments[1].details[0])
            //  newJudgmentsArray.push(listFromJudgments[1].details[0])

            // console.log('listFromJudgments[1].UCC')
            // console.log(listFromJudgments[1].UCC)

            // if (parseInt(listFromJudgments[1].UCC) === 1)
              // documentContent.data.ucc = true

            // console.log(documentContent.data.ucc)

          }

          if (
              listFromJudgments[1] &&
              listFromJudgments[1].judgment_type
            ) {

            // console.log('listFromJudgments[1]')
            // console.log(listFromJudgments[1])

            newJudgmentsArray.push(listFromJudgments[1])

            console.log('newJudgmentsArray')
            console.log(newJudgmentsArray)

            // if (listFromJudgments[1].details[0])
            //  newJudgmentsArray.push(listFromJudgments[1].details[0])

            // console.log('listFromJudgments[1].UCC')
            // console.log(listFromJudgments[1].UCC)

            // if (parseInt(listFromJudgments[1].UCC) === 1)
              // documentContent.data.ucc = true

            // console.log(documentContent.data.ucc)

          }

          if (!newJudgmentsArray.length && listFromJudgments[1].JUDG && parseInt(listFromJudgments[1].JUDG) > 0) {
            // console.log(listFromJudgments[1])

            // newJudgmentsArray.push(listFromJudgments[1])
          }  
        
      })

      // console.log(newJudgmentsArray)
      // console.log(documentContent.data.judgments)
      // console.log(documentContent)
      
      documentContent.data.judgments = newJudgmentsArray
      assembledContent.uploadedDocument = documentContent
    }
  } else {
    assembledContent.uploadedDocument = documentContent
  }

  if (documentContent.data.bankruptcies) {
    var newBankruptciesArray = []

    Object.entries(documentContent.data.bankruptcies).map((list, index) => {
      // console.log(list[1].results)

      if (list[1].results.includes('RESULTS FOUND ')
          && !list[1].results.includes('NO')
          && !list[1].results.includes('NOT')) {

          newBankruptciesArray.push(list[1])
      } 
    })

    if (Array.isArray(newBankruptciesArray) && !newBankruptciesArray.length) {
      newBankruptciesArray = false
    }
    // console.log(newBankruptciesArray)
    
    documentContent.data.bankruptcies = newBankruptciesArray
    assembledContent.uploadedDocument = documentContent
  } else {
    documentContent.data.bankruptcies = false
    assembledContent.uploadedDocument = documentContent
  }

  let detectEstateText = ['estate', 'deceased']

  if (documentContent.data.estate === false || 
    documentContent.data.current_owner && 
    !detectEstateText.some(v => documentContent.data.current_owner.toLowerCase().includes(v))) {
    documentContent.data.estate = false
  } else {
    documentContent.data.estate = true
  }

  let detectEntitiesText = ['entities', 'entity', 'llc', 'llp', 'inc', 'gp', 'dp', 'trust', 'l.l.c']

  console.log('documentContent.data.entities')
  console.log(documentContent.data.entities)

  if (documentContent.data.entities === false || 
    documentContent.data.entities !== true &&
    documentContent.data.current_owner && 
    !detectEntitiesText.some(v => documentContent.data.current_owner.toLowerCase().includes(v))) {
    documentContent.data.entities = false
  } else {
    documentContent.data.entities = true
  }

  console.log('documentContent.data.entities')
  console.log(documentContent.data.entities)

  // console.log(documentContent.data.UCC)

  if (!documentContent.data.UCC || !documentContent.data.UCC.length) {
    documentContent.data.ucc = false
  }

  // console.log(documentContent.data.UCC)

  if (!documentContent.data.mechanics_liens_municipal_claims || 
    !documentContent.data.mechanics_liens_municipal_claims.length) {
    documentContent.data.mechanics_liens_municipal_claims = false
  }


  if (!documentContent.data.foreclosure || 
    !documentContent.data.foreclosure[0].foreclosure || 
    !Object.entries(documentContent.data.foreclosure).length) 
  {
    documentContent.data.foreclosure = false
  }

  if (!documentContent.data.divorce || 
    !Object.entries(documentContent.data.divorce).length || 
    documentContent.data.divorce[0] && 
    !documentContent.data.divorce[0].case.length ) 
  {
    documentContent.data.divorce = false
  }

  if (!documentContent.data.HoaCondo || 
    documentContent.data.HoaCondo.HoaCondo === "neither" ) 
  {
    documentContent.data.HoaCondo = false
  }

  // console.log(newJudgmentsArray)
  // console.log(documentContent.data)

  return assembledContent
}