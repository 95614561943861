import React, { Component } from 'react'
import Dropzone from 'react-dropzone'
import { Link } from 'react-router-dom'
import axios from 'axios'
import history from '../../history'

// More .jsx and more loaded here
import DynamicSearch from '../upload/DynamicSearch';
import BuilderList from './BuilderList';
import { onDrop } from '../upload/UploadFunctions.js';
import { glossary } from '../upload/termSearch.js';

var contentAPI = ''
var sid = {
  "role" : 'role',
  "subscriptions" : 'subscriptionsJSON',
  "expiresAt" : 'expiresAt',
  "idToken" : 'authResult',      
}
sid = JSON.stringify(sid)

const localArray = [
  'localhost',
  '192.168.0.149',
  '192.168.1.31'
]

// console.log(sid)

function parseQuery(queryString) {
    var query = {};
    var pairs = (queryString[0] === '?' ? queryString.substr(1) : queryString).split('&');
    for (var i = 0; i < pairs.length; i++) {
        var pair = pairs[i].split('=');
        query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '');
    }
    return query;
}

if (localArray.some(v => window.location.hostname.includes(v))) {
  contentAPI = 'https://' + window.location.hostname + ':3001/api'
} else {
  contentAPI = 'https://' + window.location.hostname + '/api'
}

const parserAPI = 'https://ttbsearchextractorappv0.azurewebsites.net'

let reduceList = (list, field) => {
  // If it doesn't iterate start here

  // console.log('list')
  // console.log(list)
// 
  // console.log('field')
  // console.log(field)

  let fieldKey = ''

  if (field === "statusfiles") {
    fieldKey = 'document.metadata.status'
  } else if (field === "client_name") {
    fieldKey = 'document.data.client_name'
  } else if (field === "assignedto") {
    fieldKey = 'document.metadata.assignedto'
  } else if (field === "statustasks") {
    fieldKey = 'document.tasks.status'
  } else if (field === "problemtasks") {
    fieldKey = 'document.tasks.problem'
  } else if (field === "prioritytasks") {
    fieldKey = 'document.tasks.priority'
  } else if (field === "duedatetasks") {
    fieldKey = 'document.tasks.due_date'
  } else if (field === "status") {
    fieldKey = 'document.metadata.status'
  }

  const groups = list.reduce((groups, item) => {
    // console.log('item')
    // console.log(item)

    if (field.includes("statusfiles")) {
      const group = (groups[item.document.metadata.status] || [])
        group.push(item);
        groups[item.document.metadata.status] = group

        // console.log('groups')
        // console.log(groups)
      return groups
    } else if (field.includes("client_name")) {
      const group = (groups[item.document.data.client_name] || [])
        group.push(item)
        groups[item.document.data.client_name] = group

        // console.log('groups')
        // console.log(groups)
      return groups
    } else if (field.includes("assignedto")) {
      const group = (groups[item.document.metadata.assignedto] || [])
        group.push(item)
        groups[item.document.metadata.assignedto] = group

        // console.log('groups')
        // console.log(groups)
      return groups
    } else if (field.includes("statustasks")) {
      item.document.tasks.map( (grp, ind) => {
        const group = (groups[grp.status] || [])
          group.push(item)
          groups[grp.status] = group

          // console.log('groups')
          // console.log(groups)
      })
      return groups
    } else if (field.includes("problemtasks")) {
      item.document.tasks.map( (grp, ind) => {
        const group = (groups[grp.problem] || [])
          group.push(item)
          groups[grp.problem] = group

          // console.log('groups')
          // console.log(groups)
      })
      return groups
    } else if (field.includes("prioritytasks")) {
      item.document.tasks.map( (grp, ind) => {
        const group = (groups[grp.priority] || [])
          group.push(item)
          groups[grp.priority] = group

          // console.log('groups')
          // console.log(groups)
      })
      return groups
    } else if (field.includes("duedatetasks")) {
      item.document.tasks.map( (grp, ind) => {
        const group = (groups[grp.due_date] || [])
          group.push(item)
          groups[grp.due_date] = group

          // console.log('groups')
          // console.log(groups)
      })
      return groups
    } else if (field === "status") {
      const group = (groups[item.document.status] || [])
        group.push(item)
        groups[item.document.status] = group

        // console.log('groups')
        // console.log(groups)
      return groups
    }
  }, {})

  return groups
}

class PagedSearchSortableList extends Component {
  constructor(props) {
    super(props);
    
    // console.log('props')
    // console.log(props.searchBy)

    const documentAccordians = {
      "sections0"   :  "show",
      "sections1"   :  "hide",
      "sections2"   :  "hide",
      "sections3"   :  "hide",
      "sections4"   :  "hide",
      "sections5"   :  "hide",
      "sections6"   :  "hide",
      "sections7"   :  "hide",
      "sections8"   :  "hide",
      "sections9"   :  "hide",
      "sections10"   :  "hide",
      "sections11"   :  "hide",
      "sections12"   :  "hide",
      "sections13"   :  "hide",
      "sections14"   :  "hide",
      "sections15"   :  "hide",
      "sections16"   :  "hide",
      "sections17"   :  "hide",
      "sections18"   :  "hide",
      "sections19"   :  "hide",
      "sections20"   :  "hide"
    }

    this.state = {
      documentAccordians: documentAccordians,
      filesPDFs: [],
      filteredPDFs: [],
      dropzoneActive: false,
      selectedDocument: '',
      uploadedDocument: '',
      mainMessage: '',
      supportingMessage: '',
      metadata: {
        meta_company: '',
        meta_file_state: '',
        meta_file_id: '',
        meta_file_client_name: 'TitleBox'
      },
      faults: 0,
      typing: false,
      typingTimeout: 0,
      admin: false,
      userLevel: props.userLevel,
      searchTermNice: props.searchTermNice,
      dateFrom: props.dateFrom,
      dateTo: props.dateTo
    }

    this.handleChangeMetadata = this.handleChangeMetadata.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    // this.loadThisDocument = this.loadThisDocument.bind(this)
    this.dynamicSearch = this.dynamicSearch.bind(this)
    this.deleteThisDocument = this.deleteThisDocument.bind(this)
  }

  onDragEnter() {
    this.setState({
      //dropzoneActive: true
    });
  }

  onDragLeave() {
    this.setState({
      //dropzoneActive: false
    });
  }

  onDragEnterPDF() {
    this.setState({
      //dropzoneActivePDF: true
    });
  }

  onDragLeavePDF() {
    this.setState({
      //dropzoneActivePDF: false
    });
  }

  getAllDocuments(ID) {

  }

  // On Drop Multiple
  onMultipleNonSearchDrop(files) {

    Object.entries(files).forEach((document) => {
      var uploadURL = contentAPI + '/uploadSupportingDocument'
      var selectedDocument = document.name
      var selectionCollection = sessionStorage.getItem('selectionCollection')
      var validateMetadata = []
      var selectedWholeDocument = files[document[0]]
      validateMetadata = this.state.metadata
      var meta_file_id = validateMetadata.meta_file_id

      var blobData = new FormData()
      blobData.append('documentName', selectedDocument)
      blobData.append('clientFile',   meta_file_id)
      blobData.append('clientFolder', selectionCollection)
      blobData.append('documentWholeFile', selectedWholeDocument)

      // console.log(selectedWholeDocument)

      // console.log('blobData')
      // console.log(meta_file_id)
      // console.log(selectionCollection)

      this.setState({ supportingMessage: ''})
 
      if (meta_file_id) {
        fetch(uploadURL, {
          method: 'POST',
          body: blobData
        })
        .then((result) => { 
            // console.log(result)

            if (result.status === 200) {
              this.setState({ supportingMessage: 'Supporting Document(s) Uploaded. '})

              setTimeout(() => {
                this.setState({ supportingMessage: ''})
              }, 2500)
            }
        })
        .catch((result) => {
            // console.log(result)
        })
      }
    })

    /*
      var uploadURL = contentAPI + '/uploadCacheDocument'

      var blobData = new FormData()
      blobData.append('documentName', selectedDocument)
      blobData.append('clientFolder', selectionCollection)

      // console.log('blobData')
      // console.log(blobData)

      fetch(uploadURL, {
        method: 'POST',
        body: blobData
      })
      .then((result) => { 
          console.log(result)
      })
      .catch((result) => {
          console.log(result)
      })
    */
  }

  recheckUploadedFiles = () => {
    var filesPDFs = sessionStorage.getItem('filesPDFs')
    var selectionCollection = sessionStorage.getItem('selectionCollection')

    var getCachedURL = contentAPI + '/getCachedDocumentsEnhanced'

      // console.log(selectionCollection)
      // console.log(this.state.selectionCollection)

    if (selectionCollection) {
      axios.post(getCachedURL, {
        data: {
          'selectionCollection': selectionCollection,
        }
      }).then((response) => {
        // console.log(response)
        sessionStorage.setItem('filesPDFs', JSON.stringify(response.data.result))

        filesPDFs = JSON.parse(filesPDFs)

        return filesPDFs
      }).catch((err) => {
        // console.log(err)

        return err
      })
    }
  }

  deleteThisDocument = (event, documentId, path, folder_id) => {
    event.preventDefault()

    // console.log(path)

    let getDeleteURL = contentAPI + '/deleteCachedDocument'
    let filesPDFs = this.state.filesPDFs

    if (!Array.isArray(filesPDFs)) {
      filesPDFs = Object.values(filesPDFs)
      filesPDFs = filesPDFs.flat()
    }

    path = path.split("/")
    path = path[0]

    path = folder_id

    // console.log('filesPDFs')
    // console.log(filesPDFs)
    // console.log('documentId')
    // console.log(documentId)

    let removethisone = filesPDFs.filter(document => document._id !== documentId)



    /*    
    console.log('filesPDFs')
    console.log(removethisone)
    console.log('event')
    console.log('id')
    console.log('path')
    console.log(event)
    // console.log(id)
    console.log(path)
    */ 

    if (documentId && path) {
      axios.post(getDeleteURL, {
        data: {
          'documentId': documentId,
          'selectionCollection': path
        }
      }).then((response) => {
        // console.log('response.data.result.deletedCount')
        // console.log(response.data.result.deletedCount)
        // console.log('response')
        // console.log(response)
 
        if (response.data.result.deletedCount === 1) {
          this.setState({
            // filesPDFs: removethisone
          })
        }
      }).catch((err) => {
        console.log(err)

        // return err
      })
    }
  }

  componentDidMount() {
    let email               = localStorage.getItem('email')
    let uploadedDocument    = sessionStorage.getItem('uploadedDocument')
    let temporaryFileId     = localStorage.getItem('fileid')
    if (uploadedDocument)
      uploadedDocument      = JSON.parse(uploadedDocument)
    let filesPDFs           = sessionStorage.getItem('filesPDFs')
    let selectionCollection = sessionStorage.getItem('selectionCollection')
    let enhancedCache       = sessionStorage.getItem('enhancedCache')

    if (!enhancedCache) {
      enhancedCache = JSON.stringify({})
    }

    let queryParams = window.location.search
    queryParams     = parseQuery(queryParams)

    let searchBy            = ""
        searchBy            = queryParams.searchBy

    let dateTo              = ""
    let dateFrom            = ""
    let dateField           = ""
    let criteriaDate        = ""
    let searchTerm          = ""


    // var selectionCollection = 'xuXjMWpV7v6'
    let showUpload = false

    // console.log(window.location.hostname)

    /*
    if (window.location.hostname != 'instant.thetitlebox.com' && window.location.hostname != 'examdemoapp.azurewebsites.net') {
      showUpload = true
    }
    */

    const allow = [
      'exam@thetitlebox.com',
      'purple2@thetitlebox.com',
      'purple7@thetitlebox.com',
      'blue3@thetitlebox.com',
      'blue5@thetitlebox.com',
      'heather@thetitlebox.com',
      'orders@thetitlebox.com',
      'amber@titleeq.com',
      'matt@titleeq.com',
      'jamie@titleeq.com',
      'matt@thetitlebox.com',
      'jamie@thetitlebox.com',
      'whitney@krape.com',
      'wkrape@gmail.com'
    ]

    if (allow.includes(email)) {
      // console.log(email)
      this.setState({
        admin: true
      })

      if (!window.location.hostname.includes('instant'))
        showUpload = true
    }

    // console.log('filesPDFs')
    // console.log(filesPDFs)

    if (filesPDFs && 
      filesPDFs !== 'undefined'
    ) {
      filesPDFs = JSON.parse(filesPDFs)
    }

    this.setState({
      filesPDFsLoading:             'Will load documents in a moment. (If you\'ve waited more than a few seconds, reload the page.)',
      showUpload:                   showUpload
    })

    // console.log('enhancedCache ' + searchBy)
    // console.log(JSON.parse(JSON.parse(enhancedCache)[searchBy]))
    // console.log(filesPDFs)

    if (enhancedCache &&
       JSON.parse(enhancedCache)[searchBy]
    ) {
      this.setState({
        filesPDFs:      JSON.parse(JSON.parse(enhancedCache)[searchBy])
      })
    } else {
      // this.setState({
      //   filesPDFs:      filesPDFs
      // })
    }

    // console.log('this.state.documentAccordians.length')
    // console.log(this.state.documentAccordians.length)

    var getCachedURL = contentAPI + '/getCachedDocumentsEnhanced'
    var uploadURL = contentAPI + '/companycheck'

    axios.post(uploadURL, {
      data: {
        'ClientEmail': email,
      }
    }).then((response) => {
      // console.log(response.data.result.ClientId)
      selectionCollection = response.data.result['ClientId']

      // this.setState({ supportingMessage: 'Loading...'})

      // console.log('here1')

      if (selectionCollection) {
        var getCachedURL = contentAPI + '/getCachedDocumentsEnhanced'

        this.setState({
          filesPDFsLoading: "Reloading Files..."
        })

        setTimeout(() => {
          if (this.state.filesPDFsLoading.length > 1) {
            this.setState({
              filesPDFsLoading: "Still Loading Files..."
            })
          }
        }, 5000)

        setTimeout(() => {
          if (this.state.filesPDFsLoading.includes('Still') > 1) {
            this.setState({
              filesPDFsLoading: "Error Loading Files..."
            })
          }
        }, 10000)

        if (this.state.userLevel === "Rejected") {
          selectionCollection = 'xuXjMWpV787'
        }


        searchBy            = queryParams.searchBy
        dateTo              = queryParams.dateTo
        dateFrom            = queryParams.dateFrom
        criteriaDate        = this.props.criteriaDate
        searchTerm          = this.props.searchTerm
        dateField           = queryParams.dateField

        this.setState({
          searchBy: searchBy,
          searchTerm: searchTerm,
          dateField: dateField
        })

        // console.log('dateField')
        // console.log(dateField)
        // console.log('dateTo')
        // console.log('dateFrom')
        // console.log(dateTo)
        // console.log(dateFrom)

        if (dateTo && dateFrom) {
          searchBy = searchBy + ',date'
        }

        axios.post(getCachedURL, {
          data: {
            'selectionCollection' : selectionCollection,
            'searchBy'            : searchBy,
            'dateFrom'            : dateFrom,
            'dateTo'              : dateTo,
            'criteriaDate'        : criteriaDate,
            'searchTerm'          : searchTerm,
            'dateField'           : dateField
          }
        }).then((response) => {
          // console.log('response')
          // console.log(response)

          // console.log('searchBy')
          // console.log(searchBy)

          filesPDFs = response.data.result
          // filesPDFs = filesPDFs.reverse()
          if (
            searchBy.includes('statusfiles')    ||
            searchBy.includes('client_name')    ||
            searchBy.includes('assignedto')     ||
            searchBy.includes('statustasks')    ||
            searchBy.includes('problemtasks')   ||
            searchBy.includes('prioritytasks')  ||
            searchBy.includes('duedatetasks')
          ) {
            filesPDFs = reduceList(filesPDFs, searchBy)
          } else {
            // Array reorienter.
            // filesPDFs = { 'undefined': filesPDFs }
            // filesPDFs = [{ 0: { 'undefined': filesPDFs }}]
            // filesPDFs = [{ 0: { 'undefined': filesPDFs }}]
            filesPDFs = { 'undefined': filesPDFs }
          }

          // console.log('506 filesPDFs')
          // console.log(filesPDFs)

          enhancedCache           = JSON.parse(enhancedCache)

          // console.log('enhancedCache assignment')
          // console.log(enhancedCache)

          enhancedCache[searchBy] = JSON.stringify(filesPDFs)
          enhancedCache           = JSON.stringify(enhancedCache)

          // console.log('enhancedCache assignment')
          // console.log(enhancedCache)

          sessionStorage.setItem('filesPDFs', JSON.stringify(filesPDFs))
          sessionStorage.setItem('enhancedCache', enhancedCache)

          this.setState({
            filesPDFs:         filesPDFs,
            filesPDFsLoading:  '',
            dateTo:            dateTo,
            dateFrom:          dateFrom,
            supportingMessage: 'No files found from ' + dateFrom + ' to ' + dateTo + ' for ' + searchBy + '.'
          })

          sessionStorage.setItem('selectionCollection', selectionCollection)

          if (temporaryFileId && temporaryFileId.length) {
            localStorage.setItem('fileid', '')

            if (temporaryFileId && temporaryFileId.length) {
              this.loadThisDocument(temporaryFileId, 'yes')
            }
          }
        }).catch((err) => {
          this.setState({
            filesPDFsLoading: 'Error loading documents.'
          })
          console.log(err)
        })
      }
    }).catch((err) => {
      this.setState({
        filesPDFsLoading: 'Error getting server list.'
      })
      // console.log(err)
    })

    /*
    setTimeout(() => {
      var filesPDFsFromState = this.state.filesPDFs

      console.log('filesPDFsFromState.length')
      console.log(filesPDFsFromState.length)
      console.log('filesPDFs')
      console.log(filesPDFs)

      if (!filesPDFsFromState.length && filesPDFs) {
        console.log('Rechecking storage')

        sessionStorage.setItem('filesPDFs', filesPDFs)
      } else if (!filesPDFsFromState.length && filesPDFs === null) {
        console.log('Rechecking from Mongo')

        axios.post(getCachedURL, {
          data: {
            'selectionCollection': selectionCollection,
          }
        }).then((response) => {
          // console.log(response)
          sessionStorage.setItem('filesPDFs', JSON.stringify(response.data.result))

          filesPDFs = JSON.parse(filesPDFs)

          this.setState({
            filesPDFs: filesPDFs
          })
        }).catch((err) => {
          // console.log(err)
        })
      }
    }, 1000)
    */
  }


  handleChangeMetadata(event) { 
    // console.log(event.target.name)
    var fieldname = event.target.name
    var fieldvalue = event.target.value
    var metadata = this.state.metadata

    if (fieldname === 'meta_file_id') {
      var currentDocumentList = this.state.filesPDFs

      // console.log('currentDocumentList')
      // console.log(currentDocumentList)
      // console.log(this.state)

      if (currentDocumentList) {
        var filteredPDFs = currentDocumentList.filter( ({ document }) => {

          if (typeof document.metadata.meta_file_id !== 'undefined') {
            // document.metadata.meta_file_id.includes(fieldvalue)
          }
        })

        // console.log('filteredPDFs')
        // console.log(filteredPDFs)

        metadata[fieldname] = fieldvalue

        this.setState({
          metadata
        })

        // console.log('filteredPDFs.length')
        // console.log(filteredPDFs.length)

        if (filteredPDFs.length > 0 && fieldvalue.length > 2) {
          var faults = 1

          // console.log('faults')
          // console.log(faults)

          this.setState({
            faults: faults,
            faultMessage: 'File ID exists, filter the available search before uploading.'
          })
        } else {
          this.setState({
            faults: 0,
            faultMessage: ''
          })
        }
      }
    } else {
      // console.log(this.state)
      metadata[fieldname] = fieldvalue

      this.setState({
        metadata
      })  
    }


    // console.log(this.state)  
  }

  handleSubmit(event) {
    event.preventDefault()

    // validate
    var validateMetadata = []
    validateMetadata = this.state.metadata
    var faults = 0

    // console.log(validateMetadata)

    Object.values(validateMetadata).forEach((value) => {
      if (value && value !== "") {
        // console.log(value)
        // console.log('Fine')
      } else {
        faults++

        // console.log(value)
        // console.log('Not Fine')
      }
    })

    if (faults === 0) {
      validateMetadata = JSON.stringify(validateMetadata)
      sessionStorage.setItem('metadata', validateMetadata)
    } else {
      // console.log('faults')
      // console.log(faults)
    }
  }

  dynamicSearch = () => {
    let allPDFs = this.state.filesPDFs
    let searchTerm = this.state.searchTerm
    let userLevel = this.state.userLevel
    let filteredPDFs = allPDFs.filter( ({ document }) => {
      let filterByFilename = ''
      if (document.metadata.all_filenames) {
        filterByFilename  = document.metadata.all_filenames[0]
      }
      let filterById          = document.metadata.meta_file_id
      let filterByClient      = document.data.client_name
      let filterByAddress     = document.data.property_address
      let filterByMortgages   = ""

      /*
      if (document.tasks && document.tasks.length) {
        // Map down to just the needed fields and includes from there
        filterByMortgages   = Object.values(document.tasks.mortgages).map((option, option_index) => {
            console.log(option)
            return option.status
          })
      }
      */


      if (filterById === undefined)
        filterById = '100000001'

      // console.log(filterByMortgages)

      filterById = filterById.toString()

      // console.log(filterById)
      // console.log(filterById.includes(searchTerm))

      if (userLevel === "User") {
        if (filterById.includes(searchTerm))
          return document
      } else if (userLevel === "Admin") {
        if (filterById.includes(searchTerm) || 
          filterByClient.includes(searchTerm) || 
          filterByAddress.includes(searchTerm) || 
          filterByMortgages.includes(searchTerm))
          return document
      } else if (userLevel === "Rejected") {
        if (filterByFilename.includes(searchTerm))
          return document
      }
    })

    // console.log(filteredPDFs)

    this.setState({
      filteredPDFs: filteredPDFs
    })
  }

  editSearchTerm = (e) => {
    const self = this;

    if (self.state.typingTimeout) {
       clearTimeout(self.state.typingTimeout);
    }

    self.setState({
       searchTerm: e.target.value,
       typing: false,
       typingTimeout: setTimeout(function () {
          self.dynamicSearch()
         }, 800)
    })
  }

  accordianFields = (event) => {
    if (event.target.attributes.name) {
      const clickedField = event.target.attributes.name.value
      let updatedState = this.state.documentAccordians

      // console.log(clickedField)

      if (
        updatedState[clickedField] &&
        updatedState[clickedField] === "show"
      ) {
        updatedState[clickedField] = "hide"
      } else {
        updatedState[clickedField] = "show"
      }

      // console.log(updatedState)

      this.setState({
        documentAccordians: updatedState
      })
    }
  }

  bundleItems = (fieldKey) => {

    let items       = this.state.filesPDFs
    let bundleItems = ''

    // console.log("items")
    // console.log(items)
    // console.log("fieldKey")
    // console.log(fieldKey)

    if (this.state.bundledBy) {
      fieldKey = ''
      bundleItems = this.state.filesPDFsOriginal
    } else {
      bundleItems = Object.entries(items).map((itemRow, index) => {
          // console.log('itemRow')
          // console.log(itemRow)

          let itemsReduced = []

          // This is both kludgy and awesome. While it would be nice if the structure
          // from the query was always well arranged this cleans it up so when it goes out to be used it works well.
          if (itemRow[1]) {
            itemsReduced = itemRow[1].reduce((groups, item) => {
              // console.log('item')
              // console.log(item)

              if (fieldKey === "metadata.uploaded_at") {
                let simplifyDate = new Date(item.document.metadata.uploaded_at).getFullYear() + "-" + (new Date(item.document.metadata.uploaded_at).getMonth()+1) + "-" + new Date(item.document.metadata.uploaded_at).getDate()

                const group = (groups[itemRow[0]] && groups[itemRow[0]][simplifyDate] || [])
                  group.push(item);

                  if (!groups[itemRow[0]])
                    groups[itemRow[0]] = []

                  if (!groups[itemRow[0]][simplifyDate])
                    groups[itemRow[0]][simplifyDate] = []

                  // console.log('group')
                  // console.log(group)

                  // console.log('groups[itemRow[0]][simplifyDate]')
                  // console.log(groups[itemRow[0]][simplifyDate])

                  groups[itemRow[0]][simplifyDate] = group

                  // console.log('groups')
                  // console.log(groups)
                return groups
              } else if (
                fieldKey === "data.client_name"
              ) {

                const group = (groups[item.document.data.client_name] || [])

                  // console.log('group')
                  // console.log(group)

                  group.push(item)

                  if (item.document.data.client_name.length) {
                    groups[item.document.data.client_name] = group
                  }

                  // console.log('groups')
                  // console.log(groups)
                return groups
              } else {
                return ""
              }

            }, {})
          }





          return itemsReduced 
      })
    }

    if (fieldKey === "metadata.uploaded_at") {

    }

    if (fieldKey === "data.client_name" ) {
      // console.log('bundleItems')
      // console.log(bundleItems)
      
      bundleItems = bundleItems[0]
      bundleItems = [ { 'undefined': bundleItems } ]

      // console.log('bundleItems')
      // console.log(bundleItems)
    }
  
    // console.log("bundleItems")
    // console.log(bundleItems)

    
    this.setState({
      filesPDFs: bundleItems,
      filesPDFsOriginal: items,
      bundledBy: fieldKey
    })
    
  }

  render () {   
    let { 
      documentAccordians,
      documentFieldCollections,
      filesPDFs,
      filesPDFsLoading,
      filteredPDFs, 
      dropzoneActive, 
      uploadedDocument,
      mainMessage,
      supportingMessage,
      faults,
      faultMessage,
      newState,
      showUpload,
      userLevel,
      searchPrompt,
      searchBy,
      searchTerm,
      searchTermNice,
      dateFrom,
      dateTo,
      dateField,
      admin,
      bundleItems
    } = this.state

    // console.log('filesPDFs')
    // console.log(filesPDFs)

    // console.log(filesPDFs)
    // console.log('this.state')
    // console.log(this.state)

    if (userLevel === 'User') {
      searchPrompt = 'File ID'
    } else if (userLevel === 'Admin') {
      searchPrompt = 'Address, Client, File ID'
    } else if (userLevel === 'Rejected') {
      searchPrompt = 'Filename'
    }

    let sortButtons = [
      ['metadata.uploaded_at',  'Created Date'],
      // ['data.property_address', 'Address'],
      //['metadata.meta_file_id', 'ID'],
      ['data.client_name',      'Client'],
      // ['data.assignedto',       'Assigned To'],
      // ['tasks.problem.list',    'Tasks']
    ]

    return (
      <>
        { faults !== 0 ?
          <div>
            <h3>{this.state.faultMessage}</h3>
          </div> 
        : null }

        <div className="col-md-12 row">
          Bundle by: 

          <div className="col-md-6 row">
            {
              sortButtons.map((item, index) => 
                <button
                  type="button"
                  onClick={ () => this.bundleItems(item[0]) }
                  className={'col-md-2 btn btn-secondary sort__button p-1 mr-2'}
                  key={"sortButton" + index}
                >
                <small>  { item[1] } </small>
                </button>
              )
            }
          </div>
        </div>

        <div className="col-md-12 row">
          Searched by
            { 
              dateFrom ?
                " Date From: " + dateFrom
              :
                null
            }
            { 
              dateFrom ?
                " - Date To: " + dateTo
              :
                null
            }
            { 
              searchBy ?
                " Type: " + searchBy
              :
                " Type: No Search Type (All possible files)"
            }
            { 
              searchTerm && searchBy !== "client_name" ?
                " with Term: " + searchTerm
              :
                searchBy !== "client_name" ?
                  " with No Search Term"
                :
                  null
            }

            { 
              dateField ?
                " and Recently Moved Status"
              :
                null
            }
        </div>

        { filesPDFs && 
          Object.entries(filesPDFs).length ?
          Object.entries(filesPDFs).map((item, index) => 
            Object.entries(item[1])[0] && 
            Object.entries(item[1])[0][1]['_id'] ?
              <>
                <div role='button' className="card pointer m-2 p-2"
                  onClick={ this.accordianFields.bind(this) }
                  name={ "sections"+index }
                >{ glossary('all', item[0]) ? glossary('all', item[0]) + "" : item[0] } 

                { glossary('all', item[0]) && glossary('all', item[0]).length > 1 || 
                  item[0] && item[0] !== "undefined" && item[0].length > 1 ? " | " : null } 

                { item[1].length } 

                  { searchBy && 
                    searchBy.includes('task') ?
                      <> File(s)</>
                    :
                      <> File(s)</>
                  }

                  <div className={ documentAccordians["sections"+index] + ' accordians card m-2 p-2' }>
                    
                    <BuilderList 
                      filesPDFs={ item[1] } 
                      userLevel={ userLevel } 
                      deleteThisDocument={ this.deleteThisDocument } 
                      searchTermNice={ searchTermNice }
                      dateFrom={ dateFrom }
                      dateTo={ dateTo }
                    />
                    
                  </div>
                </div>
              </>
            :
              Object.entries(item[1]).map((eachItem, index) => 
                <div className="card pointer m-2 p-2"> { glossary('all', eachItem[0]) ? glossary('all', eachItem[0]) : eachItem[0] }

                  { Object.entries(eachItem[1]).map((subItem, index) => 
                    <>
                      <div role='button' className="card pointer m-2 p-2"
                        onClick={ this.accordianFields.bind(this) }
                        name={ "sections"+index }
                      > 
                        { subItem[0] ?
                            subItem[0] + " | "
                          :
                            null }

                        { subItem[1].length } Files

                        <div className={ documentAccordians["sections"+index] + ' accordians card m-2 p-2' }>
                          
                          <BuilderList 
                            filesPDFs={ subItem[1] } 
                            userLevel={ userLevel } 
                            deleteThisDocument={ this.deleteThisDocument } 
                            searchTermNice={ searchTermNice }
                            dateFrom={ dateFrom }
                            dateTo={ dateTo }
                          />
                          
                        </div>
                      </div>
                    </>
                  )}
                </div>
              )
          )
          :
            <div>{ supportingMessage }</div>
        }





        { /* filesPDFs && filesPDFs.length ? 
          <div className="uploaded__documents">
            <div className="row" style={{"paddingLeft": "27px", "display": "none"}}>
              <h2 className="col-md-12 p-0"><a name="examiner__previous">Available Searches</a></h2>

              <input type='text' className="form-control col-md-5" value={this.state.searchTerm || ''} onChange={this.editSearchTerm} placeholder={ 'Filter Searches by ' + searchPrompt } /> 
            </div>

            { filteredPDFs.length < 1 && !searchTerm ?
              <BuilderList 
                filesPDFs={ filesPDFs } 
                userLevel={ userLevel } 
                deleteThisDocument={ this.deleteThisDocument } 
                searchTermNice={ searchTermNice }
                dateFrom={ dateFrom }
                dateTo={ dateTo }
              />
              :
              <BuilderList 
                filesPDFs={ filteredPDFs } 
                userLevel={ userLevel } 
                deleteThisDocument={ this.deleteThisDocument } 
                searchTermNice={ searchTermNice }
                dateFrom={ dateFrom }
                dateTo={ dateTo }
              />
            }
          </div>
        : 
          null
        */ }

        { filesPDFsLoading }
      </>
    )
  }
}

export default PagedSearchSortableList;
