import React, { Component } from 'react'

class Entries extends Component {
    render() {
        return (
            <div>
                {this.props.name}
            </div>
        )
    }
}

class DynamicSearch extends Component {
    render() {
        return (
            <div>
                {this.props.names.map(entry => <Entries entry = {entry}/>)}
            </div>
        )
    }
}

export default DynamicSearch