import React, { Component } from 'react'
import Dropzone from 'react-dropzone'
import { Link, Redirect } from 'react-router-dom'
import axios from 'axios'
import history from '../../history'

// More .jsx and more loaded here
import TaskFormInputs from './TaskFormInputs'
import IntakeFormInputs from './IntakeFormInputs'
import UploadtoList from '../upload/UploadtoList'
import GetSupportingDocuments from '../upload/GetSupportingDocuments'
import IntakeFormMenu from '../navbar/IntakeFormMenu'
import OnPageMenu from '../navbar/OnPageMenu'
import { loadDirectDocument } from '../upload/UploadFunctions.js';
import IntakeHistory from './IntakeHistory'
import IntakeSettlementChecklist from './IntakeSettlementChecklist'

var contentAPI = ''
var sid = {
  "role" : 'role',
  "subscriptions" : 'subscriptionsJSON',
  "expiresAt" : 'expiresAt',
  "idToken" : 'authResult',      
}
sid = JSON.stringify(sid)

const localArray = [
  'localhost',
  '192.168.0.149',
  '192.168.1.31'
]
const hostname = window.location.hostname

// console.log(sid)

if (localArray.some(v => window.location.hostname.includes(v))) {
  contentAPI = 'https://' + window.location.hostname + ':3001/api'
} else {
  contentAPI = 'https://' + window.location.hostname + '/api'
}

const parserAPI = 'https://ttbsearchextractorappv0.azurewebsites.net'


class IntakeForm extends Component {
  constructor(props) {
    super(props)

    const documentObject = {
        "metadata": {
          "meta_file_id": "",
          "path":         "Path to PDFs, Files",
          "uploaded_at":  "Uploaded Date",
          "folder_id":    "Client ID"
        },
        "data": {
          "HoaCondo": false, 
          "UCC": false,
          "bankruptcies": [
            {
              "name_run": "",
              "results":  "",
              "SSN":      ""
            }
          ],
          "current_owner": "",
          "buyer": "", 
          "client_name": "",
          "current_water_and_sewer_rents": [
            {
              "water_account_numbers": "",
              "water_stormwater_and_sewer_rents": "", 
              "bill_cycle": "", 
              "storm_water_charges": "",
              "utilities_type": "",
              "utilities_comments": ""
            }
          ],
          "divorce":  [
            {
              "case":           "",
              "recorded_date":  "",
              "plaintiff":      "",
              "defendant":      ""
            }
          ],
          "entities": false,
          "estate":   false,
          "judgments": [
            {
              "names_run":            "",
              "judgment_type":        false,
              "case_id":              "",
              "defendant":            "",
              "plaintiff":            "",
              "recorded_date":        "",
              "deedbook-liberfolio":  "",
              "amount":               "",
              "JUDG":                 false,
              "FTL":                  false,
              "STL":                  false,
              "LIENS":                false,
              "LIS":                  false,
              "UCC":                  false,
              "fulltext":             "", 
              "attached":             false,
              "comments":             ""
            }
          ],
          "legal_description": "",
          "mortgages": [
            {
              "borrower":                              "",
              "trustee":                               "",
              "lender":                                "",
              "amount":                                "",
              "debtor":                                "",
              "secured_party":                         "",
              "date_on_mortgage":                      "",
              "recorded_date_entered":                 "",
              "maturity_end":                          "",
              "recorded_in":                           "",
              "date":                                  "",
              "recorded_date":                         "",
              "ucc_financing_statement":               "",
              "assignment_of_mortgage_date":           "",
              "assignment_of_mortgage_recorded_date":  "",
              "assignment_of_mortgage_assignor":       "",
              "assignment_of_mortgage_assignee":       "",
              "close_end":                             "",
              "comments":                              ""
            }
          ],
          "parcel_id":  "",
          "plat_book":  "",
          "property_address": "",
          "property_city":  "",
          "property_county":  "",
          "property_state":   "",
          "property_zip":     "",
          "property_type":    "",
          "pud_hoa": {
            "fulltext": "",
            "pud":      "",
            "hoa":      ""
          },
          "foreclosure": [
            {
              "foreclosure": false
            }
          ],
          "real_estate_taxes": {
            "parcel_id":                     "",
            "assessment_year":               "",
            "assessed_land":                 "",
            "assessed_building":             "",
            "assessed_value":                "",
            "annual_real_estate_tax_amount": "",
            "actual_due_date":               "",
            "real_estate_tax_status":        "",
            "real_estate_tax_deliquency":    "",
            "real_estate_tax_comments":      ""
          },
          "recital": [
            {
              "fulltext":       "",
              "grantor":        "",
              "grantee":        "",
              "date":           "",
              "recorded_date":  "",
              "deed_type":      "",
              "how":            "",
              "consideration":  "",
              "recorded_in":    "",
              "location":       ""
            }
          ],
          "search_date":                          "",
          "settlement_date":                      "",
          "property_address_reprocessed":         "",
          "property_address_reprocessed_county":  ""
        },
        "tasks": [
        ],
        "settlementchecklist": [
        ],
        "grade": {
          "score": ""
        },
        "history": [
          {
            "user":            "",
            "updated_at":      "",
            "changed":         ""
          }
        ]
      }

      const documentFields = {
        // "metadata": [ "meta_file_id", "path", "uploaded_at", "folder_id" },
        // "data": {

        "Property Information & Legal Description": {
          "property_information":  {
            "attributes": { "name": "Property Information", "display": "single", "class": "col-md-4", "json_adjustment":"" },
              0: {
                "settlement_date": { "name": "Settlement Date", "display": "single", "type": "date", "class": "col-md-4" },
                "search_date": { "name": "Search Date", "display": "single", "type": "date", "class": "col-md-4" },
                "current_owner": { "name": "Current Owner", "display": "single", "type": "text", "class": "col-md-4" },
                "current_ssn": { "name": "Owner SSN", "display": "single", "type": "text", "class": "col-md-4" },
                "parcel_id": { "name": "Parcel ID", "display": "single", "type": "text", "class": "col-md-4" },
                "buyer": { "name": "Buyer", "display": "single", "type": "text", "class": "col-md-4" },
                // "property_street": { "name": "Address Street", "display": "single", "type": "text", "class": "col-md-6 clear" },
                "property_address": { "name": "Property Address", "display": "single", "type": "text", "class": "col-md-12" },
                "property_city": { "name": "Address City", "display": "single", "type": "text", "class": "col-md-6" },
                "property_county": { "name": "Address County", "display": "single", "type": "text", "class": "col-md-6" },
                "property_state": { "name": "Address State", "display": "single", "type": "text", "class": "col-md-6" },
                "property_zip": { "name": "Address ZIP", "display": "single", "type": "text", "class": "col-md-6" },
                "property_address_reprocessed": { "name": "property_address_reprocessed (For system)", "display": "single", "type": "text", "class": "col-md-6" },
                "property_address_reprocessed_county": { "name": "property_address_reprocessed_county (For system)", "display": "single", "type": "text", "class": "col-md-6" },
                // "plat_book": { "name": "Plat Book", "display": "single", "type": "text", "class": "col-md-4" },
                "property_type": { "name": "Property Type", "display": "single", "type": "dropdown", "class": "col-md-4", "options": "Single Family-single_family,Condo-condo,PUD-pud" },
            }
          },
          "legal_description":  {
            "attributes": { "name": "Legal Description", "display": "single", "class": "col-md-12", "json_adjustment":"" },
            0: {
              "legal_description":   { "name": "Legal Description", "type": "textarea", "class": "col-md-12" }
            }
          }
        },
        "Chain Of Title": {
          "recital": {
            "attributes": { "name": "Chain of Title", "name-singular": "Chain of Title", "display": "multiple", "class": "col-md-4", "json_adjustment":"recital" },
            0: {
              "grantor":        { "name": "Grantor", "type": "text","class": "col-md-6" },
              "grantee":        { "name": "Grantee", "type": "text","class": "col-md-6" },
              "date":         { "name": "Date on Deed", "display": "single", "type": "date", "class": "col-md-6" },
              "recorded_date":        { "name": "Recorded Date", "display": "single", "type": "date", "class": "col-md-6" },
              // "deed_type":            { "name": "Deed Type", "type": "text", "class": "col-md-6" },
              "how":                  { "name": "How", "type": "dropdown", "class": "col-md-6", "options": "Fee Simple-fee_simple,Leasehold-leasehold,Life Estate-life_estate" },
              "consideration":        { "name": "Consideration $", "type": "text", "class": "col-md-6" },
              "recorded_in":    { "name": "Instrument/Book/Folio", "type": "text", "class": "col-md-6" },
              "location":    { "name": "Location", "type": "text", "class": "col-md-6" },
              "deed_type":      { "name": "Deed Type", "type": "dropdown","class": "col-md-6", "options": "General Warranty-general_warranty,Special/Limited Warranty-special_limited_warranty,Quit Claim-quit_claim,GA-Builders-ga_builders,NY-Bargain & Sale-ny_bargain_sale,OH-Transfer on Death designation aff-oh_transfer_on_death_designation_aff,VA-Transfer on Death Deed-va_transfer_on_death_deed,Estate-estate" },
              // "consideration":  { "name": "Consideration", "type": "text","class": "col-md-6" },
              // "date":           { "name": "Date (from parse)", "type": "text", "class": "col-md-6" },
              // "recorded_date":  { "name": "Recorded Date", "type": "date", "class": "col-md-6" },
              // "recorded_in":    { "name": "Instrument #/Deed Bk/Page", "type": "text", "class": "col-md-6" }
            }
          }
        },
        "HOA/Condo, Entities, Estates, Foreclosure": {
          "HoaCondo":  { 
            "attributes": { "name": "HOA/Condo", "display": "single-deep", "class": "col-md-12", "json_adjustment":"HoaCondo" },
            0: {
              "HoaCondo":   { "name": "Is this property in an HOA or Condo?", "type": "radio", "class": "col-md-12", "options": "HOA-hoa,Condo-condo,Neither-neither" },
              "HoaCondo_name":   { "name": "Name of HOA or Condo", "type": "text", "class": "col-md-12" }
            }
          },
          "entities":  {
            "attributes": { "name": "Entities", "display": "single", "class": "col-md-12", "json_adjustment":"" },
            0: {
              "entities":   { "name": "Is the buyer/borrower or owner an Entity? *", "type": "radio", "class": "col-md-12", "options": "Yes-yes,No-no" }
            }
          },
          "estate":  {
            "attributes": { "name": "Estate", "display": "single", "class": "col-md-12", "json_adjustment":"" },
            0: {
              "estate":   { "name": "Is there an Estate in the chain of title that needs investigation?*", "type": "radio", "class": "col-md-12", "options": "Yes-yes,No-no" }
            }
          },
          "foreclosure":  {
            "attributes": { "name": "Foreclosure", "display": "single-deep", "class": "col-md-12", "json_adjustment":"foreclosure" },
            0: {
              "foreclosure":   { "name": "Is there a Foreclosure in the chain of title that needs investigation?", "type": "radio", "class": "col-md-12", "options": "Yes-yes,No-no" },
              "foreclosure_comments":   { "name": "Foreclosure Comments", "type": "textarea", "class": "col-md-12" }
            }
          }
        },
        "Real Estate Taxes": {
          "real_estate_taxes": {
            "attributes":                    { "name": "Real Estate Taxes", "display": "single-deep", "type": "checkbox", "class": "col-md-4", "json_adjustment":"real_estate_taxes" },
            0: {
              "parcel_id":                     { "name": "Parcel ID", "type": "text", "class": "col-md-12" },
              "assessment_year":               { "name": "Assessment Year", "type": "text", "class": "col-md-12" },
              "assessed_land":                 { "name": "Assessed Land $", "type": "text", "class": "col-md-4" },
              "assessed_building":             { "name": "Assessed Building $", "type": "text", "class": "col-md-4" },
              "assessed_value":                { "name": "Assessed Value $", "type": "text", "class": "col-md-4" },
              "annual_real_estate_tax_amount": { "name": "Annual Real Estate Tax Amount $", "type": "text", "class": "col-md-4" },
              "actual_due_date":               { "name": "Actual Due Date", "type": "date", "class": "col-md-4" },
              "real_estate_tax_status":        { "name": "Real Estate Tax Status", "type": "radio", "class": "col-md-4", "options": "Paid-paid,Unpaid-unpaid" },
              "real_estate_tax_deliquency":    { "name": "Deliquency", "type": "radio", "class": "col-md-4", "options" : "Yes-yes,No-no" },
              "real_estate_tax_comments":      { "name": "Real Estate Tax Comments", "type": "textarea", "class": "col-md-12" }
            }
          },
        },
        "Utilities": {
          "current_water_and_sewer_rents": {
            "attributes":                       { "name": "Utilities, etc.", "name-singular": "Utility", "display": "multiple", "class": "col-md-4", "json_adjustment":"current_water_and_sewer_rents" },
            0: {
              "water_account_numbers":            { "name": "Account Numbers", "type": "text", "class": "col-md-4" },
              "water_stormwater_and_sewer_rents": { "name": "Water Stormwater and Sewer Rents", "type": "text", "class": "col-md-4" },
              "bill_cycle": { "name": "Bill Cycle", "type": "text", "class": "col-md-4" },
              // "storm_water_charges":              { "name": "Stormwater Charges", "type": "text", "class": "col-md-4" },
              "utilities_type":               { "name": "Utilities Type", "type": "dropdown", "class": "col-md-4", "options" : "Water-water,Sewer-sewer,Other-other" },
              "utilities_comments":               { "name": "Utilities Comments", "type": "textarea", "class": "col-md-12" }
            }
          },
        },
        "Mortgages": {
          "mortgages": {
            "attributes":     { "name": "Mortgages", "name-singular": "Mortgage", "display": "multiple", "type": "checkbox", "class": "col-md-4", "json_adjustment":"mortgages" },
            0: {
              "borrower":                             { "name": "Borrower", "type": "text", "class": "col-md-6" },
              "trustee":                              { "name": "Trustee", "type": "text", "class": "col-md-6" },
              "lender":                               { "name": "Lender", "type": "text", "class": "col-md-6" },
              "amount":                               { "name": "Amount", "type": "text", "class": "col-md-6" },
              "debtor":                               { "name": "Debtor", "type": "text", "class": "col-md-6" },
              "secured_party":                        { "name": "Secured Party", "type": "date", "class": "col-md-4" },
              "date_on_mortgage":                     { "name": "Date on Mortgage", "type": "date", "class": "col-md-4" },
              "recorded_date_entered":                { "name": "Recorded Date", "type": "date", "class": "col-md-4" },
              "maturity_end":                         { "name": "Maturity End", "type": "text", "class": "col-md-4" },
              "recorded_in":                          { "name": "Instrument #/Book/page", "type": "text", "class": "col-md-4" },
              "date":                                 { "name": "Date on Mortgage", "type": "text", "class": "col-md-4" },
              "recorded_date":                        { "name": "Recorded Mortgage Date", "type": "text", "class": "col-md-4" },
              "ucc_financing_statement":              { "name": "UCC Financing Statement", "type": "text", "class": "col-md-4" },
              "assignment_of_mortgage_date":          { "name": "Assignment of Mortage Date", "type": "text", "class": "col-md-4" },
              "assignment_of_mortgage_recorded_date": { "name": "Assignment of Mortage Recorded Date", "type": "text", "class": "col-md-4" },
              "assignment_of_mortgage_assignor":      { "name": "Assignment of Mortage Assignor", "type": "text", "class": "col-md-4" },
              "assignment_of_mortgage_assignee":      { "name": "Assignment of Mortage Assignee", "type": "text", "class": "col-md-4" },
              // "recorded_in":          { "name": "Instrument #/Book/page (Parsed)", "type": "text", "class": "col-md-4" },
              // "comments":             { "name": "Comments", "type": "textarea", "class": "col-md-12" },
              // "status":               { "name": "Status", "section": "tasks", "type": "dropdown", "class": "col-md-12", "options": "Unreviewed-unreviewed,Reviewed-reviewed" }
            }
          },
        },
        "Judgments/Liens": {
          "judgments": {
            "attributes": { "name": "Judgments/Liens", "name-singular": "Judgment/Lien", "display": "multiple", "type": "checkbox", "class": "col-md-4", "json_adjustment":"judgments" },
              0: {
                "names_run":            { "name": "Names Run", "type": "text", "class": "col-md-12" },
                "judgment_type":        { "name": "Judgment Type", "type": "dropdown", "class": "col-md-6", "options" : "JUDG-JUDG,FTL-FTL,STL-STL,LIENS-LIENS,LIS-LIS,UCC-UCC,None-none"  },
                "case_id":              { "name": "Case ID", "type": "text", "class": "col-md-6" },
                "defendant":            { "name": "Defendant", "type": "text", "class": "col-md-6" },
                "plaintiff":            { "name": "Plaintiff", "type": "text", "class": "col-md-6" },
                "recorded_date":        { "name": "Recorded Date", "type": "date", "class": "col-md-6" },
                "deedbook-liberfolio":  { "name": "Instrument #/Book/page", "type": "text", "class": "col-md-6" },
                "amount":               { "name": "Amount", "type": "text", "class": "col-md-12" },
                // "JUDG":                 { "name": "", "type": "checkbox", "class": "col-md-4" },
                // "FTL":                  { "name": "", "type": "checkbox", "class": "col-md-4" },
                // "STL":                  { "name": "", "type": "checkbox", "class": "col-md-4" },
                // "LIENS":                { "name": "", "type": "checkbox", "class": "col-md-4" },
                // "LIS":                  { "name": "", "type": "checkbox", "class": "col-md-4" },
                // "UCC":                  { "name": "", "type": "checkbox", "class": "col-md-4" },
                // "comments":             { "name": "Comments", "type": "textarea", "class":  "col-md-12" }
              }
          },
        },
        "Bankruptcy": {
          "bankruptcies":  {
            "attributes": { "name": "Bankruptcies", "name-singular": "Bankruptcy", "display": "multiple", "class": "col-md-12", "json_adjustment":"bankruptcies" },
            0: {
              "name_run":   { "name": "Name Run", "type": "text", "class": "col-md-4" },
              "results":    { "name": "Results", "type": "text", "class": "col-md-4" },
              "SSN":        { "name": "SSN", "type": "text", "class": "col-md-4" }
            }
          },
        },
        "Divorce": {
          "divorce": {
            "attributes": { "name": "Divorce", "name-singular": "Divorce", "display": "multiple", "class": "col-md-4", "json_adjustment":"divorce" },
            0: {
              "case":           { "name": "Case ID", "type": "text", "class": "col-md-4" },
              "recorded_date":  { "name": "Recorded Date", "type": "date", "class": "col-md-4" },
              "plaintiff":      { "name": "Plaintiff", "type": "text", "class": "col-md-4" },
              "defendant":      { "name": "Defendant", "type": "text", "class": "col-md-4" }
            }
          },
          // "pud_hoa": {
          //   "attributes": { "name": "PUD HoA", "display": "single-deep", "class": "col-md-4", "json_adjustment":"pud_hoa" },
          //   0: {
          //     "fulltext":   { "type": "text", "class": "col-md-4" },
          //     "pud":        { "type": "text", "class": "col-md-4" },
          //     "hoa":        { "type": "text", "class": "col-md-4" }
          //   }
          // }
        }
      }

      const documentAccordians = {
        "property_information"                : "show",
        "property_information1"               : "show",
        "judgments1"                          : "show",
        "legal_description1"                  : "show",
        "real_estate_taxes1"                  : "show",
        "HoaCondo1"                           : "show",
        "entities1"                           : "show",
        "estate1"                             : "show",
        "foreclosure1"                        : "show",
        "mortgages1"                          : "show",
        "current_water_and_sewer_rents1"      : "show",
        "recital1"                            : "show",
        "bankruptcies1"                       : "show",
        "divorce1"                            : "show",
      }

      const processStatus = {
        "form" : "",
        "task" : ""
      }

      this.state = {
        showUpload: false,
        documentStep: 'all--hidden',
        documentObject: JSON.parse(JSON.stringify(documentObject)),
        documentObjectOriginal: JSON.parse(JSON.stringify(documentObject)),
        documentAccordians: documentAccordians,
        processStatus: processStatus,
        processChanged: JSON.stringify({}),
        metaFields: {
          "folder_id":        { "name": "Client Name", "display": "single", "type": "dropdown", "class": "col-md-4", "options": "Alpha Abstract Agency LLC-ppGjymvaX33|August Title-Cir9TLCjUCj|BlockLand Transfer-g5iLxTJqhF|Bluegrass Land Title, LLC-4i79ykgc944r|Brownstone Abstract-RMujPZY3nR|C. Jackson Nantz, III, P.C.-7jrgVl6yp09|Cassidy Title, LLC-odf45hs890g|Capstone Land Transfer LLC-p2gL54mk7I3|Cardinal Settlements LLC-81a6GSDCr1|CB Title Group LLC-dCFx2fL5KW|Divvy Title-qjk5kQrdhT|Duane C. Romanello, P.A.-OdQciUTzd6|Epoch Property Transfer, LLC-ua45t6aa1kn|Express Title Agency LLC-FxhPGHFn8j|Faxon Settlement Services LLC-qQ9yaRUYlp9|Florida Real Estate Title & Closings, Inc-h5y8juKk9la0|Flow Title LLC-rBiuMlk8mo10|Flynn Title-h9bJVG4wxBV|Forever Home Title-KsgR0Quc0v|Gennex Settlement Services-7S4jZp18LO|Good Deeds & Co-rKbAyYyAfv2|Houwzer Title-xuXjMWpV7v2|Innovative Land Transfer, LLC-DC3XTM7W2s|Law Offices of Michael C. Lima-ff56Y79kla6|Leap Settlement Services-rddHM56ya46|LemonBrew Abstract LLC-0frr5yui8lk|Milestone Title Services, LLC-2g7hG3kL9k0|My Philly Abstract LLC-6uk7i0f5rty|NextGen-8J56yt329l|Nova Abstract-hK6tGi4aAJ|PHL Associates LLC a/b/a PHL Abstract-ppGjymvaX33|PM Title LLC-t6yaA7KC4lay|Prominent Title-hb2IAx3RHf|RARE Title Services LLC dba RARE Settlement Services LLC-ppGjymvaX33|Real Property Transfer-oA7wsWCaKC|Signature Abstract-glo2MXitX0|SJ Abstract LLC dba Interstate Abstract-po7eVdoaqzo|South Central Agency, Inc-X2JfTW8Yjd|Stewart Abstract of Berks County, Inc.-029JGRF5nL6|TestDocuments-xuXjMWpV7v4|TitleBox-xuXjMWpV7v6|True Value Title-Iu9GLACYn7|Truist Abstract, LLC-8iig5gh6l5j|TitleIQ LLC dba TitleEQ-p57g1h70id3|Urban Abstract, Inc-fo758iohkuo|Wagon Wheel Title and Escrow, LLC-rt5e3klje53" },
          "meta_file_id":     { "name": "File Number (Double Click in List if Editing)", "placeholder": "Start Typing a Document ID", "display": "single", "type": "text", "class": "col-md-4" },
          // "folder_id":        { "name": "Client ID", "display": "single", "type": "dropdown", "class": "col-md-4", "options": "Community Settlements & Escrow  LLC-rBkHMvTkm10" },
          // "search_company":   { "name": "Search Company (Optional)", "display": "single", "type": "dropdown", "class": "col-md-4", "options": "Titlewave-titlewave|Mortiles-mortiles|AgentNet-agentnet" }
        },
        documentFields: JSON.parse(JSON.stringify(documentFields)),
        documentFieldsOriginal: JSON.parse(JSON.stringify(documentFields))
      
    }

    // this.handleSubmit         = this.handleSubmit.bind(this)
    // this.loadDocumentContent  = this.loadDocumentContent.bind(this)
  }

  componentDidMount() {
    const email      = localStorage.getItem('email')
    const hostname   = window.location.hostname

    const allow = [
      'exam@thetitlebox.com',
      'purple2@thetitlebox.com',
      'purple7@thetitlebox.com',
      'blue3@thetitlebox.com',
      'blue5@thetitlebox.com',
      'heather@thetitlebox.com',
      'orders@thetitlebox.com',
      'amber@titleeq.com',
      'matt@titleeq.com',
      'jamie@titleeq.com',
      'matt@thetitlebox.com',
      'jamie@thetitlebox.com',
      'whitney@krape.com',
      'wkrape@gmail.com'
    ]

    this.setState({
      hostname: hostname
    })






    // Set out the main VARs
    let uploadedDocument = sessionStorage.getItem('uploadedDocument')
    let temporaryFileId  = localStorage.getItem('fileid')
    if (uploadedDocument)
      uploadedDocument = JSON.parse(uploadedDocument)
    var filesPDFs = sessionStorage.getItem('filesPDFs')
    var selectionCollection = sessionStorage.getItem('selectionCollection')
    // var selectionCollection = 'xuXjMWpV7v6'
    let showUpload = false
    // Set out the main VARs


    // console.log(window.location.hostname)

    /*
    if (window.location.hostname != 'instant.thetitlebox.com' && window.location.hostname != 'examdemoapp.azurewebsites.net') {
      showUpload = true
    }
    */

    if (allow.includes(email)) {
      // console.log(email)
      this.setState({
        admin: true
      })

      if (!window.location.hostname.includes('instant'))
        showUpload = true
    }

    this.setState({
      filesPDFsLoading: 'Will load documents in a moment. (If you\'ve waited more than a few seconds, reload the page.)',
      showUpload:       showUpload
    })

    var getCachedURL = contentAPI + '/getCachedDocuments'
    var uploadURL = contentAPI + '/companycheck'

    axios.post(uploadURL, {
      data: {
        'ClientEmail': email,
      }
    }).then((response) => {
      // console.log(response.data.result.ClientId)
      selectionCollection = response.data.result['ClientId']

      // console.log('here1')

      if (selectionCollection) {
        var getCachedURL = contentAPI + '/getCachedDocuments'

        this.setState({
          filesPDFsLoading: "Loading documents..."
        })

        if (this.state.searchByAvailable === "Rejected") {
          selectionCollection = 'xuXjMWpV787'
        }

        axios.post(getCachedURL, {
          data: {
            'selectionCollection': selectionCollection,
          }
        }).then((response) => {
          filesPDFs = response.data.result
          filesPDFs = filesPDFs.reverse()

          sessionStorage.setItem('filesPDFs', JSON.stringify(filesPDFs))
          this.setState({
            filesPDFs: filesPDFs,
            filesPDFsLoading: ''
          })
          sessionStorage.setItem('selectionCollection', selectionCollection)

          if (temporaryFileId && temporaryFileId.length) {
            localStorage.setItem('fileid', '')

            if (temporaryFileId && temporaryFileId.length) {
              this.loadThisDocument(temporaryFileId, 'yes')
            }
          }
        }).catch((err) => {
          this.setState({
            filesPDFsLoading: 'Error loading documents.'
          })
          console.log(err)
        })
      }
    }).catch((err) => {
      this.setState({
        filesPDFsLoading: 'Error getting server list.'
      })
      // console.log(err)
    })


    if (uploadedDocument) {
      console.log(uploadedDocument)

      this.loadExistingDocument(uploadedDocument)
    }















    // this.loadDocumentContent('620e59bd48e43702820b8edc','rt5e3klje53')

    // Load document content
    // 
    // 
  }

  handleSubmit = (event) => {
    event.preventDefault()

    // console.log(this.state.documentObject)

    let documentObject      = this.state.documentObject
    const meta_file_id      = this.state.documentObject.metadata.meta_file_id
    const exactDocumentId   = this.state.documentObject.metadata.exactDocumentId
    const folder_id         = this.state.documentObject.metadata.folder_id
    const editType          = "document"
    let processStatus       = this.state.processStatus
    let processChanged      = this.state.processChanged

    let blobData = new FormData()

    let documentObjectHistory = documentObject.history
    let userEmail = localStorage.getItem('email')
    let modified = { 
      "user":       userEmail,
      "changed":    processChanged, 
      "updated_at": Date.now()
    }

    // console.log(documentObjectHistory)

    documentObjectHistory.push(modified)
    documentObject.history = documentObjectHistory

    documentObject = JSON.stringify(this.state.documentObject)
    blobData.append('documentContent', documentObject)
    blobData.append('folder_id', folder_id)
    blobData.append('meta_file_id', meta_file_id)
    blobData.append('exactDocumentId', exactDocumentId)
    blobData.append('editType', editType)
    // If "exactId" is set then use editCachedDocument
    // If no exactId, then use createCachedDocument
    // ...

    let formURL = contentAPI + '/editCachedDocument'

    // console.log('documentObject')
    // console.log(documentObject)

    // Append to history


    fetch(formURL, {
      method: 'POST',
      body: blobData
    })
    .then((result) => { 
      let updatedState = this.state.documentObject
      processStatus.form                  = 'Document Saved'
      updatedState.metadata.notifcation   = ''

      this.setState({
        documentObject: updatedState,
        processStatus:  processStatus,
        processChanged: JSON.stringify({})
      })

      console.log(result)
    })
    .catch((result) => {
      processStatus.form = 'Error Saving Document'

      this.setState({
        processStatus: processStatus
      })

      console.log(result)
    })
    // Endpoint to go out to Mongo
  } // handleSubmit

  handleFileInformationSubmit = (event) => {
    event.preventDefault()

    const grade   = event.target.grade.value
    const status  = event.target.status.value

    // console.log(grade)
    // console.log(status)
  } // handleFileInformationSubmit

  dynamicSearch = (event) => {
    let allPDFs = sessionStorage.getItem('filesPDFs')
    allPDFs = JSON.parse(allPDFs)
    // console.log(allPDFs)
    let searchTerm = this.state.searchTerm
    // console.log(searchTerm)
    let searchByAvailable = this.state.searchByAvailable
    let filteredPDFs = []

    // console.log('allPDFs')
    // console.log(allPDFs)

    if (allPDFs &&
      Array.isArray(allPDFs)) {
      filteredPDFs = allPDFs.filter( ({ document }) => {
        let filterByFilename = ''
        if (document.metadata.all_filenames) {
          filterByFilename  = document.metadata.all_filenames[0]
        }
        let filterById        = document.metadata.meta_file_id
        let filterByClient    = document.data.client_name
        let filterByAddress   = document.data.property_address

        if (filterById === undefined)
          filterById = '100000001'

        filterById = filterById.toString()

        // console.log(filterById)
        // console.log(filterById.includes(searchTerm))

        // lowercasing, I think this would be cleaner as a Regex test 

        if (filterById.toLowerCase().includes(searchTerm.toLowerCase())) {
          return document
        }
      })
    }

    filteredPDFs = filteredPDFs.slice(0,5)

    // console.log('filteredPDFs')
    // console.log(filteredPDFs)

    this.setState({
      filteredPDFs: filteredPDFs
    })
  } // dynamicSearch

  //
  editSearchTerm = (value) => {
    const self = this

    if (this.state.typingTimeout) {
       clearTimeout(this.state.typingTimeout)
    }

    this.setState({
       searchTerm: value,
       typing: false,
       typingTimeout: setTimeout(function () {
          self.dynamicSearch()
         }, 800)
    })
  } // editSearchTerm
  //

  processAddressFields = (event) => {

  } // processAddressFields

  handleChangeInput = (event) => {
    let name = event.target.name
    let parent = ''
    let number = ''
    let display = ''
    let section = ''
    let type = event.target.type
    let value = event.target.value
    const addressFields = [
      "property_address",
      "property_city",
      "property_county",
      "property_state",
      "property_zip"
    ]

    // console.log('type')
    // console.log(type)

    if (event.target.attributes.section)
      section = event.target.attributes.section.value

    if (event.target.attributes.parent)
      parent = event.target.attributes.parent.value

    if (event.target.attributes.display)
      display = event.target.attributes.display.value

    if (event.target.attributes.number)
      number = event.target.attributes.number.value

    let updatedState  = this.state.documentObject
    let updatedWhat   = JSON.parse(this.state.processChanged)
    let originalState = this.state.documentObjectOriginal
    originalState = JSON.parse(JSON.stringify(originalState))

    // console.log('originalState')
    // console.log(originalState)

    if (String(value).toLowerCase() === 'yes' && type === 'radio') {
      // console.log('yes')
      value = JSON.parse('true')
    }

    if (String(value).toLowerCase() === 'no' && type === 'radio') {
      // console.log('no')
      value = JSON.parse('false')
    }

    if (event.target.name === "folder_id") {
      const client_name   = value.split('-')[0]
      value               = value.split('-')[1]

      updatedState.data.client_name = client_name
    }

    if (name === "meta_file_id") {
      this.editSearchTerm(event.target.value)
    }

    if (name === "folder_id") {
      // console.log(value)

      this.reloadList(value)
    }



    // improve Main change area, needs work.
    if (!updatedState.hasOwnProperty[parent] && display === "single") {
      // console.log("single 01")

      updatedState[section][name] = value
    } else if (updatedState.hasOwnProperty[parent] && display === "single") {
      // console.log("single 02")

      updatedState[section][parent][name] = value
    } else if (parent && display === "multiple" || parent && display === "single-deep") {
      if (section === parent) {
        if (updatedState[section][number]) {
          // console.log("single deep 01")

          updatedState[section][number][name] = value
        } else {
          // console.log("single deep 02")

          updatedState[section][number] = JSON.parse(JSON.stringify(originalState[section][0]))
          updatedState[section][number][name] = value
        }
      } else if (updatedState[section][parent][number] !== undefined) {
        // console.log("single deep unfound 01")

        updatedState[section][parent][number][name] = value
      } else if (updatedState.data[parent][name]) {
        // console.log("single deep else")

        updatedState[section][parent][name] = value
      } else if (parent === "HoaCondo") {
        if (
            updatedState[section][parent] === false ||
            updatedState[section][parent] === true
          ) {
            updatedState[section][parent] = {
              "HoaCondo": "",
              "HoaCondo_name": ""
            }
        }

        updatedState[section][parent][name] = value
      } else if (updatedState[section][parent]) {
        updatedState[section][parent][name] = value
      } else {
        // console.log(section + " " + parent + " " + number + " " + name)

        updatedState[section][parent][number] = JSON.parse(JSON.stringify(originalState[section][parent][0]))
        updatedState[section][parent][number][name] = value
      }
    } else {
      // console.log('updatedState')
      // console.log(updatedState)

      // console.log('updatedState[section]')
      // console.log(updatedState[section])

      // console.log('section')
      // console.log(section)

      updatedState[section][name] = value
    }

    // console.log('name')
    // console.log(name)
// 
    // console.log('value')
    // console.log(value)

    if (addressFields.includes(name)) {
      let combineCountyState = updatedState['data']['property_county']

      let combineAddresses = updatedState['data']['property_address'] + ' ' + updatedState['data']['property_city'] + ' ' + combineCountyState  + ', ' + updatedState['data']['property_state'] + ' ' + updatedState['data']['property_zip']

      updatedState['data']['property_address_reprocessed'] = combineAddresses
      updatedState['data']['property_address_reprocessed_county'] = combineCountyState
    }




    if (
      event.target.name === "meta_file_id" || 
      event.target.name === "folder_id"
    ) {
      let path = this.state.documentObject.metadata.folder_id + "/" + this.state.documentObject.metadata.meta_file_id

      // console.log(path)

      updatedState.metadata.path        = path
      updatedState.metadata.uploaded_at = Date.now()
    }

    // Constructed what was modified
    let changedArray = []

    // console.log('updatedWhat01')
    // console.log(updatedWhat)

    if (section && !updatedWhat[section]) {
      // console.log('No Section ' + section)

      changedArray.push(name)

      updatedWhat[section] = changedArray
    } else if (updatedWhat[section]) {
      // console.log('Yes Section ' + section)

      changedArray = updatedWhat[section]

      if (!changedArray.includes(name)) {
        // console.log('No Name ' + name)

        changedArray.push(name)

        updatedWhat[section] = changedArray
      }
    }

    // console.log('updatedWhat02')
    // console.log(updatedWhat)


    // console.log('updatedState Input Change')
    // console.log(updatedState)

    this.setState({
      documentObject: updatedState,
      processChanged: JSON.stringify(updatedWhat)
    })
  } // handleChangeInput

  choiceButtonEffect = (event) => {
    // console.log(event.target.value)

    event.currentTarget.blur()

    this.setState({
      documentStep: event.target.value
    })

    // console.log(event.target.value)

    if (event.target.value === "step--02-start") {

      let { documentObject, documentObjectOriginal, documentFieldsOriginal } = this.state
      documentFieldsOriginal = JSON.parse(JSON.stringify(documentFieldsOriginal))
      documentObject.data = documentObjectOriginal.data
      documentObjectOriginal = JSON.parse(JSON.stringify(documentObjectOriginal))
      documentObject.data = documentObjectOriginal.data
      documentObject.metadata = documentObjectOriginal.metadata
      documentObject.grade = documentObjectOriginal.grade


      // console.log("Starting new document")
      // console.log(documentFieldsOriginal)

      this.setState({
        documentObject: documentObject,
        documentFields: documentFieldsOriginal
      })

      sessionStorage.setItem('uploadedDocument', '')

      // console.log("this.state")
      // console.log(this.state)
    }

    // if (
    //     this.state.documentObject.metadata.meta_file_id !== '' &&
    //     event.target.value === "step--02-start"
    //   ) {
    //   sessionStorage.setItem('uploadedDocument', '')
// 
    //   // Reloads to fully clear the page, needed?
    //   // window.location.reload(false)
    // }
  } // choiceButtonEffect

  createNewDocument = (event) => {
    let getCachedURL = contentAPI + '/createCachedDocument'
    let { 
      meta_file_id, 
      clientFolder, 
      documentObject, 
      documentObjectOriginal, 
      filesPDFs 
    } = this.state
    let processStatus = this.state.processStatus
    let filteredPDFs = ''
    let folder_id = ''

    folder_id = documentObject.metadata.folder_id
    meta_file_id = documentObject.metadata.meta_file_id

    // console.log(this.state.documentObjectOriginal)

    // console.log(filesPDFs)

    if (filesPDFs) {
      filteredPDFs = filesPDFs.filter( ({ document }) => {
        let filterById = document.metadata.meta_file_id
        if (filterById)
          filterById = filterById.toString()

        // console.log('meta_file_id')
        // console.log(meta_file_id)

        // console.log('filterById')
        // console.log(filterById)

        if (filterById && filterById.includes(meta_file_id)) {
          // console.log(filterById.includes(meta_file_id))
          return document
        }
      })
    }

    // console.log(filteredPDFs)

    // console.log('meta_file_id')
    // console.log(meta_file_id)

    // console.log('filteredPDFs')
    // console.log(filteredPDFs)
    // console.log(filteredPDFs.length)

    if (filteredPDFs && filteredPDFs.length) {
      // let updatedState = documentObject.processStatus 

      // updatedState['error'] = "Duplicate Document"

      // console.log("Duplicate Document")


      processStatus.form = 'This Document ID already exists, update the Document ID'

      this.setState({
        processStatus: processStatus
      })

      // this.setState({
      //   processStatus: updatedState
      // })
    } else {
      if (meta_file_id && 
        meta_file_id !== "Document File ID" && 
        folder_id &&
        folder_id !== "Client ID") {
        axios.post(getCachedURL, {
          data: {
            'documentObject': documentObject
          }
        })
        .then((response) => { 
          // console.log (documentObject)
          documentObject.metadata.exactDocumentId = response.data.result
          documentObjectOriginal = JSON.parse(JSON.stringify(documentObjectOriginal))

          processStatus.form = 'Document Created'

          this.setState({
            documentStep: 'step--03-start',
            // documentFields: documentFieldsOriginal,
            documentObject: documentObject,
            processStatus: processStatus
          })

          // console.log(response)
        })
        .catch((err) => {
          console.log(err)
        })
      } else {
        console.log('Form error')
        console.log(this.state)
      }
    }
  } // createNewDocument

  loadExistingDocument = (event) => {
    let getCachedURL = contentAPI + '/loadExactCachedDocument'
    let meta_file_id, folder_id, clientFolder = this.state
    let documentObject = this.state.documentObject
    let updatedState = documentObject
    let documentObjectOriginal = this.state.documentObjectOriginal
    documentObjectOriginal = JSON.parse(JSON.stringify(documentObjectOriginal))
    let documentFields = this.state.documentFields
    let documentFieldsOriginal = this.state.documentFieldsOriginal
    documentFieldsOriginal = JSON.parse(JSON.stringify(documentFieldsOriginal))

    meta_file_id  = this.state.documentObject.metadata.meta_file_id
    folder_id     = this.state.documentObject.metadata.folder_id

    // console.log('folder_id')
    // console.log(folder_id)

    if (event && event.metadata) {
      // console.log('event.metadata.meta_file_id')
      // console.log(event.metadata.meta_file_id)
      // console.log('event.metadata.folder_id')
      // console.log(event.metadata.folder_id)

      meta_file_id  = event.metadata.meta_file_id

      if (event.metadata.folder_id)
        folder_id     = event.metadata.folder_id
    }

    if (meta_file_id && 
      meta_file_id !== "" && 
      meta_file_id !== "Document File ID" && 
      folder_id &&
      folder_id !== "Client ID") {

      //console.log("Yep, getting the document.")

      let processStatus = this.state.processStatus
      processStatus.form = 'Loading...'

      updatedState.metadata = documentObjectOriginal.metadata
      updatedState.data = documentObjectOriginal.data
      updatedState.grade = documentObjectOriginal.grade


      //
      // Need to clear out the old document better
      // Judgment run through and update dropdown.
      //

      setTimeout(() => {
        processStatus.form = "Still Loading..."

        if (this.state.processStatus.form.includes('Loading') > 1) {
          this.setState({
            processStatus: processStatus
          })
        }
      }, 5000)

      setTimeout(() => {
        processStatus.form = "Error Loading..."

        if (this.state.processStatus.form.includes('Still') > 1) {
          this.setState({
            processStatus: processStatus
          })
        }
      }, 10000)


      this.setState({
        documentObject: updatedState,
        // documentFields: documentFieldsOriginal,
        processStatus: processStatus
      })

      axios.post(getCachedURL, {
        data: {
          'meta_file_id': meta_file_id,
          // 'exactDocumentId': "618bf26006cdf76428cb7295",
          'selectionCollection': folder_id
        }
      })
      .then((response) => { 
        // console.log("response")
        // console.log(response)
        response = JSON.parse(response.data.results)

        // console.log("response")
        // console.log(response)
        let lengthOfField = ''
        let fieldsToDuplicate = []

        // Remove Don't think this is needed
        documentObject.metadata.meta_file_id  = response.document.metadata.meta_file_id
        documentObject.metadata.file_id       = response.document.metadata.file_id
        documentObject.metadata.assignedto    = response.document.metadata.assignedto
        // Remove

        let reverseRecital                = response.document.data['recital']
        if (reverseRecital)
          response.document.data['recital'] = reverseRecital.reverse()

        documentObject.metadata               = response.document.metadata
        documentObject.settlementchecklist    = response.document.settlementchecklist

        console.log('folder_id')
        console.log(folder_id)

        if (response.document.metadata.folder_id) {
          console.log('Yes, this has a saved folder ID')
          console.log(documentObject.metadata.folder_id)

          documentObject.metadata.folder_id     = response.document.metadata.folder_id
        } else {
          console.log('No, this needs an ID')
          console.log(folder_id)

          documentObject.metadata.folder_id     = folder_id
        }

        console.log('documentObject')
        console.log(documentObject)

        documentObject.metadata.uploaded_at   = response.document.metadata.uploaded_at
        documentObject.metadata.path          = response.document.metadata.path
        documentObject.data                   = response.document.data

        if (response.document.metadata.status)
          documentObject.metadata.status      = response.document.metadata.status
        if (response.document.metadata.statusDate)
          documentObject.metadata.statusDate  = response.document.metadata.statusDate
        if (response.document.grade)
          documentObject.grade                = response.document.grade
        if (response.document.history)
          documentObject.history              = response.document.history

        if (response.document.tasks && response.document.tasks.length) {
          documentObject.tasks = response.document.tasks
        } else {
          // IF NO TASKS BUILD THEM OUT
          // Don't think this is needed?

          // documentObject.tasks = [
          //   {
          //     "problem":            "overall",
          //     "status":             "open",
          //     "priority":           "low",
          //     "due_date":           "",
          //     "comments":           ""
          //   }
          // ]
        }
        documentObject.metadata.exactDocumentId = response._id

        // console.log('documentObject')
        // console.log(documentObject)

        // console.log('documentObject.data')
        // console.log(documentObject.data)

        // documentFields = documentFieldsOriginal

        // console.log('documentObject')
        // console.log(documentObject)
// 
        // console.log('documentFields')
        // console.log(documentFields)

        Object.entries(documentFields).map((section, section_index) => {
          // console.log(section)
          const sectionContent = section[1]
          const sectionName    = section[0]

          Object.entries(sectionContent).map((problem, problem_index) => {
            const problemContent  = problem[1]
            const problemName     = problem[0]

            if (
              problemContent["attributes"] && 
              problemContent["attributes"]["display"] === "multiple" && 
              documentObject.data[problemName]
            ) {
              // console.log('problemName')
              // console.log(problemName)

              lengthOfField = documentObject.data[problemName].length
              let currentLength = Object.values(documentFields[sectionName][problemName]).length - 1
              
              // console.log('lengthOfField')
              // console.log(lengthOfField)
// 
              // console.log('currentLength')
              // console.log(currentLength)

              if (lengthOfField > currentLength) {
                for (let dup = 0 + 1; dup < lengthOfField; dup++) {
                  documentFields[sectionName][problemName][dup] = JSON.parse(JSON.stringify(documentFieldsOriginal[sectionName][problemName][0]))
                  
                    // console.log('fieldsToDuplicate')
                    // console.log(fieldsToDuplicate)
                }
              } else {
                for (let dup = lengthOfField; dup < currentLength; dup++) {
                  delete documentFields[sectionName][problemName][dup]
                    // console.log('fieldsToDuplicate')
                    // console.log(fieldsToDuplicate)
                }
              }
            }

          })
        })

        /*
        console.log(documentFields['Chain Of Title']['recital'])

        let reverseRecitalFields                    = Object.entries(documentFields['Chain Of Title']['recital']).reverse()
        let reverseRecitalFieldsObject = {}
        reverseRecitalFields.reduce(function(result, item, index, array) {
          // console.log(result)
          console.log(item)

            documentFields['Chain Of Title']['recital'][index] = item[1]
          return result
        }, {})

        console.log(reverseRecitalFieldsObject)
        documentFields['Chain Of Title']['recital']   = reverseRecitalFieldsObject
        */

        sessionStorage.setItem('uploadedDocument', JSON.stringify(documentObject))

        processStatus.form = 'Loaded'

        if (meta_file_id) {
          let getURL = contentAPI + '/getSupportingDocuments'
          let preparse_id  = this.state.documentObject.metadata.file_id

          if (preparse_id && preparse_id.length) {
            folder_id = this.state.documentObject.metadata.path
            folder_id = folder_id.split("/")[0]
            meta_file_id = preparse_id
          }

          axios.post(getURL, {
            data: {
              'clientFile':   meta_file_id,
              'clientFolder': folder_id
            }
          })
          .then((response) => {
              var sendBackList = response.data.sendBackList
              var report = "report";
              sendBackList = sendBackList.sort(function(item,itemNext) { 
                // console.log(item.documentName.toLowerCase().includes(report));
                return item.documentName.toLowerCase().includes(report) ? -1 : itemNext.documentName.toLowerCase().includes(report) ? 1 : 0; 
              })

              // console.log('sendBackList')
              // console.log(sendBackList)

              this.setState({
                supportingDocuments: sendBackList
              })
          })
          .catch((err) => {
              console.log(err)
          })
        }

        console.log('setdocument, step, and process')

        this.setState({
          documentObject: documentObject,
          documentStep: 'step--03-existing',
          processStatus: processStatus,
          inprogressPage: "inprogress__form"
        })

        console.log(this.state.documentStep)
      })
      .catch((err) => {
        let processStatus = this.state.processStatus

        processStatus.form = 'Error loading Document. Check ID and try clicking load again.'

        this.setState({
          processStatus: processStatus
        })

        console.log(err)
      })
    } else {
      // console.log('meta_file_id')
      // console.log(meta_file_id)
      // console.log('folder_id')
      // console.log(folder_id)
// 
      // // console.log(documentObject.metadata.meta_file_id)
      // // console.log(documentObject.metadata.folder_id)
// 
      // console.log('Form error')
    }
  } // loadExistingDocument

  inprogressPage = (event) => {
    let inprogressPage = event.target.name

    //console.log(inprogressPage)

    this.setState({
      inprogressPage: inprogressPage
    })
  } // inprogressPage

  reloadList = (folder_id) => {
    // console.log(folder_id)
    var getCachedURL = contentAPI + '/getCachedDocuments'
    var filesPDFs

    axios.post(getCachedURL, {
      data: {
        'selectionCollection': folder_id,
      }
    }).then((response) => {
      console.log('response')
      console.log(response)

      filesPDFs = response.data.result
      filesPDFs = filesPDFs.reverse()

      sessionStorage.setItem('filesPDFs', JSON.stringify(filesPDFs))
      this.setState({
        filesPDFs: filesPDFs,
        filesPDFsLoading: ''
      })
      sessionStorage.setItem('selectionCollection', folder_id)
    }).catch((err) => {
      this.setState({
        filesPDFsLoading: 'Error loading documents.'
      })
      console.log(err)
    })
  } // reloadList

  handleOptionReload = (event) => {
    window.location.reload()
  } // handleOptionReload


  // change input event

  // submit event

  render () {
    let { 
      showUpload, 
      documentObject,
      documentObjectOriginal, 
      documentStep,
      metaFields,
      documentFields,
      documentFieldsOriginal,
      documentAccordians,
      processStatus,
      filteredPDFs,
      inprogressPage,
      supportingDocuments
    } = this.state

    // console.log('documentFields')
    // console.log(documentFields)

    return (
      !hostname.includes("instantish") ?
        <div className="p-6 examiner__upload">
          <h1>Welcome to the TitleBox LIVESearch&trade;</h1>

          <div className="col-md-12">
            <OnPageMenu handleOptionReload={ this.handleOptionReload.bind(this) } />
          </div>
          
          <div className="col-md-12">
            <IntakeFormMenu 
              inprogressPage={ this.inprogressPage.bind(this) } 
              activePage={ inprogressPage }
              documentStep={ documentStep }
              loadDirectDocument={ loadDirectDocument }
              documentObject={ documentObject }
            />
          </div>

          <div className={ inprogressPage === "inprogress__form" ? "inprogress__form active" : "inprogress__form" }>
            <IntakeFormInputs 
              handleSubmit={ this.handleSubmit.bind(this) } 
              handleFileInformationSubmit={ this.handleFileInformationSubmit.bind(this) } 
              choiceButtonEffect={ this.choiceButtonEffect.bind(this) } 
              createNewDocument={ this.createNewDocument.bind(this) } 
              loadExistingDocument={ this.loadExistingDocument.bind(this) } 
              handleChangeInput={ this.handleChangeInput.bind(this) }
              metaFields={ metaFields }
              documentFields={ documentFields }
              documentFieldsOriginal={ documentFieldsOriginal }
              documentObject={ documentObject }
              documentObjectOriginal={ documentObjectOriginal }
              documentAccordians={ documentAccordians }
              documentStep={ documentStep }
              processStatus={ processStatus }
              filteredPDFs={ filteredPDFs }
            />
          </div>

          <div className={ inprogressPage === "inprogress__task" ? "inprogress__task active" : "inprogress__task" }>
            <TaskFormInputs 
              documentFields={ documentFields }
              documentObject={ documentObject }
              documentStep={ documentStep }
              processStatus={ processStatus }
            />
          </div>

          <div className={ inprogressPage === "inprogress__document" ? "inprogress__document active" : "inprogress__document" }>
            <UploadtoList 
              documentObject={ documentObject }
              documentStep={ documentStep }
              processStatus={ processStatus }
              filteredPDFs={ filteredPDFs }
              supportingDocuments={ supportingDocuments }
            />
          </div>

          <div className={ inprogressPage === "inprogress__form" ? "inprogress__form active" : "inprogress__form" }>
            <IntakeHistory 
              documentObject={ documentObject }
              documentStep={ documentStep }
            />
          </div>

          { /*
          <div className={ inprogressPage === "inprogress__settlementchecklist" ? "inprogress__settlementchecklist active" : "inprogress__settlementchecklist" }>
            <IntakeSettlementChecklist 
              documentFields={ documentFields }
              documentObject={ documentObject }
              documentStep={ documentStep }
              processStatus={ processStatus }
            />
          </div>
          */ }

          <br/><br/><br/><br/><br/><br/>
          <p>{ processStatus.form }</p>
          <br/><br/>
          <small>{ process.env.REACT_APP_CLIENT_VERSION }</small>
        </div>
        :
        <Redirect to="/" />
    )
  }
}

export default IntakeForm;
