import { Link } from 'react-router-dom'
import React, { Component } from 'react'

import { useSortableData } from '../upload/useSortableData.js';
import { loadThisDocument } from '../upload/UploadFunctions.js';
import { glossary } from '../upload/termSearch.js';

let reduceList = (list, field) => {
  // If it doesn't iterate start here

  console.log('list')
  console.log(list)
// 
  // console.log('field')
  // console.log(field)

  let fieldKey = ''

  const groups = list.reduce((groups, item) => {
    // console.log('item')
    // console.log(item)

    if (item && groups) {
      const group = (groups[item[field]] || [])
        group.push(item)
        groups[item[field]] = group

        // console.log('groups')
        // console.log(groups)
      return groups
    }
  }, {})

  return groups
}

let BuilderList = (props) => {
    // console.log('userLevel')
    // console.log(props)

    if (props.filesPDFs) {
      const filesPDFs = props.filesPDFs

      // if taskview do something different 
      /*
      let { items, requestSort, sortConfig } = useSortableData(filesPDFs)
      const getClassNamesFor = (name) => {
        // console.log(name)
        if (!sortConfig) {
          return
        }
        return sortConfig.key === name ? sortConfig.direction : 'off'
      }
      */

      let sortButtons = []
      let columnSpan = 3

      if (props.userLevel === "User") {
        sortButtons = [
          ['data.search_date',      'Search Date'],
          ['data.property_address', 'Address'],
          ['metadata.meta_file_id', 'File ID']
        ]

        columnSpan = 4
      } else if (props.userLevel === "Admin") {
        sortButtons = [
          ['metadata.uploaded_at',  'Uploaded Date'],
          ['data.property_address', 'Address'],
          ['data.client_name',      'Client'],
          ['metadata.meta_file_id', 'File ID'],
          ['tasks.problem.list',    'Task Type']
        ]

        columnSpan = 3
      } else if (props.userLevel === "Rejected") {
        sortButtons = [
          ['metadata.uploaded_at',  'Uploaded Date'],
          ['data.metadata.all_filenames', 'Filename']
        ]

        columnSpan = 5
      }

      let filesPDFsLength = filesPDFs.length
      let displayType     = props.displayType
      let pullAllTogether = []


      filesPDFs.filter((eachDocument) => {
        // console.log('eachDocument.document.tasks')
        // console.log(eachDocument.document.tasks)

        pullAllTogether.push(eachDocument.document.tasks)
      })

      pullAllTogether = pullAllTogether.flat()

      let organizedFromFiltered = reduceList(pullAllTogether, props.searchTerm)
      if (organizedFromFiltered)
        organizedFromFiltered     = Object.entries(organizedFromFiltered)

      return (
        <div className="examiner__review mt-0 p-0 row">
          {
            displayType === 'tasks' ?
              <div className="badge text-dark text-left">
                <h6 className="pb-2">{ pullAllTogether.length } Task(s)</h6>

                <ul className="list-group">
                {
                  Object.values(organizedFromFiltered).map((item, index) => 
                    <li className="list-group-item">
                      { item[1].length } { glossary('all', item[0]) ? glossary('all', item[0]) : item[0] }
                    </li>
                  )
                }
                </ul>
              </div>
            :
              <div className="col-md-12 row p-0">
                <button type="button" className="btn btn-outline-secondary">
                    <span className="badge badge-light">
                      { filesPDFsLength } { props.searchTermNice } File(s)
                    </span>
                  <br/>
                </button>
              </div>
          }
        </div>
      )
    } else {
      return null
    }
  }

export default BuilderList