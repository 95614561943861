import { Link } from 'react-router-dom'
import React, { Component } from 'react'
import axios from 'axios'

import { useSortableData } from '../upload/useSortableData.js';

var contentAPI = ''
var sid = {
  "role" : 'role',
  "subscriptions" : 'subscriptionsJSON',
  "expiresAt" : 'expiresAt',
  "idToken" : 'authResult',      
}
sid = JSON.stringify(sid)

const localArray = [
  'localhost',
  '192.168.0.149',
  '192.168.1.31'
]

// console.log(sid)

if (localArray.some(v => window.location.hostname.includes(v))) {
  contentAPI = 'https://' + window.location.hostname + ':3001/api'
} else {
  contentAPI = 'https://' + window.location.hostname + '/api'
}

const parserAPI = 'https://ttbsearchextractorappv0.azurewebsites.net'


export default class TaskFormInputs extends Component{
    // console.log('props')
    // console.log(props)

    // Array here builds out the forms.
    //

  constructor(props) {
    super(props)

    const tasksFields = {
      "attributes": { "name": "Tasks", "name-singular": "Task", "section":"tasks", "display": "multiple", "class": "col-md-4", "json_adjustment":"tasks" },
      "sections": [ "General-general","Judgments-judgments","Mortgages-mortgages"," Estate-estate","Entities-entities","Real Estate Taxes-real_estate_taxes"," Utilities-utilities","Divorce-divorce","HOA/Condo-hoa_condo"," Bankruptcies-bankruptcies","Chain of Title-chain_of_title","Legal Description-legal_description","Foreclosures-foreclosure" ],
      0: {
        "problem":   { "name": "Problem", "type": "dropdown", "class": "col-md-4", "options": "General-general,Judgment-judgments,Mortgage-mortgages,Estate-estate,Entity-entities,Real Estate Tax-real_estate_taxes, Utility-current_water_and_sewer_rents,Divorce-divorce,HOA/Condo-HoaCondo, Bankruptcy-bankruptcies,Chain of Title-recital,Legal Description-legal_description,Foreclosure-foreclosure,PACER/Patriot/PACS-pacerpatriotpacs" },
        "status":     { "name": "Status", "type": "dropdown", "class": "col-md-4", "options": "In Process-inprocess,Requested-requested,Closed-closed" },
        "priority":   { "name": "Priority", "type": "dropdown", "class": "col-md-4", "options": "Low-p0low,Medium-p1medium,High-p2high,Critical-p3critical" },
        "due_date":   { "name": "Due Date", "type": "date", "class": "col-md-4" },
        "comments":   { "name": "Comments", "type": "textarea", "class": "col-md-4" }
      }
    }

    this.state = {
      documentObject: this.props.documentObject,
      documentFields: this.props.documentFields,
      tasksFields: tasksFields,
      processStatus: this.props.processStatus
    }

    this.duplicateTasksFields = this.duplicateTasksFields.bind(this)
    this.removeTasksFields    = this.removeTasksFields.bind(this)
  }

  handleTasksChangeInput = (event) => {

  }

  duplicateTasksFields = (event) => {
    // console.log("event")
    // console.log(event)
    // console.log("entry")
    // console.log(event.target.className)

    // console.log("duplicate")

    let updatedState              = this.state.documentObject
    const lengthOfTasks           = Object.entries(updatedState.tasks).length
    const tasksFields             = {
            "problem":            "general",
            "status":             "open",
            "priority":           "low",
            "due_date":           "",
            "comments":           "" }


    updatedState.tasks[lengthOfTasks]   = tasksFields

    // console.log(updatedState)
    
    this.setState({
      documentFields: updatedState
    })

    /*
    const documentFieldsOriginal  = this.state.documentFieldsOriginal
    let problemForFields          = event.target.className
    problemForFields              = problemForFields.replace(" btn btn-default btn-primary", "")
    const lengthOfProblem         = Object.entries(updatedState[problemForFields]).length-1

    updatedState[problemForFields][lengthOfProblem] = JSON.parse(JSON.stringify(documentFieldsOriginal[problemForFields][0]))
    
    this.setState({
      documentFields: updatedState
    })
    */
  }

  buildOutTasks = (event) => {
    let updatedState              = this.state.documentObject
    let lengthOfTasks             = Object.entries(updatedState.tasks).length
    let documentObject            = this.state.documentObject
    let documentFields            = this.state.documentFields
    const tasksFields             = {
            "problem":            "general",
            "status":             "open",
            "priority":           "low",
            "due_date":           "",
            "comments":           "" }

    let lengthOfField = ''

        Object.entries(documentFields).map((section, section_index) => {
          // console.log(section)
          const sectionContent = section[1]
          const sectionName    = section[0]

          Object.entries(sectionContent).map((problem, problem_index) => {
            const problemContent  = problem[1]
            const problemName     = problem[0]

            // console.log('problemName')
            // console.log(problemName)

            const createForProblems = [
              "mortgages", 
              "judgments",
              "bankruptcies",
              "HoaCondo",
              "estate",
              "entities",
              "foreclosure"
            ]


            if (
              problemContent === 'foreclosure' &&
              documentObject.data['foreclosure'][0]['foreclosure']  === true ||
              problemContent === 'divorce' &&
              documentObject.data['divorce'][0]['case'].length > 0 ||
              problemContent &&
              problemContent["attributes"] && 
              problemContent["attributes"]["display"] === "multiple" &&
              createForProblems.includes(problemName) &&
              documentObject.data[problemName] ||
              problemContent &&
              createForProblems.includes(problemName) &&
              documentObject.data[problemName] === true
            ) {

              lengthOfField = documentObject.data[problemName].length
              

              if (lengthOfField === undefined)
                lengthOfField = 1

              for (let dup = 0; dup < lengthOfField; dup++) {
                let contentItem

                if (problemContent["attributes"]["display"] === "multiple") {
                  contentItem = documentObject.data[problemName][dup]
                } else {
                  contentItem = documentObject.data[problemName]
                }

                if (contentItem) {
                  // console.log('contentItem')
                  // console.log(contentItem)

                  if (
                    problemName === 'bankruptcies' &&
                    contentItem.results.includes('RESULTS FOUND ') && 
                    !contentItem.results.includes('NO') && 
                    !contentItem.results.includes('NOT') ||
                    problemName === 'judgments' &&
                    parseInt(contentItem.JUDG) > 0 ||
                    problemName === 'judgments' &&
                    parseInt(contentItem.FTL) > 0 ||
                    problemName === 'judgments' &&
                    parseInt(contentItem.STL) > 0 ||
                    problemName === 'judgments' &&
                    parseInt(contentItem.LIENS) > 0 ||
                    problemName === 'judgments' &&
                    parseInt(contentItem.LIS) > 0 ||
                    problemName === 'judgments' &&
                    contentItem.attached
                  ) {
                    // console.log('Bank and Judg filter')
                    // console.log(problemName)
                    // console.log(documentObject.data['bankruptcies'][dup])

                    lengthOfTasks                       = Object.entries(updatedState.tasks).length
                    tasksFields.problem                 = problemName
                    updatedState.tasks[lengthOfTasks]   = JSON.parse(JSON.stringify(tasksFields))

                      // console.log('fieldsToDuplicate')
                      // console.log(fieldsToDuplicate)
                  } else if (
                    problemName !== 'bankruptcies' && 
                    problemName !== 'judgments'
                  ) {
                    // console.log('Additional fields')
                    // console.log(problemName)

                    lengthOfTasks                       = Object.entries(updatedState.tasks).length
                    tasksFields.problem                 = problemName
                    updatedState.tasks[lengthOfTasks]   = JSON.parse(JSON.stringify(tasksFields))
                  }
                }
              }

            }

          })
        })


    console.log('updatedState')
    console.log(updatedState)
    
    this.setState({
      documentFields: updatedState
    })
  }


  removeTasksFields = (event) => {
    // console.log("event")
    // console.log(event)
    // console.log("entry")
    // console.log(event.target.className)

    // console.log("duplicate")
    console.log('event.target.attributes.number')
    console.log(event.target.attributes.number)

    if (event.target.attributes.number) {
      const number = event.target.attributes.number.value

      // console.log(number)

      let updatedState        = this.state.documentObject

      updatedState.tasks.splice(number, 1)
      
      this.setState({
        documentObject: updatedState
      })
    }
  }

  handleTasksChangeInput = (event) => {
    // console.log(event.target.value)

    // On change for problem attachment adds a space in "documentObject" for a task underneith
    // The appropriate section. 
    // 

    let name = event.target.name
    let parent = ''
    let number = ''
    let display = ''
    let section = ''
    let value = event.target.value

    if (event.target.attributes.section)
      section = event.target.attributes.section.value

    if (event.target.attributes.parent)
      parent = event.target.attributes.parent.value

    if (event.target.attributes.display)
      display = event.target.attributes.display.value

    if (event.target.attributes.number)
      number = event.target.attributes.number.value

    let updatedState = this.state.documentObject

    // console.log(number)

    // console.log(updatedState["tasks"])
    // console.log(updatedState["tasks"][0])

    // console.log(number)
    // console.log(updatedState["tasks"][number])

    updatedState["tasks"][number][name] = value


    /*
    let sort01 = updatedState["tasks"].sort((a, b) => {
      return b.priority - a.priority
    })

    let sort02 = sort01.sort((a, b) => {
      var wordsToValues = ['open','reviewed','closed'] 

      // console.log(wordsToValues.indexOf('open'))

        if (wordsToValues.indexOf(a.status) < wordsToValues.indexOf(b.status))
            return -1
        if (wordsToValues.indexOf(a.status) > wordsToValues.indexOf(b.status))
            return 1
        return 0
    })
    */

    // sort02.reverse()

    // console.log(updatedState["tasks"])
    // console.log(sort01)
    // updatedState["tasks"] = sort01

    // console.log(this.state)

    const processStatus = this.state.processStatus

    if (processStatus.tasks === "review") {
      // console.log("On the review page")

      this.setState({
        processStatus: {
          tasks: "review"
        }
      })

      this.handleTasksSubmit(event)
    }

    this.setState({
      documentObject: updatedState
    })
  }

  handleTasksSubmit = (event) => {
    event.preventDefault()

    // console.log(this.state.documentObject)

    this.state.documentObject.metadata.notifcation = ""

    const documentContent             = JSON.stringify(this.state.documentObject)
    const meta_file_id              = this.state.documentObject.metadata.meta_file_id
    const exactDocumentId           = this.state.documentObject.metadata.exactDocumentId
    const folder_id                 = this.state.documentObject.metadata.folder_id
    const processStatus             = this.state.processStatus
    let processStatusOriginal       = {}

    if (processStatus.tasks === "review") 
      processStatusOriginal.tasks = "review"

    let blobData = new FormData()
    // Just the task section
    blobData.append('editType', "tasks")
    blobData.append('documentContent', documentContent)
    blobData.append('folder_id', folder_id)
    blobData.append('meta_file_id', meta_file_id)
    blobData.append('exactDocumentId', exactDocumentId)
    // If "exactId" is set then use editCachedDocument
    // If no exactId, then use createCachedDocument
    // ...

    // console.log('meta_file_id')
    // console.log(meta_file_id)
    // console.log('exactDocumentId')
    // console.log(exactDocumentId)

    let formURL = contentAPI + '/editCachedDocument'

    // console.log('documentContent')
    // console.log(documentContent)

    processStatus.tasks = "Saving"

    this.setState({
      processStatus: processStatus
    })

    fetch(formURL, {
      method: 'POST',
      body: blobData
    })
    .then((result) => { 
      // console.log('result')
      // console.log(result)

      processStatus.tasks = "Saved"

      this.setState({
        processStatus: processStatus
      })

      if (processStatusOriginal.tasks === "review") {
        processStatus.tasks = "review"

        this.setState({
          processStatus: processStatus
        })
      }

      // console.log(result)
    })
    .catch((result) => {
      processStatus.tasks = "Error Saving Tasks"

      this.setState({
        processStatus: processStatus
      })

      // console.log(result)
    })
    // Endpoint to go out to Mongo
  } // handleTasksSubmit

  sortTasks = (event) => {
    event.preventDefault()

    let sortBy = ''

    if (event.target.attributes.id.value) {
      sortBy = event.currentTarget.value
    } else {
      sortBy = event.target.attributes.value.value
    }

    sortBy = sortBy.toLowerCase()
    sortBy = sortBy.replace(' ', '_')

      // console.log('sortBy')
      // console.log(sortBy)
      
      const tasksList = this.state.documentObject.tasks

      let sortableItems = [...tasksList]
      let updatedState = this.state.documentObject

      sortableItems.sort((a, b) => {
        let { aSort, bSort } = ''
        let sortConfig = { direction: 'ascending' }
        aSort = a[sortBy]
        bSort = b[sortBy]

        // console.log(aSort)
        // console.log(bSort)

        if (aSort < bSort) {
          return sortConfig.direction === 'descending' ? -1 : 1
        }
        if (aSort > bSort) {
          return sortConfig.direction === 'descending' ? 1 : -1
        }
        return 0
      })

      updatedState["tasks"] = sortableItems

      // console.log('sortableItems')
      // console.log(sortableItems)

      this.setState({
        documentObject: updatedState
      })

      this.forceUpdate()
  } // sortTasks



    render () {
      let { tasksFields, documentObject, processStatus } = this.state
      let { filteredPDFs, documentStep } = this.props

      const taskColumns = ["Problem","Status","Priority","Due Date","Comments"];

      // console.log(documentObject.tasks)

      // console.log('documentStep')
      // console.log(documentStep)

      // console.log(documentObject.metadata.meta_file_id)

      return (
        documentStep && documentStep !== "all--hidden" && documentObject.metadata.meta_file_id ?
        // documentStep && documentStep === "step--03-existing" ||
        // documentStep && documentStep === "step--03-start" ?
          <form className={ "document__editing pl-20 pr-20 " } onSubmit={ this.handleTasksSubmit }>
              <h5>Tasks</h5>

              {
                documentStep && documentStep !== "show-on--review" ?
                  <div className="row pl-0 mb-3 col-md-8">
                    <button type="button" onClick={ this.duplicateTasksFields.bind(this) } className={ "btn btn-default btn-outline-primary" }>Add a Task</button>

                    <button type="button" onClick={ this.buildOutTasks.bind(this) } className={ "ml-2 btn btn-default btn-outline-primary" }>Auto-create Tasks</button>
                  </div>
                :
                  null
              }

              <div className="section__editing p-4">
                <div className="editing__header row col-md-12 d-flex">
                  {
                    documentStep && documentStep !== "show-on--review" ?
                      taskColumns.map((column, column_index) => 
                        <div className="col-md-2 p-0">
                          <h6>
                            <button
                              onClick={ this.sortTasks.bind(this) }
                              key={ column + column_index }
                              value={ column }
                            >
                            { column }

                            <span 
                              type="button" 
                              name={ column } 
                              className={ "ml-2" } 
                              title="Collapse Tasks"
                              aria-hidden="true"
                            > 
                              &#9662;
                            </span> 
                            </button>
                          </h6> 
                        </div>
                      )
                    :
                      <div className="col-md-2 p-0">
                        <select
                          className="form-control"
                          type="text" 
                          name="tasksReviewDropdown"
                          onChange={ this.sortTasks.bind(this) }
                          id="tasksReviewDropdown"
                          key="tasksReviewDropdown"
                        >
                          <option 
                            value=""
                            key={ "tasksDropdownNone"  }
                          >Choose</option>
                          {
                            taskColumns.map((column, column_index) => 
                              <option 
                                value={ column }
                                key={ "tasksDropdown" + column }
                              >
                                { column }
                              </option>
                            )
                          }
                        </select>
                      </div>
                  }
                </div>


                    {

                        documentObject.tasks.map((task, task_index) =>
                            <div className={ "row mb-2 task__wrapper " + task["priority"] }>
                            {
                              Object.entries(tasksFields[0]).map((taskfield, taskfield_index) =>
                                  taskfield[1]["type"] === 'dropdown' && taskfield[1]["options"] ?
                                    <div className={ 'col-md-2 p-0 ' + taskfield[0] }>
                                      <label className="col-md-12">
                                        {/* taskfield[1]["name"] ? taskfield[1]["name"] : '' */}
                                        <select
                                          type={ taskfield[1]["type"] }
                                          name={ taskfield[0] }
                                          section=""
                                          display={ taskfield[1]["display"] }
                                          number={ task_index }
                                          example={ taskfield[1]["json_adjustment"] }
                                          onChange={ this.handleTasksChangeInput }
                                          className={ 'form-control ' }
                                          key={ "input" + taskfield_index }
                                          disabled={ documentStep && documentStep !== "show-on--review" || taskfield[0] === "status" ? "" : "true" }
                                        >
                                        { 
                                          taskfield[1]["options"].split(',').map((option, option_index) =>
                                            <option value={ option.split('-')[1] }
                                            selected={
                                              task[taskfield[0]] && task[taskfield[0]] === option.split('-')[1] ?
                                                true
                                              : 
                                                null
                                            }>{ option.split('-')[0] }</option>
                                          ) 
                                        }
                                        </select>
                                      </label>
                                    </div>
                                  : taskfield[1]["type"] === 'textarea' ?
                                     <div className={ 'col-md-2 p-0 ' + taskfield[0] }>
                                      <label className="col-md-12">{/* taskfield[1]["name"] */}
                                        <textarea
                                          type={ taskfield[1]["type"] }
                                          name={ taskfield[0] }
                                          section={ 'tasks' }
                                          number={ task_index }
                                          // onClick={() => bubbledevent}
                                          onChange={ this.handleTasksChangeInput }
                                          className={ ' form-control ' + taskfield[0] }
                                          key={ "input" + taskfield_index }
                                          value={ task[taskfield[0]] }
                                          disabled={ documentStep && documentStep !== "show-on--review" ? "" : "true" }
                                        >{ task[taskfield[0]] }</textarea>
                                      </label>
                                    </div>
                                  :
                                     <div className={ 'col-md-2 p-0 ' + taskfield[0] }>
                                      <label className="col-md-12">{/* taskfield[1]["name"] */}
                                        <input
                                          type={ taskfield[1]["type"] }
                                          name={ taskfield[0] }
                                          section={ 'tasks' }
                                          number={ task_index }
                                          // onClick={() => bubbledevent}
                                          onChange={ this.handleTasksChangeInput }
                                          className={ ' form-control ' + taskfield[0] }
                                          key={ "input" + taskfield_index }
                                          value={ task[taskfield[0]] }
                                          disabled={ documentStep && documentStep !== "show-on--review" ? "" : "true" }
                                        />
                                      </label>
                                    </div>


                              ) // fieldsEnd
                            }
                              <div className={ 'col-md-1 p-0 complete mx-auto text-center' }>
                                <input
                                  type="checkbox"
                                  name="status"
                                  number={ task_index }
                                  value="closed"
                                  onChange={ this.handleTasksChangeInput }
                                  checked={
                                    task["status"] && task["status"] === "closed" ?
                                      "yes"
                                    :
                                      null
                                  }
                                /> Complete
                              </div>

                              { 
                                documentStep && documentStep !== "show-on--review" ?
                                  <button 
                                    type="button" 
                                    onClick={ this.removeTasksFields.bind(this) } 
                                    number={ task_index } 
                                    className={ "btn btn-secondary mr-5 mb-5" } 
                                    title="Remove Task"
                                  > 
                                    <span aria-hidden="true" number={ task_index } >&times;</span>
                                  </button> 
                                :
                                  null
                              }
                            </div>

                        ) // tasksEnd
                      }
              </div>

              {
                documentStep && documentStep !== "show-on--review" ?
                  <>
                    <br className="clear"/>

                    <div className="row pl-0 mb-3 col-md-8">
                      <button type="submit" className="btn btn-default btn-outline-primary">Save Tasks</button>

                      <div className="col-md-8 row mt-20">
                        <p className="status__message status--tasks">{ processStatus.tasks }</p>
                      </div>
                    </div>

                    <br className="clear"/>
                  </>
                :
                  null
              }
          </form>
      :
        <p>Start or Choose a document to start tasks.</p>
      )
    }
    // } else {
      // return null
    // }
}


