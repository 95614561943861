import { Link } from 'react-router-dom'
import React, { Component } from 'react'

export let useSortableData = (items, config = null) => {
  const [sortConfig, setSortConfig] = React.useState(config)

  // console.log('items')
  // console.log(items)
  
  const sortedItems = React.useMemo(() => {
    // console.log(items)
    let sortableItems = [...items]
    if (sortConfig !== null) {
      sortableItems.sort((a, b) => {
        let sortConfigkey = sortConfig.key.split('.')
        let { aSort, bSort } = ''
        if (sortConfigkey.length === 2) {
          aSort = a['document'][sortConfigkey[0]][sortConfigkey[1]]
          bSort = b['document'][sortConfigkey[0]][sortConfigkey[1]]
        } else if (sortConfigkey[2] === "list" && sortConfigkey.length === 3) {
          if (a['document'][sortConfigkey[0]] && a['document'][sortConfigkey[0]][0][sortConfigkey[1]]) {
            aSort = a['document'][sortConfigkey[0]][0][sortConfigkey[1]]
          } else {
            aSort = 'zed'
          }
          if (b['document'][sortConfigkey[0]] && b['document'][sortConfigkey[0]][0][sortConfigkey[1]]) {
            bSort = b['document'][sortConfigkey[0]][0][sortConfigkey[1]]
          } else {
            bSort = 'zed'
          }
        } else if (sortConfigkey.length == 3) {
          if (a['document'][sortConfigkey[0]] && a['document'][sortConfigkey[0]][sortConfigkey[1]]) {
            aSort = a['document'][sortConfigkey[0]][sortConfigkey[1]][sortConfigkey[2]]
          } else {
            aSort = 'zed'
          }
          if (b['document'][sortConfigkey[0]][sortConfigkey[1]]) {
            bSort = b['document'][sortConfigkey[0]][sortConfigkey[1]][sortConfigkey[2]]
          } else {
            bSort = 'zed'
          }
        }

        if (aSort === undefined)
          aSort = 1000000000001

        if (bSort === undefined)
          bSort = 1000000000001

        if (aSort < bSort) {
          return sortConfig.direction === 'ascending' ? -1 : 1
        }
        if (aSort > bSort) {
          return sortConfig.direction === 'ascending' ? 1 : -1
        }
        return 0
      })
    }
    return sortableItems
  }, [items, sortConfig])

  const requestSort = key => {
    let direction = 'descending'
    if (sortConfig && sortConfig.key === key && sortConfig.direction === 'descending') {
      direction = 'ascending'
    }
    setSortConfig({ key, direction })
  }

  return { items: sortedItems, requestSort, sortConfig }
}